.main {
    min-width: 1500px;
    margin: auto;
}

.appbar {
    display: flex;
    justify-content: space-between;
    padding-left: 99.8px;
    padding-right: 93px;
    padding-top: 27px;
    padding-bottom: 19.5px;
    border-bottom: 1px solid #dedede;
}

.logo1 {
    width: 143.7px;
}

.login {
    height: 100%;
    display: flex;
    align-items: center;
}

.avatar {
    margin-right: 7.8px;
    width: 14.1px;
}

.loginText{
    font-family: "Apple_R";
    font-size: 15px;
    letter-spacing: -0.75px;
    color: #303030;
    display: inline-block;
    vertical-align: top;
}

.body {
    width: 439px;
    margin: 0 auto;
}

.loginTextBottom {
    margin-top: 70.5px;
    font-family: "Apple_R";
    letter-spacing: "-1.5px";
    font-size: 30px;
    color: #303030;
    margin-bottom: 20px;
}

.label {
    margin-bottom: 9px;
    font-size: 18px;
    color: #606060;
    letter-spacing: -0.45px;
    font-family: "Apple_R";
}

.submit {
    margin-top: 30px;
    width: 440px;
    height: 62px;
    border-radius: 31px;
    color: white;
    font-family: "Apple_R";
    font-size: 20px;
    letter-spacing: -1px;
    background-color: #3d50b0;
    border: none;
    margin-bottom: 35.5px;
    cursor: pointer;
}

.findPasswordText {
    font-size: 16px;
    margin-bottom: 12px;
    letter-spacing: -0.16px;
    cursor: pointer;
}

.lastText {
    text-align: center;
    padding-top: 29.7px;
    border-top: 1px solid #c1c1c1;
    font-size: 19px;
    color: #2a2a2a;
    letter-spacing: 0.19px;
    font-family: "Apple_R";
    margin-bottom: 20px;
}

.phone {
    display: flex;
    align-items: flex-end;
}

.modal_header {
    width: 100%;
    text-align: center;
    border-bottom: 1px solid #c1c1c1;
    padding-bottom: 20px;
    font-family: "Apple_B";
}

.phoneCert {
    width: 110px;
    margin-left: 13.8px;
    font-family: "Apple_R";
    font-size: 18px;
    background-color: #121a50;
    color: white;
    border-radius: 16px;
    cursor: pointer;
}

.modal_label {
    margin-top: 4px;
    margin-bottom: 16px;
    font-size: 16px;
    color: #606060;
    letter-spacing: -0.45px;
    font-family: "Apple_R";
}

.modalBtnDiv {
    display: flex;
    justify-content: end;
}

.modalBtn {
    margin-top: 24px;
    width: 60px;
    font-family: "Apple_B";
    color: #3d50b0;
    background-color: white;
    border: 1px solid #c1c1c1;
    font-size: 16px;
    border-radius: 12px;
    height: 28px;
    line-height: 28px;
    cursor: pointer;
}

.onlyMobile {
    display: none;
}



@media screen and (max-width : 1000px){
    /* .main {
        min-width: 0;
    }

    .body {
        width: auto;
        padding-left: 8%;
        padding-right: 8%;
    }

    .onlyPc {
        display: none;
    }

    .appbar2 {
        display: block;
    }

    .appbar2{
        background-color: #3d50b0;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .logo2 {
        width: 30%;
        height: 30vw;
    }

    .loginTextBottom {
        margin-top: 160px;
        font-family: "Apple_B";
        font-size: 4.2rem;
    }

    .label {
        font-family: "Apple_B";
        font-size: 2.2rem;
        margin-bottom: 1.5rem;
    }

    .submit {
        margin-top: 90px;
        width: 100%;
        height: 7.5rem;
        font-size: 2.2rem;
        border-radius: 3.5rem;
        font-family: "Apple_B";
    }

    .lastText {
        border: 0;
        margin-top: 80px;
        margin-bottom: 160px;
    }

    .findPasswordText{
        font-size: 2.2rem;
    }

    .lastText {
        font-size: 1.6rem;
        font-family: "Apple_B";
    }

    .modal_header {
        font-size: 2.2rem;
    }

    .modal_label {
        font-size: 2rem;
        margin-bottom: 1.5rem;
    }

    .modalBtn {
        width: 20%;
        font-size: 2rem;
        height: 3.5rem;
    } */

}

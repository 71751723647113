.header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    /* padding-left: 1.219rem;
    padding-right: 1.219rem; */
    width: 90.6%;
    margin: 0 auto;
    padding-bottom: 1.1rem;
    padding-top: 1.1rem;
    /* border-bottom: 0.075rem solid #e0e0e0; */
  }
  
  .header.ios {
    padding-top: 0px;
  }
  
  .backBtnDiv {
    display: flex;
    align-items: center;
    height: 1.5rem;
    padding-top: 0.125rem;
  
    padding-top: 0.375rem;
    padding-bottom: 0.25rem;
  
    width: 23%;
  }
  
  .arrowLeft {
    width: 1rem;
    margin-right: 1.094rem;
  }
  
  .titleText {
    font-size: 1.063rem;
    letter-spacing: -0.053rem;
    text-align: left;
    color: #2a2a2a;
    font-family: "Apple_B" !important;
    display: flex;
    align-items: center;
  }
  
  .pentosqure {
    width: 1.4rem;
  }
  
  .submit {
    width: 23%;
    text-align: end;
    font-family: "Apple_B";
    font-size: 0.95rem;
    color: black;
    display: flex;
    justify-content: space-between;
  }
  
  .my {
    color: #e0e0e0;
  }
  
  .my.active {
    color: #3d50b0;
  }
  
  .plus {
    width: 1.3rem;
  }

  .body{
    background-color: #f4f4f4;
  }

  .mainBody{
    padding-top: 2rem;
    padding-bottom: 2rem;
    width: 90.6%;
    margin: 0 auto;
  }

  .incorrectDiv{
    display: flex;
    align-items: center;
  }

  .incorrectText{
    font-family: "Apple_R";
    margin-right: 0.5rem;
    font-size: 0.95rem;
  }

  .incorrectSwitch{
    display: flex;
    align-items: center;
  }

  .invisibleDiv{
    padding-top: 2rem;
    display: flex;
  }

  .invisible{
    width: 8rem;
    height: 2.2rem;
    border: 1.5px solid #949494;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 0.35rem;
    color: #949494;
    font-family: "Apple_B";
    font-size: 0.9rem;
  }

  .invisible.first{
    margin-right: 0.5rem;
  }

  .invisible.active{
    border: 1.5px solid #304ffe;
    color: #304ffe;

    /* border: 1px solid #3f51b5;
    color: #3f51b5; */
  }

  .wordsWrapper{
    padding-top: 2.5rem;
    padding-bottom: 2.5rem;
  }

  .eachWord{
    background-color: white;
    margin-bottom: 0.8rem;
    padding : 1.8rem 1.1rem 1.8rem 1.1rem;
    border-radius: 0.4rem;
  }

  .topHeader{
    display: flex;
    justify-content: space-between;
    margin-bottom: 1rem;
  }

  .bottomLine{
    min-height: 1.32rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .word{
    font-family: "Apple_EB";
    font-size: 1.1rem;
  }

  .meaning{
    font-family: "Apple_R";
    font-size: 0.95rem;
    flex-grow: 1;
    flex-shrink: 0;
    overflow: hidden;
  }

  .isCorrect{
    font-size: 0.8rem;
  }

  .eyeSvg{
    width: 1.3rem;
  }

  .mainBody{

  }

  .mainBody::-webkit-scrollbar{
    background-color: transparent !important;
    width: 0px !important;

  }
  
  .mainBody::-webkit-scrollbar-thumb{
    background-color: transparent !important;
    border-radius: 0px;
  }

  .body::-webkit-scrollbar{
    background-color: transparent !important;
    width: 0px !important;

  }
  
  .body::-webkit-scrollbar-thumb{
    background-color: transparent !important;
    border-radius: 0px;
  }

  @media only screen and (min-width : 500px){
    .header{
      padding-top: 1.1rem !important;
    }
  }
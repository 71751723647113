.chatRoom {
    background-color: #f1f3f8;
    position: relative;
}

.header {
    display: flex;
    align-items: center;
    padding-left: 1.219rem;
    padding-right: 1.219rem;
    padding-bottom: 1.3rem;
    padding-top: 1.1rem;
    border-bottom: 0.031rem solid #b4b4b4;
}

.header.ios {
    padding-top: 0px;
}

.chatRoomBody {
    padding-bottom: 1rem;
}

.backBtnDiv {
    display: flex;
    align-items: center;
    height: 1.5rem;
    padding-top: 0.125rem;

    padding-top: 0.375rem;
    padding-bottom: 0.25rem;

    width: 12%;
}

.arrowLeft {
    width: 1rem;
    margin-right: 1.094rem;
}

.titleText {
    font-family: "Apple_SB";
    font-size: 1.094rem;
    letter-spacing: -0.88px;
    text-align: left;
    color: #2a2a2a;
}

.chatRoomSubmitDiv {
    position: fixed;
    width: 100%;
    bottom: 0;
    z-index: 99;
    background-color: white;
}

.chatRoomSubmit {
    padding-top: 0.895rem;
    padding-bottom: 0.895rem;
}

.footerWrapper {
    position: relative;
}

.loadingBar {
    position: absolute;
    top: 0;
    width: 100%;
}

.submitDiv {
    width: 92.1%;
    margin: 0 auto;
    display: grid;
    grid-template-columns: 5.5fr 1fr;
}

.textSubmitWrapper{
    padding-left: 1.3rem;
    padding-right: 1.3rem;
}

.textSubmitDiv{
    width: 100%;
    margin: 0 auto;
    border-radius: 1rem;
    background-color: #f4f4f5;
    min-height: 3.094rem;  
    padding-left: 1rem;
    padding-right: 1rem; 
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-top: 0.45rem;
    padding-bottom: 0.45rem;
}

.textSubmitInput{
    flex-grow: 1;
    padding-right: 2rem;

}

.textBoxBorder {
    border: solid 0.5px #ababab;
    /* aspect-ratio: 100 / 16.696; */
    min-height: 3.094rem;
    display: flex;
    align-items: center;
    padding-left: 0.906rem;
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
}

.submitBtnDiv {
    display: flex;
    justify-content: flex-end;
    align-items: center;
}

.input {
    border: none;
    outline: none;
    background-color: transparent;
    font-family: "Apple_R";
    font-size: 1rem;
    letter-spacing: -0.028rem;
    color: #070707;
    width: 100%;
    font-size: 0.844rem;
    line-height: 1.3;
    resize: none;
}

.input::placeholder {
    font-family: "Apple_L";
    font-size: 0.844rem;
    letter-spacing: -0.68px;
    color: #6b6b6b;
}

.input:focus {
    border-color: transparent;
    -webkit-box-shadow: none;
    box-shadow: none;
}

.submitBtnCircle {
    width: 2.469rem;
    height: 2.469rem;
    border: solid 0.5px #707070;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.sendTextCircle{
    background-color: #d6d4f5;
    border-radius: 50%;
    padding: 0.25rem;
}

.sendText {
    rotate: -30deg;
    margin-left: 4px;
    width: 1.3rem;
    height: 1.3rem;
}

.submitExtraBtnsDiv {
    margin-top: 0.734rem;
    display: flex;
    align-items: center;
    padding-left: 0.906rem;
}

.smile {
    width: 1.5323rem;
    height: 1.5323rem;
    margin-right: 0.9108rem;
}

.clip {
    width: 1.1561rem;
    height: 1.12761rem;
    margin-right: 0.9009rem;
}

.clip2 {
    width: 1.1561rem;
    height: 1.12761rem;
    rotate: 90deg;
    margin-right: 0.25rem;
}

.link {
    width: 1.7534rem;
    height: 0.75rem;
}

/* 
.shapeLogo{
    width: 1.5rem;
}

.avatar{
    width: 1.5rem;
}

.msg_img{
    width: 80%;
    object-fit: cover;
} */




.eachMessage {
    margin: 0.6rem 0 0;
    padding: 0 0 0 3.5rem;
}

.profileAnchor {
    position: relative;
}

.profileContainer {
    width: 2rem;
    height: 2rem;
    margin-left: -40px;
    border-radius: 50%;
    background-color: white;
    border: 1px solid #e1e1e1;
    position: relative;
    float: left;
}

.profileContainer.ofStudent {
    background-color: rgba(218, 218, 218);
}

.shapeLogo {
    width: 75%;
    height: 75%;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    overflow: hidden;
    object-fit: cover;
}

.avatar {
    width: 60%;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    overflow: hidden;
    object-fit: cover;
}

.userName {
    vertical-align: top;
    font-size: 14px;
    font-family: "Apple_B";
    line-height: 32px;
    display: inline-block;
    padding-top: 0px;
}

.msg_container {}

.msg_item {
    border-radius: 0.5rem;
    background-color: #e2e7ff;
    padding: 0.375rem 0.75rem;
    margin-top: 0.125rem;
    max-width: 80%;
    background-clip: border-box;
    color: #333;
    vertical-align: bottom;
    word-break: keep-all;
    word-wrap: break-word;
    display: inline-block;
}

.msg_item.ofStudent {
    background-color: #fff;
}

.msg_text_box {
    white-space: pre-wrap;
    word-break: normal;
    line-height: 1.438rem;
    color: #505050;
    font-size: 0.938rem;
    font-family: "Apple_R";
    font-size: 0.85rem;
}

.msg_item.forImg {
    display: flex;
    justify-content: center;
    align-items: center;
}

.msg_img {
    max-width: 100%;
    max-height: 10rem;
}

.msg_extra {
    padding-left: 6px;
    font-size: 11px;
    display: inline-flex;
    flex-flow: wrap-reverse;
    margin-top: 4px;
}

.msg_extra_action {
    display: block;
    color: #797979;
}

.divider {
    margin-top: 20px;
    padding: 8px 12px;
}

.emojiBox {
    position: absolute;
    top: -325px;
    width: 100%;
    border: 1px solid rgba(0, 0, 0, 0.15);
    border-radius: 4px;
    box-shadow: 0 6px 12px rgba(0, 0, 0, .175);
    height: 312px;
}

.modalTitle {
    padding: 1rem 1.5rem;
    font-family: "Apple_SB";
    color: #333;
    font-size: 1rem;
    height: 2.5rem;
}

.modalBody {
    padding: 16px 24px;
}

.modalFooter {
    padding: 0px 20px 20px;
    display: flex;
    justify-content: flex-end;
}

.cancelBtn {
    cursor: pointer;
    color: #bcbcbc;
    border-color: rgba(0, 0, 0, 0.12);
    background-color: rgba(0, 0, 0, 0.03);
    padding: 8px 12px;
    font-family: "Apple_R";
    margin-right: 1rem;
    font-size: 0.9rem;
}

.uploadBtn {
    font-family: "Apple_R";
    color: white;
    cursor: pointer;
    background-color: #3d50b0;
    padding: 8px 12px;
    border-radius: 4px;
    font-size: 0.9rem;
}

.timeString{
    font-family: "Apple_R";
    font-size: 10px;
}

@media only screen and (min-width : 500px){
    .header{
        padding-top: 1.1rem !important;
    }

    .chatRoomBody{
        padding-bottom: 2rem;
    }
}


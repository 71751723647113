.datePicker {
    margin-top: 16px;
}

.searchMenuDiv {
    font-family: "Apple_R";
    width: 177px;
    height: 51px;
    border-radius: 28px;
    border: 1px solid #cbcbcb;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 14px;
    cursor: pointer;
}

.searchMenu {
    margin-top: 23px;
    display: flex;
    margin-bottom: 33px;   
}

.searchMenuDiv.active{
    background-color: #3d50b0;
    border: none;
    color: white;
}

.totalCheckBtn{
    margin-top: 24px;
    cursor: pointer;
    margin-right: 12px;
    border-radius: 4px;
    background-color: #3d50b0;
    width: 120px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: white;
    font-family: "Apple_L";
}

.disableTotalCheckBtn {
    background-color: rgb(224, 224, 224);
    color: rgb(163, 163, 163);
    margin-top: 24px;
    cursor: not-allowed;
    margin-right: 12px;
    border-radius: 4px;
    width: 120px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-family: "Apple_L";
}

.dataGrid {
    margin-top: 24px;
}
.main2 {
    background-color: #f5f5f5;
    width: calc(100% - 244px);
    padding-left: 46px;
    padding-top: 45.7px;
    padding-right: 46px;
    padding-bottom: 71px;
    height: calc(100vh - 78px);
}

.main22 {
    background-color: #f5f5f5;
    width: calc(100% - 244px);
    padding-left: 46px;
    padding-top: 45.7px;
    padding-right: 46px;
    padding-bottom: 71px;
}

.title{
    font-family: 'Apple_B' !important;
    font-size: 22px;
}

.editImage {
    margin-right: 11px;
}


.wrap {
    display: flex;
}

.menu {
    margin-top: 23px;
    display: flex;
    margin-bottom: 33px;
    justify-content: space-between;
    align-items: center;
    width: 1160px;
}

.css-4l7j15{
    border-radius: 10px;
    box-shadow: rgb(20 20 20 / 2%) 2px 8px 12px 0px;
}

.submenu {
    display: flex;
    cursor: pointer;
}

.remain {
    margin-top: 10px;
    font-family: "Apple_R";
}

.remain span {

}

.menu_status{
    margin-right: 14px;
    width: 177px;
    height: 51px;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid #cbcbcb;
    border-radius: 28px;
    color: #cbcbcb;
    font-family: "Apple_R";
}

.menu_status.active{
    margin-right: 14px;
    width: 177px;
    height: 51px;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid #cbcbcb;
    border-radius: 28px;
    background-color: #3d50b0;
    color: white;
    font-family: "Apple_R";
}



.select {
    box-shadow: rgb(20 20 20 / 2%) 2px 8px 12px 0px;
    border-radius: 10px;
    background-color: white;
    width: 400px;
    margin-left: 28px;
    padding-left: 22px;
    padding-right: 22px;
}

.selectTitle {
    display: flex;
    justify-content:space-between;
    margin-top: 25px;
    border-bottom: 1px solid #dbdbdb;
    padding-bottom: 16px;
}

.selectmenu {
    width: 82px;
    border: 1px solid #cbcbcb;
    height: 39px;
    border-radius: 28px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: "Apple_R";
    cursor: pointer;
}

.selectmenu.active{
    background-color: #3d50b0;
    color: white;
}

.timePicker {
    padding-top: 23px;
}

.timePickerExit {
    margin-bottom: 20px;
}

.timePickerExitTitle{
    font-family: "Apple_R";
    margin-bottom: 15px;
}

.timePickerArriveTitle{
    font-family: "Apple_R";
    margin-bottom: 15px;
}

.description {
    background-color: white;
    width: 400px;
    margin-left: 28px;
    padding-left: 22px;
    padding-right: 22px;
    box-shadow: rgb(20 20 20 / 2%) 2px 8px 12px 0px;
    border-radius: 10px;
}

.descriptionTitle {
    font-family: 'Apple_R';
    font-size: 20px;
    letter-spacing: -1px;
    color: #303030;
    padding-top: 27px;
    padding-bottom: 16px;
    border-bottom: 1px solid #dbdbdb;
}

.descriptionWrite {
    padding-top: 22px;
    padding-bottom: 23px;
}

.submitDiv {
    display: flex;
    justify-content: end;
    width: 1180px;
}

.submit {
    margin-top: 26px;
    width: 181px;
    height: 46px;
    background-color: #3d50b0;
    color: white;
    border-radius: 28px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-left: 25px;
    padding-right: 20px;
    cursor: pointer;
}

.checkBoard {
    width: 1164px;
    height: 561px;
    background: white;
    padding-top: 36px;
    padding-left: 30px;
    padding-right: 30px;
    box-shadow: rgb(20 20 20 / 2%) 2px 8px 12px 0px, rgb(20 20 20 / 2%) 0px 1px 3px 0px;
    border-radius: 12px;
}

.checkBoardBody{
    height: 440px;
    overflow: auto;
    padding-right: 12px;
}

.checkBoardBody::-webkit-scrollbar{
    background-color: #f5f5f5;
    width: 4px !important;
}

.checkBoardBody::-webkit-scrollbar-thumb{
    background-color: #bbbcb6;
    border-radius: 10px;
}

.checkBoardTitle {
    padding-bottom: 20px;
    font-family: "Apple_R";
    font-size: 18px;
    border-bottom: 1px solid #dfdfdf;
}

.checklist {
    display: flex;
    justify-content: space-between;
    padding-top: 19.5px;
    padding-bottom: 19.5px;
    border-bottom: 1px solid #dfdfdf;
}

.checklistTitle {
    display: flex;
    justify-content: space-between;
}

.checklistTitle_1 {
    display: flex;
}

.date {
    width: 100px;
}

.cancel {
    border: 1px solid #3d50b0;
    width: 80px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 4px;
    font-size: 14px;
    color: #3d50b0;
    font-family: "Apple_B";
    cursor: pointer;
}

/* @media screen and (max-width : 1024px){
    .main2 {
        height: 130vh;
        overflow: auto;
    }
} */

@media screen and (max-width : 1024px){
    .main2 {
        height: 130vh;
        width: 1256px;
    }

    @media (orientation : portrait){
        .css-ekeie0{
            align-items: flex-start !important;
            padding-top: 27% !important;
        }
    }

    @media (orientation : landscape){
        .main2 {
            height: auto;
        }
    }
}
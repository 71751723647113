.logo{
    width: 6.169rem;
}

.headerDiv{
    border-bottom: 0.031rem solid #cfcfcf;
}

.header{
    width: 90.4%;
    margin: 0 auto;
    padding-top: 0.881rem;
    padding-bottom: 0.881rem;
    /* padding-left: 1.206rem;
    padding-right: 1.206rem; */

    display: flex;
    justify-content: space-between;
}

.header.ios{
    padding-top: 0px;
}

.main{

}

.logoDiv{
    display: flex;
    align-items: center;
}

.alarmDiv{
    display: flex;
    align-items: center;
}

.bellIcon{
    width: 1.281rem;
    height: 1.55rem;
}

.bodyDiv{
    width: 90.4%;
    margin: 0 auto;
}

.body{
    /* padding-left: 1.125rem;
    padding-right: 1.125rem; */

    width: 100%;
    padding-top: 1.297rem;
}

.body2{

}

.profileWrapper{
    width: 100%;
    /* aspect-ratio: 100 / 28.907; */
    padding: 1rem 0.906rem 1.406rem 1.25rem;
    border-radius: 0.313rem;
    background-color: #f3f3f3;
    display: flex;
    align-items: center;
    margin: 0 auto; 
    position: relative;
}

.avatar{
    width: 3.344rem;
    height: 3.344rem;
    margin-right: 0.932rem;
}

.profileName{
    font-family: "Apple_B";
    font-size: 0.938rem;
    letter-spacing: -0.047rem;
    text-align: left;
    color: #2a2a2a;  
    margin-bottom: 0.437rem;
}

.profileDescription{
    font-size: 0.781rem;
    font-family: "Apple_R";
    letter-spacing: -0.008rem;
    color: #2a2a2a;
}

.isOut{
    display: inline-block;
    margin-left: 0.375rem;
    width: 3.594rem;
    height: 1.375rem;
    background-color: #dbf0ff;
    text-align: center;
    line-height: 1.375rem;
    border-radius: 0.656rem;
}





.myProfileBtn{
    position: absolute;
    font-size: 0.75rem;
    font-family: "Apple_R";
    letter-spacing: 0.041rem;
    color: #251e1c;
    top: 12%;
    right: 4%;
}

.monthIcon{
    width: 1.813rem;
    height: 1.861rem;
}

.regularIcon{
    width: 1.212rem;
    height: 1.603rem;
}

.consultIcon{
    width: 1.748rem;
    height: 1.992rem;
}

.offlineIcon{
    width: 1.906rem;
    height: 1.595rem;
}

.onlineIcon{
    width: 2.039rem;
    height: 1.49rem;
}

.mealIcon{
    width: 1.588rem;
    height: 1.634rem;
}

.opinionIcon{
    width: 1.528rem;
    height: 1.389rem;
}

.abcIcon{
    width: 1.83rem;
    height: 0.927rem;
}

.checkIcon{
    width: 1.55rem;
}

.footer{
    position: fixed;
    z-index: 99;
    width: 100%;
    bottom: 0;
    background-color: white;
}

.innerFooter{
    width: 100%;
    border-top: 0.031rem solid #c9c9c9;
    /* aspect-ratio: 100 /18.133; */
    height: 4.25rem;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr;

}

.footerMenu{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100%;
}

.footerIconDiv{
    height: 1.945rem;
    height: 1.8rem;
}

.hotMenu:active{
    background-color: #ececec;
}

.hotMenuDiv{
    margin-top: 2.237rem;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    row-gap: 1.491rem;
}


.hotMenuIcon{
    height: 2.0851rem;
    display: flex;
    justify-content: center;
    align-items: flex-start;
}

.hotMenuIcon.abc{
    align-items: center;
}

.hotMenuText{
    display: flex;
    justify-content: center;
    font-family: "Apple_R";
    color: #262626;
    letter-spacing: -0.39px;
    font-size: 0.812rem;
}

.homeIcon{
    width: 1.257rem;
    height: 1.323rem;
}

.bookIcon{
    width: 1.447rem;
    height: 1.121rem;
}

.messageIcon{
    width: 1.179rem;
    height: 1.209rem;
}

.calendarIcon{
    width: 1.254rem;
    height: 1.167rem;
}

.menuIcon{
    width: 1.23rem;
    height: 0.768rem;
}

.footerIconTextDiv{
    display: flex;
    justify-content: center;
    font-family: "Apple_R";
    font-size: 0.656rem;
    letter-spacing: -0.26px;
    color: #707070;
}

.footerIconTextDiv.active{
    font-family: "Apple_B";
    font-size: 0.625rem;
    color: #3b55dc;
}

.divider{
    margin-top: 2rem;
    border-top: 0.562rem solid #f3f3f3;
    margin-bottom: 2.117rem;
}

.divider2{
    margin-top: 2.594rem;
    border-top: 0.562rem solid #f3f3f3;
    margin-bottom: 2.431rem;
}

.studyTimeSection{
    position: relative;
}

.studyTimeDetail{
    position: absolute;
    top: 0;
    right: 0;
    border: solid 1px #343953;
    border-radius: 12.3px;
    padding: 0.188rem 0.656rem 0.188rem 0.659rem;

    font-family: "Apple_R";
    font-size: 0.813rem;
    letter-spacing: -0.33px;
    color: #808080;
}

.studyTimeTitle{
    font-family: "Apple_B";
    font-size: 1.125rem;
    letter-spacing: -0.45px;
    color: #251e1c;
    margin-bottom: 0.468rem;
}

.studyTimeSubTitle{
    font-family: "Apple_M";
    font-size: 0.875rem;
    letter-spacing: -0.35px;
    color: #251e1c;
}

.studyTimeSelectMenuDiv{
    margin-top: 1.219rem;
    position: relative;
}

.studyTimeSelectMenuWrapper{
    width: 100%;
    background-color: #e8e9eb;
    height: 3.062rem;
    aspect-ratio: 100 / 14.624;
    border-radius: 24.5px;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
}


.studyTimeSelectMenuText{
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: "Apple_M";
    font-size: 0.938rem;
    letter-spacing: -0.38px;
    color: #607d8b;
    z-index: 2;
}

.studyTimeSelectMenuText.active{
    color: #37474f;
}

.whiteWrapper{
    position: absolute;
    z-index: 1;
    box-shadow: 0 1.5px 3px 0 rgba(0, 0, 0, 0.16);
    background-color: #fff;
    border-radius: 19px;
    width: 32.386%;
    height: 2.375rem; 
    aspect-ratio: 100 / 35.024;
    top: 50%;
    left: 0;
    transition: all 200ms ease-out;
}

.graphDiv{
    width: 100%;
    height: 150px;
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
    padding-left: 1.125rem;
    padding-right: 1.125rem;
    margin-bottom: 1.219rem;
}

.graphText{
    font-family: "Apple_R";
    font-size: 0.7rem;
    letter-spacing: -0.3px;
    color: #251e1c;
    display: flex;
    justify-content: center;
    margin-bottom: 0.4rem;
}

.graphHeight{
    border-radius: 5px;
    box-shadow: 0 1px 1px 0 rgba(1, 1, 1, 0.16);
    background-color: #edeff5;
    transition: all 300ms ease-out;
}

.graphHeight.active{
    background-color: #3d50b0;
}

.graphDate{
    font-size: 0.7rem;
    color: #251e1c;
    font-family: "Apple_R";
    letter-spacing: -0.3px;
    margin-top: 0.619rem;
    text-align: center;
}

.notificationTitleDiv{
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.notificationTitle{
    font-family: "Apple_EB";
    font-size: 1.25rem;
    letter-spacing: -0.5px;
    color: #2a2a2a;
}

.notificationPlusBtn{
    width: 3.875rem;
    height: 1.531rem;
    border-radius: 12.3px;
    border: solid 1px #343953;
    letter-spacing: -0.33px;
    font-family: "Apple_R";
    font-size: 0.813rem;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #808080;
}

.divider3{
    margin-top: 1.312rem;
    width: 100%;
    border-top: 0.031rem solid #c6c6c6;
}

.notificationBox{
    width: 100%;
    display: flex;
    padding-left: 4.7%;
    padding-right: 4.7%;
    align-items: center;
    /* aspect-ratio: 100 / 15.6; */
    min-height: 3.656rem;
} 

.notificationBox.even{
    background-color: #f9f9f9;
}

.notificationKind{
    font-family: "Apple_R";
    color: #2a2a2a;
    font-size: 0.875rem;
    letter-spacing: -0.35px;
    margin-right: 1.6rem;
}

.notificationDescription{
    font-family: "Apple_R";
    color: #2a2a2a;
    font-size: 0.938rem;
    letter-spacing: -0.38px;
    max-width: 60%;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 1;
    overflow: hidden;
    text-overflow: ellipsis;
}

.newNotification{
    margin-left: 0.6rem;
    width: 1.875rem;
    height: 1.062rem;
    background-color: #ff605a;
    border-radius: 0.5rem;
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: "Apple_R";
    font-size: 0.688rem;
}

.newNotification.none{
    display: none;
}

.ranking{
    font-family: "Apple_R";
    display: none;
}

.ranking.active{
    display: inline;
}

.parentNone{
    display: none;
}

@media only screen and (min-width : 600px){
    /* .profileWrapper{
        width: 100%;
        max-height: 200px;
    } */
    .profileWrapper{


    }

    .header{
        margin-top: 0.881rem;
    }

    .notificationBox{
        min-height: 5rem;
    }


}






.basicFont{
    font-family: "Apple_R";
}

.select{
    cursor: pointer;
    flex-basis: 0;
    flex-shrink: 0;
    flex-grow: 1;
    margin-right: 9px;
    border: 1px solid #c8c8c8;
    height: 50px;
    line-height: 50px;
    text-align: center;
    border-radius: 6px;
    color: #c8c8c8;
    letter-spacing: -0.88px;
    position: relative;
}

.select.active{
    background-color: #1b49af;
    color: white;
    border: 0px;
}
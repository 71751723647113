.underBorder{

}


.menuBar{
    font-size: 0.969rem;
    font-family: "Apple_SB";
    width: 100%;
    margin-top: 1.4rem;
    display: flex;
    justify-content: space-around;
    padding-bottom: 0.9rem;
    border-bottom: 0.08rem solid #cfd8dc;
    color: #607d8b;
    position: relative;
}

.menuBarSection{
    flex-grow: 1;
    text-align: center;
}

.menuBarStatus{
    flex-grow: 1;
    text-align: center;
}

.menuBarSection.active{
    color: #37474f;
}

.menuBarStatus.active{
    color: #37474f;
}

.menuUnderBar{
    position: absolute;
    width: 40%;
    border-top: 0.12rem solid #263238;
    bottom: 0;
    transition: all 200ms ease-out;
}

.dateSelectBarDiv{
    padding-top: 1rem;
}

.dateSelectBar{
    display: flex;
    flex-wrap: nowrap;
    overflow: scroll;

    scroll-snap-type: x mandatory;
    /* scroll-behavior: smooth; */
    -webkit-overflow-scrolling: touch;
}

.dateSelectBar:-webkit-scrollbar{
    background-color: transparent;
    width: 0px !important;
}

.dateSelectBar::-webkit-scrollbar{
    display: none;
}

.dateSelectBar::-webkit-scrollbar-thumb{
    background-color: transparent;
    border-radius: 10px;
}


.weekDiv{
    flex-shrink: 0;
    width: 100%;
    max-width: 500px;
    margin: 0 auto;
    display: grid;
    grid-template-columns: repeat(7, 1fr);
    grid-auto-rows: 4.2rem;
    scroll-snap-align: start;
}

.eachDayDiv{
    padding-left: 0.2rem;
    padding-right: 0.2rem;
}

.eachDayDivWrapper{
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.eachDayDiv.active .eachDayDivWrapper{
    background-color: #263238;
    border-radius: 0.6rem;
}

.eachDayDiv.weekend .dateNumber{
    color: #ff5722 !important;
}



.dateNumber{
    font-family: "Apple_SB";
    color: #263238;
}

.dateNumber.weekend{
    color: #ff5722;
}

.eachDayDiv.active .dateNumber{
    color: white;
}

.dayString{
    padding-top: 0.3rem;
    color: #b0bec5;
    font-size: 0.95rem;
    font-family: "Apple_L";
}

.dayString.weekend{
    color: #ff5722;
}

.eachDayDiv.active .dayString.weekend{
    color: #b0bec5;
}

.justBorder{
    margin-top: 1rem;
    width: 100%;
    height: 0.9rem;
    background-color: #eceff1;
}

/* .body{
    margin-top: 1.5rem;
} */

.kindSelectDiv{
    width: 90.4%;
    margin: 0 auto;
}


.studyTimeSelectMenuWrapper{
    width: 100%;
    background-color: #e8e9eb;
    height: 3.062rem;
    aspect-ratio: 100 / 14.624;
    border-radius: 24.5px;
    display: grid;
    grid-template-columns: 1fr 1fr;
}

.studyTimeSelectMenuText{
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: "Apple_M";
    font-size: 0.938rem;
    letter-spacing: -0.38px;
    color: #607d8b;
    z-index: 2;
}

.studyTimeSelectMenuText.active{
    color: #37474f;
}

.whiteWrapper{
    position: absolute;
    z-index: 1;
    box-shadow: 0 1.5px 3px 0 rgba(0, 0, 0, 0.16);
    background-color: #fff;
    border-radius: 19px;
    width: 48%;
    height: 2.6rem; 
    aspect-ratio: 100 / 35.024;
    top: 50%;
    left: 0;
    transition: all 200ms ease-out;
}

.menuWrapper{
    padding-top: 2rem;
    padding-left: 2rem;
    padding-right: 2rem;
    width: 100%;
    overflow-x: scroll;
}

.menuWrapper::-webkit-scrollbar{
    display: none;
}



.mealImageWrapper{
    width: 100%;
    height: 100%;
    overflow: hidden;
    border-radius: 0.8rem;
    position: absolute;
}

.mealImage{
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.mealDescription{
    margin-top: 0.3rem;
}

.restaurantName{
    font-family: "Apple_SB";
    color: #37474f;
}

.mealName{
    margin-top: 0.2rem;
    font-size: 0.75rem;
    color: #90a4ae;

    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 1;
    overflow: hidden;
    text-overflow: ellipsis;
    font-family: "Apple_L";
}

.submitButton{
    display: flex;
    position: fixed;
    bottom: env(safe-area-inset-bottom);
    z-index: 99;
    justify-content: center;
    align-items: center;
    background-color: #3d50b0;
    color: white;
    font-family: "Apple_SB";
    width: 100%;
    min-height: 4rem;
}

.submitButton.disabled{
    background-color: #9fa8da;
}

.submitButton.safeArea{
    width: 90%;
    min-height: 4rem;
    left: 50%;
    transform: translateX(-50%);
    border-radius: 0.8rem;
}

.loadingDiv{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
}

.eachMenuDiv{
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
    width: 100%;
    border-bottom: 1px solid #EFF1F3;
}

.eachMenuDiv.firstMenu{
    padding-top: 0px;
}

.eachMenuDivDiv{
    display: flex;
}

.eachMenuName {
    margin-right: 4.5rem;
    color: #5A6371;
    text-align: center;
    font-size: 0.8rem;
    font-style: normal;
    font-weight: 700;
}

.eachMenuTitle {
    color: #777E8A;
    font-style: normal;
    font-weight: 500;
    font-size: 0.8rem;
    /* 두줄 될거 같으면 아예 안나오게 */
    width: 160px;                /* 원하는 너비로 설정 */
  white-space: nowrap;         /* 줄바꿈 방지 */
  overflow: hidden;            /* 넘치는 텍스트 가림 */
  text-overflow: ellipsis;     /* 생략 부호(...) 표시 */
}

/* 500px 넘으면 글씨 크기좀 키워주자 */
@media (min-width: 500px) {
    .eachMenuTitle {
        font-size: 1.5rem;
        width: 350px;
    }

    .eachMenuName {
        font-size: 1.5rem;
        margin-right: 8rem;
    }

    .eachMenuDiv{
        padding-top: 1rem;
        padding-bottom: 1rem;
    }
}
.main{
    width: 1150px;
}

.select {
    margin-bottom: 24px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.name{
    font-size: 18px;
    font-family: "Apple_B";
}

.gradeCardHeader{
    display: flex;
    font-family: "Apple_B";
    height: 130px;
    align-items: center;
    font-size: 17px;
    border: 2px solid black;
}

.headerCol1{
    flex-grow: 1;
    text-align: center;
    width: 30px;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    border-right: 2px solid black;
}

.headerCol2{
    flex-grow: 1;
    text-align: center;
    width: 30px;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    border-right: 2px solid black;
}

.headerCol3{
    flex-grow: 1;
    text-align: center;
    width: 30px;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    border-right: 2px solid black;
}

.headerCol4{
    flex-grow: 1;
    text-align: center;
    width: 30px;
    display: flex;
    flex-direction: column;
    height: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    border-right: 2px solid black;
}

.headerCol4_1{
    height: 24px;
    flex-grow: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    border-bottom: 1px solid black;
}

.headerCol4_2{
    height: 24px;
    flex-grow: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    border-top: 1px solid black;
}



.headerCol5{
    flex-grow: 1;
    text-align: center;
    width: 30px;

    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    border-right: 2px solid black;
}

.headerCol6_1{
    height: 24px;
    flex-grow: 1;
    display: flex;
    align-items: center;
    justify-content: center;

    width: 100%;
    border-bottom: 1px solid black;
}

.headerCol6_2{
    height: 24px;
    flex-grow: 1;
    display: flex;
    align-items: center;
    justify-content: space-between;

    width: 100%;
    border-top: 1px solid black;
}

.headerCol6_2_1{
    width: 40px;
    flex-grow: 1;
    text-align: center;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    border-right: 1px solid black;
}

.headerCol6_2_2{
    width: 40px;
    flex-grow: 1;
    text-align: center;

    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    border-left: 1px solid black;
}

.headerCol6{
    flex-grow: 2;
    text-align: center;
    width: 30px;

    display: flex;
    flex-direction: column;
    height: 100%;


    border-right: 2px solid black;
}

.headerCol7{
    flex-grow: 1;
    text-align: center;
    width: 30px;
    display: flex;
    flex-direction: column;
    height: 100%;
}

.headerCol7_1{
    height: 24px;
    flex-grow: 1;
    display: flex;
    align-items: center;
    justify-content: center;

    border-bottom: 1px solid black;
}

.headerCol7_2{
    height: 24px;
    flex-grow: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    border-top: 1px solid black;
}

.gradeCardRow{
    display: flex;
    height: 65px;
    align-items: center;
    font-size: 17px;
    border-left: 2px solid black;
    border-bottom: 2px solid black;
    border-right: 2px solid black;
}

.gradeCardRow_1{
    width: 147px;

    text-align: center;
    font-family: "Apple_B";
    height: 100%;


    border-right: 2px solid black;

    display: flex;
    justify-content: center;
    align-items: center;
}

.gradeCardRow_2{
    width: 44px;
    text-align: center;

    height: 100%;
    width: 147px;

    border-right: 2px solid black;

    display: flex;
    justify-content: center;
    align-items: center;

}

.gradeCardRow_3{
    width: 44px;
    text-align: center;
    width: 147px;
    height: 100%;

    border-right: 2px solid black;

    display: flex;
    justify-content: center;
    align-items: center;

}

.gradeCardRow_4{
    width: 44px;
    text-align: center;
    width: 147px;

    height: 100%;

    border-right: 2px solid black;

    display: flex;
    justify-content: center;
    align-items: center;

}

.gradeCardRow_5{
    width: 44px;
    text-align: center;

    height: 100%;
    width: 147px;

    border-right: 2px solid black;

    display: flex;
    justify-content: center;
    align-items: center;

}

.gradeCardRow_6{
    width: 44px;
    text-align: center;

    height: 100%;
    width: 132px;
    border-right: 2px solid black;

    display: flex;
    justify-content: center;
    align-items: center;

}

.gradeCardRow_7{
    width: 41px;
    text-align: center;

    height: 100%;
    width: 132px;

    border-right: 2px solid black;

    display: flex;
    justify-content: center;
    align-items: center;

}

.gradeCardRow_8{
    width: 147px !important;
    text-align: center;
    height: 100%;

    display: flex;
    justify-content: center;
    align-items: center;

}

.input {
    padding-left: 10px;
    padding-right: 10px;
    width: 100%;
    height: 100%;
    border: none;
    font-family: "Apple_B";
    text-align: center;
    font-size: 17px;
}

.input:focus {
  outline: none !important;
  border:1.5px solid blue;
}

.btnDiv{
    margin-top: 18px;
    display: flex;
    justify-content: flex-end;
}

.gradeCardDiv{
    max-height: 600px;
    overflow: auto;
}

.gradeCardDiv::-webkit-scrollbar{
    background-color: #f5f5f5;
    width: 0px !important;
}

.gradeCardDiv::-webkit-scrollbar-thumb{
    background-color: #bbbcb6;
    border-radius: 10px;
}

.addDiv{
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    padding-top: 60px;
    padding-bottom: 60px;
    cursor: pointer;
}

.plus{
    width: 30px;

}

.plusText{
    margin-top: 8px;
    font-size: 16px;
    font-family: "Apple_B";
}

.submitButton{
    display: flex;
    position: fixed;
    bottom: env(safe-area-inset-bottom);
    z-index: 99;
    justify-content: center;
    align-items: center;
    background-color: #3d50b0;
    color: white;
    font-family: "Apple_SB";
    width: 100%;
    min-height: 4rem;
}

.submitButton.safeArea{
    width: 90%;
    min-height: 4rem;
    left: 50%;
    transform: translateX(-50%);
    border-radius: 0.8rem;
}

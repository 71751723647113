.main {
    background-color: #f5f5f5;
    width: calc(100% - 244px);
    height: calc(100vh - 78px);
    padding-top: 44px;
    padding-left: 53px;
    padding-right: 53px;
    font-family: "Apple_R";
    overflow: auto;
}


.mainBoard {
    padding-bottom: 60px;
}


.main::-webkit-scrollbar{
    background-color: #f5f5f5;
    width: 6px !important;
}

.main::-webkit-scrollbar-thumb{
    background-color: #bbbcb6;
    border-radius: 10px;

}

.title {
    display: flex;
    align-items: center;
    font-size: 22px;
    font-family: "Apple_B";
    color: #303030;
    margin-bottom:6px;
}

.title img {
    margin-right: 10px;
}

.searchMenu {
    margin-top: 23px;
    display: flex;
    margin-bottom: 33px;   
}

.searchMenuDiv {
    font-family: "Apple_R";
    width: 177px;
    height: 51px;
    border-radius: 28px;
    border: 1px solid #cbcbcb;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 14px;
    cursor: pointer;
}

.searchMenuDiv.active{
    background-color: #3d50b0;
    border: none;
    color: white;
}

@media screen and (max-width : 1024px){
    .main {
        height: 130vh;
        width: 1256px;
    }

    @media (orientation : landscape){
        .main {
            height: auto;
        }
    }
}

.statusBody{

}

.statusTop{
    padding-top: 2.469rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 90.4%;
    margin: 0 auto;
    padding-bottom: 0.812rem;
}

.statusTitle{
    font-family: "Apple_SB";
    font-size: 1.1rem;
    letter-spacing: -0.85px;
    color: #251e1c;
}

.statusNumber{
    font-size: 0.8rem;
    font-family: "Apple_L";
}

.description{
    background-color: #f6f6f6;
    padding-top: 1.632rem;
    padding-bottom: 1.632rem;
}

.descriptionBody{
    width: 90.6%;
    margin: 0 auto;
    padding-bottom: 3rem;
}

.eachDescription{
    margin-bottom: 1.484rem;
}

.eachDescription.last{
    margin-bottom: 0;
}

.eachDescriptionDate{
    font-family: "Apple_M";
    color: #9e9e9e;
    font-size: 0.938rem;
    letter-spacing: -0.38px;
    margin-bottom: 0.634rem;
}

.eachDescriptionBox{
    width: 100%;
    padding: 1.397rem 1.094rem 1.141rem 1.094rem;
    border-radius: 10px;
    border: solid 0.5px #dbdbdb;
    background-color: #fff;
    position: relative;
}

.eachDescriptionText{
    font-size: 0.875rem;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.57;
    letter-spacing: -0.35px;
    text-align: left;
    color: #2a2a2a;
    font-family: "Apple_M";
    margin-bottom: 0.281rem;
    width: 90%;
}

.eachDescriptionTargetDate{
    font-family: "Apple_L";
    font-size: 0.875rem;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: -0.7px;
    text-align: left;
    color: #251e1c;
    margin-bottom: 0.853rem;
}

.eachDescriptionKindDiv{
    display: flex;
    align-items: center;
}

.eachDescriptionKindText{
    font-family: "Apple_M";
    font-size: 0.95rem;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: -0.85px;
    text-align: left;
    color: #251e1c;

    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 1;
    overflow: hidden;
    text-overflow: ellipsis;
}

.eachDescriptionKindSuccess{
    margin-left: 0.719rem;
    display: flex;
    align-items: center;
    background-color: #3d55dc;
    border-radius: 12.5px;
    opacity: 0.9;
    border: 0.5px solid #b7b7b7;
    color: white;
    padding: 0.344rem 0.656rem 0.313rem 0.531rem;
}

.eachDescriptionKindSuccess > span{
    font-family: "Apple_SB";
    font-size: 0.75rem;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: -0.6px;
    text-align: left;
    color: #fff;
}

.check{
    width: 10px;
}

.xmark{
    width: 8px;
}

.arrowUp{
    width: 8px;
}

.trash{
    width: 16px;
}

.trash2{
    width: 16px;
}

.eachDescriptionKindSuccess.waiting{
    background-color: #92949c;
}

.eachDescriptionKindSuccess.decline{
    background-color: #eb445a;
}

.eachDescriptionKindSuccess.success{
    background-color: #3d55dc;
}

.trashCanDiv{
    position: absolute;
    right: 0.894rem;
    top: 1.197rem;
    padding: 0.2rem;
}

.trashCanDiv2{
    position: absolute;
    right: 3.2rem;
    top: 1.197rem;
    padding: 0.2rem;

}

.trashCanDiv.none{
    display: none;
}

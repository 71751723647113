.iosMain{
    padding-top: 1.75rem;

}

.headerDiv{
    width: 89.3%;
    margin: 0 auto;
}

.header{
    padding-top: 16.7px;
    /* padding-left: 16.5px;
    padding-right: 16.5px; */
    width: 100%;
    padding-top: 1.563rem;
    /* padding-left: 1.875rem;
    padding-right: 1.875rem; */
}

.header{
    width: 100%;
}




.logo{
    width: 6.869rem;
}

.bodyDiv{
    width: 89.3%;
    max-width: 450px;
    margin: 0 auto;
}

.body{
    width: 100%;
}

.height1{
    height: 67.5px;
    height: 3.125rem;
}

.titleTextDiv {
    font-family: "Apple_R";
    font-size: 1.313rem;
    line-height: 1.33;
    letter-spacing: -0.033rem;
    text-align: left;
    color: #2a2a2a;
    margin-bottom: 17.2px;
    margin-bottom: 1.388rem;
    -webkit-user-select: none; /* Safari */
    -ms-user-select: none; /* IE 10 and IE 11 */
    user-select: none; /* Standard syntax */
}


.emailDiv{
    z-index: 9999999;
    width: 100%;
    height: 100%;
    z-index: 1;
    border : solid 0.063rem #bdbdbd;
    display: flex;
    align-items: center;
    /* margin-bottom: 0.656rem; */
    border-radius: 0.188rem;
}

.input{
    margin-left: 1.188rem;
}

.emailDiv.active{
    border-color: #3d50b0;
}

.emailDiv.emailError{
    border-color: red;
}

.passwordDiv{
    width: 100%;
    z-index: 1;
    border : solid 0.063rem #bdbdbd;
    padding-left: 1.188rem;
    display: flex;
    align-items: center;

    border-radius: 0.188rem;
}

.passwordDiv.passwordError{
    border-color: red;
}

.passwordDiv.active{
    border-color: #3d50b0;
}

.emailDiv.verifyNumberError{
    border-color: red !important;
}

.emailDiv.rePasswordError{
    border-color: red !important;
}

.input{
    border: none;
    outline: none;
    background-color: transparent;
    font-family: "Apple_R";
    font-size: 1rem;
    letter-spacing: -0.028rem;
    color: #070707;
    width: 100%;
}

.input::placeholder{
    letter-spacing: -0.003rem;
    color: #6b6b6b;
    font-family: "Apple_R";
}

.submitBtn{
    width : 100%;
    /* aspect-ratio: 100/17.014; */
    background-color: #343953;
    /* margin-top: 1.094rem; */
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: "Apple_B";
    font-size: 1.063rem;
    -webkit-user-select: none; /* Safari */
    -ms-user-select: none; /* IE 10 and IE 11 */
    user-select: none; /* Standard syntax */
}

.submitBtn:active{
    background-color: #616785;
}


.findEmailDiv{
    margin-top: 0.875rem;
    display: flex;
    align-items: center;
    font-family: "Apple_R";
    font-size: 0.844rem;
    letter-spacing: 0.021rem;
    -webkit-user-select: none; /* Safari */
    -ms-user-select: none; /* IE 10 and IE 11 */
    user-select: none; /* Standard syntax */
}

.chevronSpan{
    display: inline-block;

    padding-left: 0.188rem;
    -webkit-user-select: none; /* Safari */
    -ms-user-select: none; /* IE 10 and IE 11 */
    user-select: none; /* Standard syntax */
}

.signUpDiv{
    margin-top: 2.031rem;
    display: flex;
    justify-content: center;
    font-size: 0.969rem;
    font-family: "Apple_R";
    letter-spacing: 0.029rem;
    color: #251e1c;
    margin-bottom: 1.063rem;
    -webkit-user-select: none; /* Safari */
    -ms-user-select: none; /* IE 10 and IE 11 */
    user-select: none; /* Standard syntax */
}

.signUpBtn{
    font-family: "Apple_R";
    display: inline-block;
    margin-left: 0.375rem;
    text-decoration: underline;

    -webkit-user-select: none; /* Safari */
    -ms-user-select: none; /* IE 10 and IE 11 */
    user-select: none; /* Standard syntax */
    color: #251e1c;
}

.underLine{
    width: 89.3%;
    margin: 0 auto;
    border-top: 0.031rem solid #b4b4b4;
}

.ionTitle{
    font-family: "Apple_B";
}

.findTitle{
    font-family: "Apple_B";
    margin-bottom: 0.5rem;
}

.btnDiv{
    margin-top: 1rem;
    display: flex;
    justify-content: flex-end;
}

.ionContentDiv{
    max-width: 450px;
    margin: 0 auto;
}




/* @font-face {
    font-family: 'Apple_B';
    src: url("styles/AppleSDGothicNeoB.woff2") format('woff2'),
    url("styles/AppleSDGothicNeoB.woff") format('woff'),
    url("styles/AppleSDGothicNeoB.ttf") format('truetype')
  }

  @font-face {
    font-family: 'Apple_EB';
    src: url("styles/AppleSDGothicNeoEB.woff2") format('woff2'),
    url("styles/AppleSDGothicNeoEB.woff") format('woff'),
    url("styles/AppleSDGothicNeoEB.ttf") format('truetype')
  }

  @font-face {
    font-family: 'Apple_L';
    src: url("styles/AppleSDGothicNeoL.woff2") format('woff2'),
    url("styles/AppleSDGothicNeoL.woff") format('woff'),
    url("styles/AppleSDGothicNeoL.ttf") format('truetype')
  }

  @font-face {
    font-family: 'Apple_M';
    src: url("styles/AppleSDGothicNeoM.woff2") format('woff2'),
    url("styles/AppleSDGothicNeoM.woff") format('woff'),
    url("styles/AppleSDGothicNeoM.ttf") format('truetype')
  }

  @font-face {
    font-family: 'Apple_T';
    src: url("styles/AppleSDGothicNeoT.woff2") format('woff2'),
    url("styles/AppleSDGothicNeoT.woff") format('woff'),
    url("styles/AppleSDGothicNeoT.ttf") format('truetype')
  }

  @font-face {
    font-family: "Apple_R";
    src : url("styles/AppleSDGothicNeoR.woff2") format('woff2'),
    url("styles/AppleSDGothicNeoR.woff") format('woff'),
    url("styles/AppleSDGothicNeoR.ttf") format('truetype')
  } */

  @font-face {
    font-family: 'Apple_H';
    src: url('styles/newFonts/subset-AppleSDGothicNeoH00.woff2') format('woff2'),
        url('styles/newFonts/subset-AppleSDGothicNeoH00.woff') format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Apple_B';
    src: url('styles/newFonts/subset-AppleSDGothicNeoB00.woff2') format('woff2'),
        url('styles/newFonts/subset-AppleSDGothicNeoB00.woff') format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Apple_EB';
    src: url('styles/newFonts/subset-AppleSDGothicNeoEB00.woff2') format('woff2'),
        url('styles/newFonts/subset-AppleSDGothicNeoEB00.woff') format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Apple_L';
    src: url('styles/newFonts/subset-AppleSDGothicNeoL00.woff2') format('woff2'),
        url('styles/newFonts/subset-AppleSDGothicNeoL00.woff') format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Apple_R';
    src: url('styles/newFonts/subset-AppleSDGothicNeoR00.woff2') format('woff2'),
        url('styles/newFonts/subset-AppleSDGothicNeoR00.woff') format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Apple_M';
    src: url('styles/newFonts/subset-AppleSDGothicNeoM00.woff2') format('woff2'),
        url('styles/newFonts/subset-AppleSDGothicNeoM00.woff') format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Apple_T';
    src: url('styles/newFonts/subset-AppleSDGothicNeoT00.woff2') format('woff2'),
        url('styles/newFonts/subset-AppleSDGothicNeoT00.woff') format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Apple_SB';
    src: url('styles/newFonts/subset-AppleSDGothicNeoSB00.woff2') format('woff2'),
        url('styles/newFonts/subset-AppleSDGothicNeoSB00.woff') format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Apple_UL';
    src: url('styles/newFonts/subset-AppleSDGothicNeoUL00.woff2') format('woff2'),
        url('styles/newFonts/subset-AppleSDGothicNeoUL00.woff') format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

  * {
    margin : 0;
    padding : 0;
    box-sizing: border-box;
  }



 a:link { text-decoration: none !important;}
 a:visited {text-decoration: none !important;}
 a:hover { text-decoration: none !important;}

 .minuscell{
   color: blue;
 }

 .pluscell{
   color : red;
 }

 /* .css-1knaqv7-MuiButtonBase-root-MuiButton-root:first-child{
   display: none !important;
 }

 .css-1knaqv7-MuiButtonBase-root-MuiButton-root:nth-child(3){
  display: none !important;
}

.css-1knaqv7-MuiButtonBase-root-MuiButton-root:nth-child(4){
  display: none !important;
} */

.md.header-md{
  padding-left: 16px;
  padding-right: 16px;
}

.ion-avatar{
  
}

.item-native{
  padding-inline-start: 0 !important;
  padding-left: 0 !important;
  background-color: red !important;
}

span.ripple {
  position: absolute;
  border-radius: 50%;
  transform: scale(0);
  animation: ripple 600ms linear;
  background-color: rgba(255, 255, 255, 0.7);
}

@keyframes ripple {
  to {
    transform: scale(4);
    opacity: 0;
  }
}

:root {
  --sat: env(safe-area-inset-top);
  --sar: env(safe-area-inset-right);
  --sab: env(safe-area-inset-bottom);
  --sal: env(safe-area-inset-left);
}

.fabContainer{
  position: fixed;
  bottom: 3rem;
  right: 0;
}

.emoji-search{
  display: none !important;
}

.swiper-pagination-bullet-active {
  background-color: blue !important;
}

ion-select::part(placeholder)::first-letter {
  font-size: 24px;
  font-weight: 500;
}

ion-select::part(text) {
  font-size: 1.063rem;
  letter-spacing: -0.053rem;
  text-align: left;
  color: #2a2a2a;
  font-family: "Apple_B" !important;
  display: flex;
  align-items: center;
}

ion-select::part(icon) {
  color: #9e9e9e;
  opacity: 1;
}

ion-modal{
  --width : 100% !important;
  --height : 100% !important
}

/* .MuiDataGrid-treeDataGroupingCell{
  color: transparent;
}

.MuiDataGrid-columnHeadersInner .MuiDataGrid-columnHeader:first-child{
  width: 60px !important;
  min-width: 60px !important;
  max-width: 60px !important;
}

.MuiDataGrid-row .MuiDataGrid-cell:first-child{
  width: 60px !important;
  min-width: 60px !important;
  max-width: 60px !important;
} */

/* .MuiDataGrid-cell--withRenderer{
  width: 60px !important;
  min-width: 60px !important;
  max-width: 60px !important;
} */


.MuiDataGrid-row .MuiDataGrid-cell:nth-child(13){
  /* color: transparent; */
} 

/* .status3{
  background-color: red;
} */

.todos_status1{
  background-color: #ff5b59;
  color: white;
  cursor: pointer;
}

.todos_status2{
  background-color: #c6c6c6;
  background-color: #eac54f;
  color: white;
  cursor: pointer;

}

.todos_status3{
  background-color: #1a7d36;
  
  color: white;
  cursor: pointer;

}

.todos_status4{
  background-color: #c6c6c6;
  color: white;
  cursor: pointer;

}

.todos_priority3{
  color: #d3232f;
  font-family: "Apple_B";
}

.todos_finish{
  background-color: #1a7d36;
}
.todos_unfinish{
  background-color: #ff5b59;
}














.flexible-modal {
  position: absolute;
  z-index: 999 !important;
  border: 1px solid #ccc;
  background: white;
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
  border-radius: 8px;
}

.my-modal-custom-class{
  z-index: 999;
}
.flexible-modal-mask {
  position: fixed;
  height: 100%;
  background: transparent !important;
  top:0;
  left:0;
  right:0;
  bottom:0;
  z-index: -5 !important;
  overflow: hidden;
}
.flexible-modal-resizer {
  position:absolute;
  right:0;
  bottom:0;
  cursor:se-resize;
  margin:5px;
  border-bottom: solid 2px #333;
  border-right: solid 2px #333;
}
.flexible-modal-drag-area{
  background: #e0e0e0 !important;
  color: white;
  height: 60px;
  position:absolute;
  right:0;
  top:0;
  cursor:grab;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
}

.divider-time-input::-webkit-inner-spin-button,
.divider-time-input::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.divider-time-input {
  -moz-appearance: textfield;
  width: 45px;
  height: 30px;
  text-align: center;
}

.page{
    padding-top: 18px;
}

.questionBox{
    margin-top: 30px;
    margin-bottom: 30px;
}

.title{
    font-family: "Apple_SB";
    font-size: 19px;
    margin-bottom: 16px;
}

.title span{
    color : #3d50b0;
    font-size: 19px;
    font-family: "Apple_B";
}

.autocompleteWrapper{
    width: 350px;
}

.withImage{
    padding-top: 24px;
}

.textfieldWrapper{
    width: 650px;
}

.multiMultiTextWrapper{
    width: 315px;
    margin-right: 20px;
}

.multiMultiText2{
    display: flex;
}



/* flexPage를 flex로 하고 1:5로 width 해준다 */
.flexPage{
    display: flex;
}

.flexChild1{
    flex: 2;
    border-right: 1px solid #E0E0E0;
    margin-right: 50px;
    padding-right: 50px;
}

.flexChild2{
    flex:4;
    height: 800px;
    overflow-y : scroll;
    position: relative;
    padding-bottom: 30px;
}

.justText{
    font-family: "Apple_R";
}

.multiTextTitle{
    margin-bottom: 5px;
    margin-top: 10px;
}

.multiTextTitle2{
    margin-top: 20px;
}


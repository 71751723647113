.main {
    background-color: #f5f5f5;
    width: calc(100% - 244px);
    height: calc(100vh - 78px);
    overflow: scroll;
    padding-top: 44px;
    padding-left: 53px;
    padding-right: 53px;
}


.main::-webkit-scrollbar{
    background-color: #f5f5f5;
    width: 6px !important;
}

.main::-webkit-scrollbar-thumb{
    background-color: #bbbcb6;
    border-radius: 10px;

}

.mainTitle {
    display: flex;
    align-items: center;
    font-size: 22px;
    font-family: "Apple_B";
    color: #303030;
    letter-spacing: -1.1px;
}

.mainImg {
    margin-right: 10.3px;
}

.selectMenu {
    display: flex;
    margin-top: 24px;
}

.selectedMenu{
    width: 177px;
    height: 51px;
    border: 1px solid #cbcbcb;
    border-radius: 28px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 14px;
    color : #888888;
    font-family: "Apple_R";
    cursor: pointer;
}

.selectedMenu.active{
    color: white;
    background-color: #3d50b0;
    border: transparent;
}

.board {
    display: flex;
    margin-top: 64px;
}

.menu {
    width: 733px;
    /* margin-right: 58px; */
}

.schedule{
    margin-right: 58px;
}

.restaurantList {
    display: flex;
}

.restaurant {
    cursor: pointer;
    font-family: "Apple_R";
    font-size: 18px;
    letter-spacing: -0.18px;
    width: 155px;
    display: flex;
    justify-content: center;
    padding-bottom: 18px;
    border-bottom: 2px solid #b1b1b1;
    margin-right: 25px;
    margin-bottom: 51px;
    margin-bottom: 32px;
    margin-top: 29px;
    margin-top: 46px;
}

.restaurant.active{
    color: #4a78ff;
    border-bottom: 2px solid #4a78ff;
}

.mealMenuBoard {
    margin-top: 46px;
    margin-top: 0px;
    display: flex;
    flex-wrap: wrap;
}

.mealMenuBoard div:nth-child(3n+0){
    margin-right: 0;
}

.eachMenu {
    width: 216px;
    margin-right: 27px;
}

.imgBox {
    width: 216px;
    position: relative;
    border-radius: 10px;
    box-shadow: rgb(20 20 20 / 2%) 2px 8px 12px 0px;
}

.image {
    width: 216px;
    height: 216px;
    border-radius: 10px;

}

.mealSelect {
    cursor: pointer;
    position: absolute;
    width: 100%;
    height: 48px;
    bottom: 0;
    background-color: #484848;
    opacity: 0.8;
    display: none;
    justify-content: center;
    align-items: center;
    color: white;
}

.menuSelectText {
    margin-bottom: 4px;
    margin-left: 5.6px;
}

.imgBox:hover .mealSelect{
    display: flex;
}

.menuTitle {
    margin-top: 17px;
    font-size: 18px;
    letter-spacing: -0.9px;
    font-family: "Apple_R";
}

.menuPriceDiv {
    margin-top: 3px;
    font-size: 18px;
    font-family: "Apple_R";
    letter-spacing: -0.9px;
    margin-bottom: 29px;
}

.menuPrice {
    color: #d14e4e;
}



.moneyCharge {
    display: flex;
    width: 100%;
    background-color: white;
    height: 67px;
    align-items: center;
    justify-content: space-between;
    padding-left: 30px;
    padding-right: 30px;
    box-shadow: rgb(20 20 20 / 2%) 2px 8px 12px 0px, rgb(20 20 20 / 2%) 0px 1px 3px 0px;
    border-radius: 10px;
}

.moneyCharge div:first-child {
    font-family: "Apple_R";
    font-size: 19px;
    color: #121a50;
}

.moneyCharge span:first-child{
    color: #566ee8;
}

.moneyCharge div:nth-child(2) {
    cursor: pointer;
    padding-left: 8px;
    font-family: "Apple_R";
    width: 123px;
    height: 39px;
    border: 1px solid #566ee8;
    border-radius: 23px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #566ee8;
}

.moneyCharge div:nth-child(2) img {
    margin-bottom: 4px;
}

.chargeTitle {
    border-radius: 4px 4px 0 0;
    font-family: "Apple_L";
    font-size: 18px;
    margin-bottom: 18px;
    background-color: #3d50b0;
    color: white;
    height: 60px;
    display: flex;
    align-items: center;
    padding-left: 12px;
}

.chargeBoard {
    padding-left: 12px;
    padding-right: 12px;
    padding-bottom: 8px;
}

.chargeAmount {
    margin-bottom: 4px;
    font-family: "Apple_R";
}

.chargeAmount2 {
    margin-top: 8px;
    margin-bottom: 4px;
    font-family: "Apple_R";
}

.chargeName {
    margin-top: 12px;
    margin-bottom: 4px;
    font-family: "Apple_R";
}

.chargeBtnDiv {
    margin-top: 12px;
    border-top: 2px solid rgb(223, 223, 223);
    display: flex;
    justify-content: end;
    padding-top: 16px;
    padding-bottom: 16px;
}

.disabledChargedBtn {
    cursor: not-allowed;
    margin-right: 12px;
    border-radius: 4px;
    background-color: rgb(224, 224, 224);
    width: 120px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: rgb(163, 163, 163);
    font-family: "Apple_L";
}

.chargeBtn {
    cursor: pointer;
    margin-right: 12px;
    border-radius: 4px;
    background-color: #3d50b0;
    width: 120px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: white;
    font-family: "Apple_L";
}

.accountInfoDiv {
    border-radius: 4px;
    background-color: white;
    width: 400px;
    height: 280px;
    padding-left: 12px;
    padding-right: 12px;
}

.accountInfoTitle {
    font-family: "Apple_R";
    font-size: 18px;
    margin-bottom: 8px;
}

.accountInfoDescription {
    border: 1.5px solid rgb(210, 210, 210);
    font-family: "Apple_R";
    padding-top: 12px;
    padding-bottom: 12px;
    padding-left: 8px;
    padding-right: 8px;
    border-radius: 4px;
}

.accountInfoBank {

    display: flex;
    margin-bottom: 6px;
}

.accountInfoBank div:first-child{
    color: rgb(120 , 120, 120);
    width: 120px;
}

.accountInfoNumber {
    display: flex;
    margin-bottom: 6px;

}

.accountInfoNumber div:first-child{
    color: rgb(120 , 120, 120);

    width: 120px;
}

.accountInfoName{
    display: flex;
    margin-bottom: 6px;
}

.accountInfoName div:first-child{
    color: rgb(120 , 120, 120);

    width: 120px;
}

.accountInfoAmount {
    display: flex;
    margin-bottom: 6px;
}

.accountInfoAmount div:first-child{
    color: rgb(120 , 120, 120);

    width: 120px;
}

.accountInfoAddDesc {
    display: flex;
}

.accountInfoAddDesc div:first-child{
    color: rgb(120 , 120, 120);

    width: 120px;
}

.accountInfoAdd {
    margin-top: 16px;
    font-family: "Apple_R";
    font-size: 14px;
}

.accountInfoAdd2 {
    margin-top: 2px;
    font-family: "Apple_R";
    font-size: 14px;
}

.bankName{
    margin-top: 12px;
    margin-bottom: 4px;
    font-family: "Apple_R";
}

.bankDescription {
    font-size: 14px;
    font-family: "Apple_R";
}

.selectedMealMenu {
    padding-left: 22px;
    background-color: white;
    width: 373px;
    height: 67px;
    align-items: center;
    display: flex;
    font-family: "Apple_R";
    font-size: 20px;
    color: #121a50;
    letter-spacing: -0.63px;
    margin-bottom: 46px;
    box-shadow: rgb(20 20 20 / 2%) 2px 8px 12px 0px, rgb(20 20 20 / 2%) 0px 1px 3px 0px;
    border-radius: 10px;
}

.selectedMealMenu span {
    display: inline-block;
    margin-left: 5px;
    color: #566ee8;
}

.uploadFileDiv {
    margin-top: 16px;
    margin-left: 8px;
}

.uploadFile {
    font-family: "Apple_R";
    display: flex;
    align-items: center;
}

.uploadFile img{
    margin-right: 8px;
}

.whenDiv {
    display: flex;
    align-items: center;
    justify-content: center;
}

.css-4l7j15{
    border-radius: 10px;
    box-shadow: rgb(20 20 20 / 2%) 2px 8px 12px 0px;
}

.submitDiv {
    margin-top: 33px;
    background-color: white;
    padding-top: 25px;
    padding-bottom: 26px;
    box-shadow: rgb(20 20 20 / 2%) 2px 8px 12px 0px, rgb(20 20 20 / 2%) 0px 1px 3px 0px;
    border-radius: 10px;
}

.whenDiv .when:first-child{
    margin-right: 13px;
}

.when {
    cursor: pointer;
    width: 153px;
    height: 46px;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid #c2c2c2;
    border-radius: 28px;
    font-family: "Apple_R";
    color: #566ee8;
}

.when.active{
    background-color: #3d50b0;
    color: white;
}

.when.disabled{
    background-color: rgb(224, 224, 224);
    color: rgb(163, 163, 163);
    cursor: not-allowed;
}

.priceDiv {
    font-size: 22px;
    font-family: "Apple_R";
    margin-top: 21px;
    margin-left: 30px;
    margin-right: 30px;
    padding-bottom: 16px;
    border-bottom: 1px solid #d9d9d9;
}

.priceDiv span {
    color: #566ee8;
}

.submitBtn {
    cursor: pointer;
    margin-top: 27px;
    margin-left: 29px;
    margin-right: 29px;
    background-color: #121a50;
    color: white;
    height: 55px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 28px;
}

.submitBtn img{
    width: 6px;
    margin-left: 18px;
}

.alert{
    margin-top: 16px;
    margin-left: 8px;
    margin-right: 8px;
}

.alertSpan{
    font-family: "Apple_R";
}

.checkBoard {
    margin-top: 33px;
    background-color: white;
    width: 1164px;
    height: 561px;
    padding-top: 53px;
    padding-left: 66px;
    padding-right: 50px;
    border-radius: 12px;
    box-shadow: rgb(20 20 20 / 2%) 2px 8px 12px 0px, rgb(20 20 20 / 2%) 0px 1px 3px 0px;
}

.title {
    display: flex;
    align-items: center;
}

.title img{
    margin-right: 8px;
}

.title span {
    font-family: "Apple_R";
    font-size: 18px;
    color: #303030;
}

.dayLine {
    margin-top: 16px;
    display: flex;
    border-top: 2px solid #303030;
}

.sunday {
    color: #d14e4e;
}

.dayFirst1 {
    width: 40px;
    border-top: 3px solid #f2f2f2;
    height: 44px;
    border-bottom: 3px solid #f2f2f2;
    border-left: 3px solid #f2f2f2;
}

.dayFirst2 {
    
    font-family: "Apple_R";
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 40px;
    height: 165px;
    border-bottom: 3px solid #f2f2f2;
    border-left: 3px solid #f2f2f2;
}

.dayFirst3 {
    font-family: "Apple_R";
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 40px;
    height: 165px;
    border-bottom: 3px solid #f2f2f2;
    border-left: 3px solid #f2f2f2;
}

.dayDiv {
    font-family: "Apple_R";
    width: 144px;
    height: 44px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #ebebeb;
    border-left: 3px solid #f2f2f2;
    border-bottom: 3px solid #f2f2f2;
}

.dayLine .dayDiv:last-child{
    border-right: 3px solid #f2f2f2;
}

.dateLine{
    display: flex;
}

.dateDiv{
    width: 144px;
    height: 165px;
    border-left: 3px solid #f2f2f2;
    border-bottom: 3px solid #f2f2f2;
    padding-top: 11px;
    padding-left: 17px;
    padding-bottom: 11px;
    padding-right: 17px;
}

.dateDivDiv{
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.dateLine .dateDiv:last-child{
    border-right: 3px solid #f2f2f2;
}

.menuListLunch div{
    font-family: "Apple_R";
    margin-bottom: 8px;
    line-height: 22px;
}

.menuListLunch div:first-child{
    font-family: "Apple_B";
}

.menuListDinner div{
    font-family: "Apple_R";
    margin-bottom: 8px;
}

.cancelBtn {
    cursor: pointer;
    font-family: "Apple_B";
    color: #3d50b0;
    display: flex;
    justify-content: center;
    border: 1px solid #3d50b0;
    border-radius: 6px;
    align-items: center;
    height: 24px;
    font-size: 14px;
}

.menuListDinner div:first-child{
    font-family: "Apple_B";
    line-height: 22px;
}

.linearProgress {
    margin-top: 20px;
}

.datePicker {
    margin-top: 32px;
}

.totalCheckBtn{
    margin-top: 24px;
    cursor: pointer;
    margin-right: 12px;
    border-radius: 4px;
    background-color: #3d50b0;
    width: 120px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: white;
    font-family: "Apple_L";
}

.disableTotalCheckBtn {
    background-color: rgb(224, 224, 224);
    color: rgb(163, 163, 163);
    margin-top: 24px;
    cursor: not-allowed;
    margin-right: 12px;
    border-radius: 4px;
    width: 120px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-family: "Apple_L";

}

.dataGrid {
    margin-top: 12px;
}

.cancelBtnDiv{
    display: flex;
    justify-content: end;
    margin-bottom: 12px;
}

.totalSumDiv {
    font-family: "Apple_R";
    font-size: 18px;
    margin-top: 24px;
    display: flex;
    justify-content: start;
}

.loading {
    width: 550px;
    height: 300px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.height {
    height: 15px;
}

.averageDiv {
    margin-top: 12px;
    font-size: 18px;
}

.averageDiv div{
    margin-bottom: 6px;
}

/* @media screen and (max-width : 1024px){
    .main {
        height: 130vh;
        overflow: auto;
    }
} */

@media screen and (max-width : 1024px){
    .main {
        height: 130vh;
        width: 1256px;
    }

    @media (orientation : landscape){
        .main {
            height: auto;
        }
    }
}

.handleChargeBtn{
    display: flex;
    align-items: center;
}

.printPaper{
    padding-top: 3.13rem;
    padding-left: 2.5rem;
    padding-right: 2.5rem;
    padding-bottom: 2.74rem;
}

.printTitle{
    font-family: "Apple_B";
    font-size: 2.5rem;
}

.printSubTitle{
    font-family: "Apple_L";
    font-size: 1.125rem;
    letter-spacing: 0.1125rem;
}

.printJustHeight{
    height: 4.94rem;
}

.printDate{
    width: 100%;
    display: flex;
    justify-content: flex-end;
    font-family: "Apple_SB";
    font-size: 1rem;
}

.printBody{
    width: 100%;
    display: flex;
    margin-top: 0.51rem;
}

.printEachMeal{
    flex: 1;
    margin-right: 0.63rem;
}

.printEachMeal.lastElement{
    margin-right: 0;
}   

.printEachMealBox{
    width: 100%;
    border-top: 1px solid black;
    border-bottom: 0.5px solid black;
    padding-top: 0.63rem;
    padding-bottom: 0.44rem;
    height: 110px;
    overflow: hidden;

    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.printEachMealRestaurant{
    font-family:  "Apple_SB";
    line-height: normal;
}

.printEachMealName{
    font-family: "Apple_R";
    line-height: normal;

    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
}

.printEachMealNumber{
    font-family: "Apple_R";
    display: flex;
    justify-content: flex-end;
}

.printEachMealUsersBox{
    padding-top: 1.62rem;
    display: grid;
    grid-template-columns: 1fr 1fr;
    row-gap: 0.62rem;
}

.printEachMealUser{
    font-size: 2rem;
    font-family: "Apple_R";
}

.printBottom{
    position: fixed;
    bottom: 2.74rem;
    left: 50%;
    transform: translateX(-50%);

    display: flex;
    flex-direction: column;
    align-items: center;
}


.main {
    background-color: #f5f5f5;
    width: calc(100% - 244px);
    height: calc(100vh - 78px);
    overflow: scroll;
    padding-top: 44px;
    padding-left: 53px;
    padding-bottom: 120px;
    font-family: "Apple_R";
}

.title {
    display: flex;
    align-items: center;
    font-size: 22px;
    font-family: "Apple_B";
    color: #303030;
}

.title img {
    margin-right: 10px;
}

.selectStudentText {
    margin-top: 21px;
    font-family: "Apple_R";
    font-size: 21px;
    letter-spacing: -1.05px;
    padding-bottom: 15px;
    border-bottom: 1px solid #cbcbcb;
}

.autocompleteDiv {
    margin-top: 26px;
}


.manageDiv {
    margin-top: 36px;
}

.manageDivTitle {
    font-size: 21px;
    font-family: "Apple_R";
    letter-spacing: -1.05px;
    color: #303030;
}

.manageDivTitle {
    display: flex;
    justify-content: space-between;
    margin-right: 50px;
    width: 1150px;
}

.manageDivTable{
    width: 1150px;
    margin-top: 15px;
}

.manageDivTable{
    display: grid;
    grid-template-columns: 1fr 1fr 5.8fr 5.8fr 5.8fr;
    grid-template-rows: 50px 100px 80px 50px 60px 60px 60px 60px 60px 60px 60px;
}

.titleTableCell{
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: "Apple_R";
}

.cell0{
    grid-column: 1/3;
}

.cell4{
    grid-row: 2/5;
}

.cell17{
    grid-row: 5/8;
}

.cell30{
    grid-column: 1/3;
}

.cell34{
    grid-row: 9/11;
}

.cell43{
    grid-column: 1/3;
}

.cell44{
    grid-column: 3/6;
}

.cell{
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: white;
}

.cell0,.cell1, .cell2, .cell3{
    border-top: 2px solid #707070;
    border-right: 1px solid #cbcbcb;
    background-color: rgb(235,235,235);
}      

.cell3{
    border-right: 0;
}

.cell4{
    border-right: 1px solid #cbcbcb;
    border-bottom: 2px solid #707070;
}

.cell5,.cell6,.cell7,.cell8{
    border-bottom: 1px solid #cbcbcb;
    border-right: 1px solid #cbcbcb;
}

.cell8{
    border-right: 0;
}

.cell9,.cell10,.cell11,.cell12{
    border-bottom: 1px solid #cbcbcb;
    border-right: 1px solid #cbcbcb;
}

.cell12{
    border-right: 0;
}

.cell13,.cell14,.cell15,.cell16{
    border-bottom: 2px solid #707070;
    border-right: 1px solid #cbcbcb;
}

.cell16{
    border-right: 0;
}


.cell17{
    border-right: 1px solid #cbcbcb;
    border-bottom: 2px solid #707070;
}

.cell18,.cell19,.cell20,.cell21{
    border-bottom: 1px solid #cbcbcb;
    border-right: 1px solid #cbcbcb;
}

.cell21{
    border-right: 0;
}

.cell22,.cell23,.cell24,.cell25{
    border-bottom: 1px solid #cbcbcb;
    border-right: 1px solid #cbcbcb;
}

.cell25{
    border-right: 0;
}

.cell26,.cell27,.cell28,.cell29{
    border-bottom: 2px solid #707070;
    border-right: 1px solid #cbcbcb;
}

.cell29{
    border-right: 0;
}

.cell30,.cell31,.cell32,.cell33{
    border-bottom: 2px solid #707070;
    border-right: 1px solid #cbcbcb;
}

.cell33{
    border-right: 0;
}


.cell34{
    border-right: 1px solid #cbcbcb;
    border-bottom: 2px solid #707070;
}

.cell35,.cell36,.cell37,.cell38{
    border-bottom: 1px solid #cbcbcb;
    border-right: 1px solid #cbcbcb;
}

.cell39,.cell40,.cell41,.cell42{
    border-bottom: 2px solid #707070;
    border-right: 1px solid #cbcbcb;
}

.cell43{
    border-right: 1px solid #cbcbcb;
}


.textarea {
    font-family: "Apple_R";
    padding-left: 10px;
    padding-right: 10px;
    width: 100%;
    height: 100%;
    border: none;
    padding-top: 10px;
    padding-bottom: 10px;
    resize: none;
  }

  .textarea:focus {
    outline: none !important;
    border:1.5px solid #3d50b0;
  }

  .textarea::-webkit-scrollbar{
    background-color: #f5f5f5;
    width: 6px !important;

}

.textarea::-webkit-scrollbar-thumb{
    background-color: #bbbcb6;
    border-radius: 10px;

}

.linearProgress {
    margin-top: 24px;
    width: 1150px;
}


.printDiv {
    margin-top: 30px;
}

.printDiv {
    display: flex;
    align-items: center;
    justify-content: end;
    width: 1150px;
}

.print {
    width: 32px;
    cursor: pointer;
}

.submit {
  margin-left: 16px;
  width: 181px;
  height: 46px;
  background-color: #3d50b0;
  color: white;
  border-radius: 28px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-left: 25px;
  padding-right: 20px;
  cursor: pointer;
}

.disabledSubmit {
  margin-left: 16px;
  width: 181px;
  height: 46px;
  background-color: rgb(224, 224, 224);
  color: rgb(163, 163, 163);
  border-radius: 28px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-left: 25px;
  padding-right: 20px;
  cursor: not-allowed;
}

.cell6,.cell10{
    border-bottom: 0;
}

.cell19,.cell23{
    border-bottom: 0;
}

@media screen and (max-width : 1024px){
    .main {
        height: 500vh;
        width: 1256px;
        overflow: visible;
    }


    @media (orientation : landscape){
        .main {
            height: auto;
        }
    }
}

.main {
    background-color: #f5f5f5;
    width: calc(100% - 244px);
    height: calc(100vh - 78px);
    overflow: scroll;
    padding-top: 44px;
    padding-left: 53px;
    padding-right: 53px;
    padding-bottom: 60px;
}

.main::-webkit-scrollbar{
    background-color: #f5f5f5;
    width: 6px !important;
}

.main::-webkit-scrollbar-thumb{
    background-color: #bbbcb6;
    border-radius: 10px;

}

.title {
    display: flex;
    align-items: center;
    font-family: "Apple_R";
    font-size: 22px;
}

.title img {
    margin-bottom: 3px;
    margin-right: 7px;
}

.selectMenu {
    margin-top: 24px;
    display: flex;
    justify-content: center;
}

.selectedMenu{
    width: 177px;
    height: 51px;
    border: 1px solid #cbcbcb;
    border-radius: 28px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 14px;
    color : #888888;
    font-family: "Apple_R";
    cursor: pointer;
}

.selectedMenu.active{
    color: white;
    background-color: #3d50b0;
    border: transparent;
}

.qnaWrite{
    cursor: pointer;
    position: fixed;
    bottom: 20px;
    right: 42px;
    background-color: #303030;
    color: white;
    width: 176px;
    height: 49px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50px;
    font-family: "Apple_R";
    z-index: 99999;
}

.qnaWrite2{
    cursor: pointer;
    position: fixed;
    bottom: 80px;
    right: 42px;
    background-color: #303030;
    color: white;
    width: 176px;
    height: 49px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50px;
    font-family: "Apple_R";
}


.qnaWrite img{
    margin-right: 10.9px;
}

.qnaWrite2 img{
    margin-right: 10.9px;
}

.pageNumberDiv{
    margin-top: 49px;
    display: flex;
    justify-content: center;
    margin-bottom: 120px;
}

.pageNumber {
    display: flex;
    width: 300px;
    justify-content: space-between;
}

.chevron {
    cursor: pointer;
}

.currentPage{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 35px;
    height: 35px;
    cursor: pointer;
}

.currentPage.active{
border-radius: 6px;
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
  background-color: #fff;
}

.questionBodyDiv{
    display: flex;
    justify-content: center;
}

.questions {
    margin-top: 22px;
}

.questionDiv {
    width: 800px;
    padding: 30px;
    padding-top: 24px;
    padding-bottom: 24px;
    background-color: white;
    margin-bottom: 60px;
    border-radius: 10px;
    box-shadow: rgb(20 20 20 / 2%) 2px 8px 12px 0px, rgb(20 20 20 / 2%) 0px 1px 3px 0px;
}

.questionheader {
    display : flex;
    align-items: center;
    border-bottom: 1px solid #d9d9d9;
    padding-bottom: 16.1px;
    justify-content: space-between;
}

.config {
    cursor: pointer;
}

.trash {
    width: 20px;
}

.avatar {
    display: flex;
    align-items: center;
}

.avatarImg {
    width: 20px;
}

.emailDiv {
    margin-left: 14px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.email {
    font-family: "Apple_B";
    font-size: 20px;
}

.questionDate {
    font-size: 12px;
    margin-bottom: 8px;
    color : rgb(120, 120, 120);
}

.questionBody {
    padding-top: 20.4px;
    padding-bottom: 16px;
}

.bodyTitle {
    font-family: "Apple_R";
    font-weight: 500;
    font-size: 20px;
}

.bodyDescription {
    white-space: pre-wrap;
    margin-top: 13.4px;
    font-family: "Apple_R";
    margin-bottom: 14.7px;
}

.imgBox {
    background-color: #f3f3f3;
    display: flex;
    justify-content: center;
    border-radius: 15px;
    max-height: 400px;
    position: relative;
}

.answerImageBox {

    margin-top: 25px;
}

.answerImg{
    
}

.img {
    object-fit: contain;
    max-width: 600px;
}

.imgBoxCover {
    width: 100%;
    height: 100%;
    position: absolute;
    border-radius: 15px;
    background-color: transparent;
    color: transparent;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    font-family: "Apple_R";
}

.imgBoxCoverTitle {
    margin-bottom: 14px;
    font-size: 18px;
}

.imgBoxCoverDescription {
    cursor: pointer;
}

.imgBoxCover:hover {
    background-color: rgba(30, 30, 30, 0.6);
    color: white;
    transition: all 200ms ease-in;
}

.imgListElement {
    margin-bottom: 10px;
    background-color: rgb(245, 245, 245);
    padding: 15px;
    border-radius: 8px;
}

.imgList {

}

.imgList :hover {
    background-color: rgb(210, 210, 210);
    transition: all 200ms ease-in;
}



.imgListElement_img{
    margin-right: 25px;
}

.imgListElement_left{
    display: flex;
}

.fileImage {
    width: 32px;
}

.imgListElement_name {
    display: flex;
    flex-direction: column;
    justify-content:start;
}

.imgListElement_namedetail {
    font-weight: 600;
}

.letshow {
    font-size: 10px;
    margin-top: 10px;
    font-weight: 600;
    color: rgb(120, 120, 120);
    cursor: pointer;
}

.modalDiv{
    /* padding-top: 40px;
    padding-bottom: 40px;
    cursor: grab;
    position: relative;
    top: 20px;
    left: 160px;
    vertical-align: middle;
    width: 1200px;
    height: 800px;
    text-align: center;
    background-color: rgb(242, 244, 248);
    overflow: scroll;
    box-sizing: border-box; */
    position: relative;



}

.imageWrapper {



    display: flex;
    /* justify-content: center; */
}


.modalImg {
    position: absolute;
    top: 0;
    /* transform: translate(-50%, 0); */
}

.imgOperator {
    display: flex;
    position: fixed;
    top: 90%;
    left: 50%;
    transform: translate(-50%,-50%);
    background-color : rgba(0,0,0,0.65);
    padding-top: 10px;
    padding-bottom: 10px;
    padding-left: 15px;
    padding-right: 15px;
    border-radius: 20px;
}

.minus {
    cursor: pointer;
    width: 20px;
    margin-right: 30px;
}

.percentDiv{
    color: white;
    font-weight: 600;
    font-family: "Apple_R";
}

.plus {
    cursor: pointer;
    width: 20px;
    margin-left: 30px;
    margin-right: 30px;
}

.maximize {
    cursor: pointer;
    width: 15px;
    margin-right: 30px;
}

.rotate {
    cursor: pointer;
    width: 15px;
}

.likeicon {
    display: flex;
    align-items: center;
    margin-left: 5px;
    margin-top: 14.1px;
    margin-bottom: 16.9px;
    font-family: "Apple_R";
    cursor: not-allowed;
}

.likeicon {
    cursor: pointer;
}

.liketext{
    margin-top: 2px;
    margin-left: 9.5px;
    color: rgb(160, 160, 160);
}

.liketext.activate{
    font-weight: 600;
    color: #3d50b0;
}

.answerTextFieldDiv{
    border-top: 1px solid #d9d9d9;
    padding-top: 28.5px;
}

.fileupload {
    margin-left: 12px;
}

.clip {
    width: 16px;
    cursor: pointer;
}

.uploadedFileClip {
    width: 16px;
    margin-right: 8px;
}

.answerFile{
    padding-left: 12px;
    padding-right: 12px;
    padding-top: 10px;
    padding-bottom: 10px;
    border-radius: 6px;
    display: flex;
    justify-content: space-between;
    margin-left: 12px;
    margin-right: 12px;
    margin-bottom: 12px;
    background-color: rgb(242, 244, 248);
}

.answerFileTitle {
    display: flex;
}

.uploadedFileTrash {
    width: 12px;
    cursor: pointer;
}

.answerSubmit {
    width: 100%;
    font-size: 14px;
    display: flex;
    justify-content:space-between;
    padding-bottom: 8px;
}

.answerSubmitText {
    cursor: not-allowed;
    margin-right: 12px;
    font-size: 16px;
    height: 15px;
    font-family: "Apple_R";
    background-color: rgb(242, 244, 248);
    color: rgb(190, 190, 190);
    width: 45px;
    height: 30px;
    border-radius: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.answerSubmitText.active{
    background-color: #3d50b0;
    color: white;
    transition: all 300ms ease-in;
    cursor: pointer;
}

.answerloading {
    display: flex;
    justify-content: center;
    margin-bottom: 12px;
}



.review {
    padding-top: 23px;
    padding-bottom: 23px;
    border-top: 1px solid #d9d9d9;
    display: flex;
    position: relative;
}

.reviewDescriptionDiv {
    margin-left: 19.1px;
    flex-grow: 1;
    padding-right: 30px;
}

.reviewDescription {
    white-space: pre-wrap;
}

.reviewAuthor{
    font-family: "Apple_B";
    font-size: 20px;
    margin-bottom: 4px;
}

.answerDate {
    font-size: 12px;
    margin-top: 12px;
    color : rgb(120, 120, 120);
}

.reviewTrashDiv {
    cursor: pointer;
    width: 20px;
    position: absolute;
    top: 20px;
    right: 8px;
}

.questionsLoading {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 300px;
}

/* @media screen and (max-width : 1024px){
    .main {
        height: 130vh;
    }
} */

@media screen and (max-width : 1024px){
    .main {
        height: 130vh;
        width: 1256px;
    }

    .bodyTitle {
        font-size: 44px;
        font-weight: 600;
    }

    .bodyDescription {
        font-size: 38px;
        margin-top: 38px;
        margin-bottom: 38px;
    }

    .reviewAuthor{
        font-size: 22px;
        font-weight: 500;
    }   

    /* .review{
        display: block;
        vertical-align: top;
    }

    .reviewAvatar{
        display: inline-block;
        vertical-align: top;

    }

    .reviewDescriptionDiv{
        display: inline-block;
        width: 950px;
    }

    .reviewAuthor{
        margin-bottom: 0px;
    } */




    @media (orientation : portrait){
        .imgOperator {
            top: 65%;
        }
    }

    .questionDiv {
        width: 1150px;
    }

    @media (orientation : landscape){
        .main {
            height: auto;
        }
    }
}



.main::-webkit-scrollbar{
    background-color: #f5f5f5;
    width: 6px !important;
}

.main::-webkit-scrollbar-thumb{
    background-color: #bbbcb6;
    border-radius: 10px;
}

.main {
    background-color: #f5f5f5;
    width: calc(100% - 244px); 
    padding-left: 46px;
    padding-top: 48px;
    height: calc(100vh - 78px);
    overflow: auto;
}

.attendanceBoard {
    display: flex;
    border-radius: 14px !important;
}

.attendanceBoardWeek {
    width: 568px !important;
    padding-bottom: 34.5px;
    background-color: #ffffff;
    margin-right: 28px;
    border-radius: 14px;
    box-shadow: rgb(20 20 20 / 4%) 0px 12px 24px 0px, rgb(20 20 20 / 2%) 0px 1px 3px 0px;
}

.attendanceBoardToday {
    width: 568px;
    padding-bottom: 34.5px;
    background-color: #ffffff;
    border-radius: 14px;
    box-shadow: rgb(20 20 20 / 4%) 0px 12px 24px 0px, rgb(20 20 20 / 2%) 0px 1px 3px 0px;
}

.attendanceBoardTitle {
    border-top-left-radius: 14px;
    border-top-right-radius: 14px;
    background-color: #ececec;
    display: flex;
    justify-content: space-between;
    padding-left: 33px;
    padding-right: 36px;
    height: 54px;
    align-items: center;
}

.attendanceBoardTitle_1 {
    font-family: "Apple_B";
    font-size: 19px;
    letter-spacing: -0.95px;
    color: #303030;
}

.attendanceBoardTitle_2 {
    cursor: pointer;
    width: 81px;
    height: 32px;
    background-color: #ffffff;
    border-radius: 28px;
    border: 1px solid #d4d4d4;
    line-height: 32px;
    text-align: center;
    font-family: "Apple_M";
    font-size: 15px;
    letter-spacing: -0.38px;
    color: #3d50b0;
}

.attendanceBoardDescription {
    padding-left: 33px;
    padding-right: 39px;
    padding-top: 11.5px;
}

.attendanceBoardDescription_1 {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-top: 16.5px;
    padding-bottom: 15.5px;
    border-bottom: 1px solid #e9e9e9;
}

.attendanceBoardDescription_1_1 {
    font-family: "Apple_R";
    font-size: 17px;
    color: #303030;
    letter-spacing: -0.85px;
}

.attendanceBoardDescription_1_2 {
    font-family: "Apple_R";
    color: #303030;
    letter-spacing: -0.9px;
    font-size: 18px;
}

.attendanceBoardDescriptionHour {
    font-size: 22px;
    font-family: "Apple_R";
    color: #d14e4e;
    display: inline-block;
    margin-right: 6px;
}

.attendanceBoardDescriptionMinute {
    font-size: 22px;
    font-family: "Apple_R";
    color: #d14e4e;
    display: inline-block;
    margin-right: 6px;
    margin-left: 8px;
}

.notificationDiv {
    margin-top: 39px;
    margin-bottom: 80px;
}

.notificationTitle {
    font-family: "Apple_B";
    font-size: 22px;
    margin-bottom: 16px;
}

.notifications {
    display: flex;
}

.notification {
    margin-right: 19px;
    cursor: pointer;
    box-shadow: rgb(20 20 20 / 2%) 2px 8px 12px 0px, rgb(20 20 20 / 2%) 0px 1px 3px 0px;
}

.notification_imageDiv {
    border-top-right-radius: 10px;
    border-top-left-radius: 10px;
    width: 277px;
    height: 140px;
    background-size: cover;
}

.notification_image {
    width: 277px;
}

.notification_description {
    padding-left: 23px;
    padding-bottom: 21px;
    padding-right: 21px;
    background-color:white;
    border-bottom-right-radius: 10px;
    border-bottom-left-radius: 10px;
}

.notification_description_text {
    border-radius: 10px;
    padding-top: 6px;
    padding-left: 23px;
    padding-bottom: 21px;
    padding-right: 21px;
    background-color: white;
    width: 277px;
    height: 279px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.notification_description1 {
    display: flex;
    justify-content: space-between;
    margin-bottom: 34px;
    padding-top: 18px;
}

.notification_description1_1 {
    width: 150px;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
    overflow: hidden;
    text-overflow: ellipsis;
    font-size: 17px;
    font-family: "Apple_B";
    letter-spacing: -0.85px;
}

.notification_description2 {
    display: flex;
    justify-content: space-between;
}

.new {
    width: 47.7px;
    height: 27px;
    background-color: #eeeeee;
    border-radius: 6px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #ff4848;
    font-weight: 600;
    font-size: 14px;
}

.eachDescription {
    font-size: 15px;
    line-height: 22px;
    max-width: 100%;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 3;
    overflow: hidden;
    text-overflow: ellipsis;
    margin-bottom: 70px;
    height: 65px;
    font-family: "Apple_L";
    letter-spacing: -0.75px;
}

.avatarText {
    font-family: "Apple_R";
}

.notification_description2_1 {
    font-family: "Apple_L";
    letter-spacing: -0.8px;
}

.notification_description2_2 {
    font-family: "Apple_L";
    cursor: pointer;
}



.tableDiv{
    width: 350px;
    margin: 0 auto;
    margin-top: 25px;
}

.modalTitle{
    width: 350px;
    margin: 0 auto;
    font-size: 17px;
    font-family: "Apple_B";
}

.tableTop{
    display: flex;
}

.tableTop .col1{
    padding: 10px 0;
    border-top: 2px solid #222;
    background-color: #f5f5f5;
    color: #000;
    font-family: "Apple_B";
}

.tableTop .col2{
    padding: 10px 0;
    border-top: 2px solid #222;
    background-color: #f5f5f5;
    color: #000;
    font-family: "Apple_B";
}

.tableRow{
    display: flex;
    border-bottom: 1px solid #ccc;
}

.col1{
    width: 400px;
    text-align: center;
    padding: 6px 0;
    font-size: 15px;
    line-height: 42px;
    font-family: "Apple_R";

}

.col2{
    width: 400px;
    text-align: center;
    padding: 6px 0;
    font-size: 15px;
    line-height: 42px;
    font-family: "Apple_R";
}

.modalPaper{

}

.tableRow .col1{
    font-family: "Apple_B";
}

.mine .col1{
    background-color: #1b49af;
    color: white;
}

.mine .col2{
    background-color: #1b49af;
    color: white;
}




@media screen and (max-width : 1024px){
    .main {
        height: 130vh;
        width: 1256px;
    }

    @media (orientation : landscape){
        .main {
            height: auto;
        }
    }
}









.searchMenu {
    margin-top: 23px;
    display: flex;
    margin-bottom: 33px;   
}

.searchMenuDiv {
    font-family: "Apple_R";
    width: 177px;
    height: 51px;
    border-radius: 28px;
    border: 1px solid #cbcbcb;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 14px;
    cursor: pointer;
}

.searchMenuDiv.active{
    background-color: #3d50b0;
    border: none;
    color: white;
}

.radioTitle {
    font-size: 20px;
    font-family: "Apple_SB";
    margin-bottom: 16px;
    margin-top: 24px;
}

.radiofont{
    font-family: "Apple_R";
}

.radioBox{
    border: 1px solid #CDD7E1;
    border-radius: 6px;
    padding: 24px;
}
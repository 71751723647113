.main {
    background-color: #f5f5f5;
    width: calc(100% - 244px);
    height: calc(100vh - 78px);
    padding-top: 44px;
    padding-left: 53px;
    padding-right: 53px;
    font-family: "Apple_R";
    overflow: auto;
}

.mainBoard {
    padding-bottom: 60px;
}

.mainBoard.photo{
    padding-bottom: 10px;
}

.main::-webkit-scrollbar{
    background-color: #f5f5f5;
    width: 6px !important;
}

.main::-webkit-scrollbar-thumb{
    background-color: #bbbcb6;
    border-radius: 10px;

}

.kindDiv {
    margin-bottom: 21px;
}

.title {
    display: flex;
    align-items: center;
    font-size: 22px;
    font-family: "Apple_B";
    color: #303030;
    margin-bottom:6px;
}

.title img {
    margin-right: 10px;
}

.searchMenu {
    margin-top: 23px;
    display: flex;
    margin-bottom: 33px;   
}

.searchMenuDiv {
    font-family: "Apple_R";
    width: 177px;
    height: 51px;
    border-radius: 28px;
    border: 1px solid #cbcbcb;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 14px;
    cursor: pointer;
}

.searchMenuDiv.active{
    background-color: #3d50b0;
    border: none;
    color: white;
}

.timeSelect {
    display: flex;

}

.dash {
    margin-left: 8px;
    margin-right: 8px;
    height: 50px;
    display: flex;
    align-items: center;
}

.blockDiv {
    margin-bottom: 18px;
}

.blockDiv div:first-child {
    font-family: "Apple_B";
}

.timeDiv{
    margin-bottom: 8px;
}

.workDescription {
    margin-bottom: 8px;
} 

.text {
    font-family: "Apple_R";
}

.submit {
    padding-top: 16px;
}

.submitBtn {
    cursor: pointer;
    background-color: #3d50b0;
    color: white;
    width: 100%;
    height: 60px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 32px;
    font-family: "Apple_R";
}

.board {
    padding-top: 32px;
    display: flex;
}

.phoneBoard {
    background-color: white;
    width: 140px;
    height: 140px;
    border-radius: 50%;
    flex-direction: column;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    margin-right: 32px;
}

.phoneBoard.active {
    background-color: #3d50b0;
}

.phoneBoard.active .phoneTitle {
    color: whitesmoke;
}

.phoneBoard:hover{
    background-color: rgb(200, 200, 200);
}

.phone {
    width: 32px;
}

.phoneTitle {
    font-family: "Apple_B";
    color: black;
}

.messageLaw {
    margin-top: 32px;
    font-family: "Apple_R";
}

.messageLawTitle {
    font-family: "Apple_B";
    font-size: 18px;
}

.bold {
    color: red;
    font-family: "Apple_B";
}

.messageLawText {
    margin-top: 8px;
    margin-left: 16px;
    font-family: "Apple_R";
}

.mobiletitle {
    font-family: "Apple_B";
}

.sendsms {
    margin-top: 16px;
}

.sendsmstitle {
    margin-top: 24px;
    font-family: "Apple_B";
    margin-bottom: 12px;
}

.sendsmsphone{
    font-family: "Apple_R";
    margin-bottom: 4px;
}

.sendsmsphonetext{
    margin-bottom: 8px;
}

.sendsmsdescription {
    font-family: "Apple_R";
    margin-bottom: 4px;
}

.sendsms {
    display: flex;
}

.firstDiv {
    margin-right: 12px;
}

.sendBtn {
    margin-top: 25px;
    margin-left: 8px;
}

.placeholeder {
    font-family: "Apple_R";
}

.sendName {
    font-family: "Apple_B";
    color: #3d50b0;
    margin-left: 6px;
    height: 80px;
}

.alertDiv {
    margin-top: 3px;
}

/* @media screen and (max-width : 1024px){
    .main {
        height: 130vh;
        overflow: auto;
    }
} */

@media screen and (max-width : 1024px){
    .main {
        height: 130vh;
        width: 1256px;
    }

    @media (orientation : landscape){
        .main {
            height: auto;
        }
    }
}
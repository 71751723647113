.headerWrapper{
    border-bottom: 0.031rem solid #b4b4b4;
}

.headerDiv{
    width: 90.6%;
    margin: 0 auto;
}

.header{
    padding-top: 0.5rem;
    width: 100%;
    padding-bottom: 0.5rem;
}

.header.ios{
    padding-top: 0;
}

.title{
    margin-top: 1rem;
    font-family: "Apple_B";
    font-size: 1.3rem;
    color: #263238;
}

.body{
    margin-top: 0.06rem;

}

.messageTitle {
    font-family: "Apple_SB";
    font-size: 0.94rem;
    margin-bottom: 1rem;
}

.nameText{
    font-size: 0.8rem;
}

.buttonDiv {
    margin-top: 1rem;
    display: flex;
    justify-content: flex-end;
}

.eachChatRoomDiv{
    padding-top: 1.291rem;
    padding-bottom: 1.031rem;
}

.eachChatRoom{
    /* display: grid;
    grid-template-columns: 1.6fr 6fr 2fr; */
    display: flex;
    width: 90.4%;
    margin: 0 auto;
}

.avatarDiv{
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 1rem;
}

.shapeLogo{
    width: 1.2rem;
}

.descriptionDiv{
    /* width: 100%; */
    flex-grow: 1;
    overflow: hidden;
}

.nameDiv{
    font-family: "Apple_B";
    font-size: 0.813rem;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: -0.39px;
    text-align: left;
    color: #2e2e2e;
    margin-bottom: 0.3rem;
}

.messageDiv{
    font-family: "Apple_M";
    font-size: 0.813rem;
    line-height: 1.38;
    letter-spacing: -0.39px;
    text-align: left;
    color: #2e2e2e;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 1;
    overflow: hidden;
    text-overflow: ellipsis;
}

.dateDiv{
    padding-left: 0.5rem;
    white-space: nowrap;
}

.dateText{
    font-family: "Apple_L";
    font-size: 0.75rem;
    letter-spacing: -0.36px;
    color: #959595;
    text-align: end;
    padding-right: 1rem;
}

.unreadNumber.numTransparent{
    display: none;
}

.unreadNumberDiv{
    display: flex;
    justify-content: flex-end;
    margin-right: 1rem;
}

.unreadNumber{
    background-color: #ff7043;
    margin-top: 0.3rem;
    width: 1.1rem;
    height: 1.1rem;
    font-size: 0.75rem;
    border-radius: 50%;
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: "Apple_SB";
}

.avatar{
    width: 40px;
    height: 40px;
}

.main{
    display: flex;
}

.secondBlock{
    margin-top: 50px;
}

.mainBlock{
    margin-right: 40px;
}

.title{
    font-family: "Apple_SB";
    margin-bottom: 8px;
}

table {
    font-family: arial, sans-serif;
    border-collapse: collapse;
}

th{
    background-color: #d9d9d9;
}
  
td, th {
    border: 0.5px solid black;
    text-align: center;
    width: 100px;
    height: 30px;
}

.in{
    color: blue;
}

.out{
    color: red;
}

.name{
    background-color: #e7e7e7;
}

.attendance{
    background-color: #e7e7e7;
    font-family: "Apple_L";
    font-size: 15px;
}   

.firstLine{
    display: flex;
    margin-top: 50px;
}

.firstTable{
    margin-right: 30px;
}

.secondLine{
    margin-top: 40px;
}

.thirdLine{
    margin-top: 40px;
}

.secondBlockBtns{
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 12px;
}
  

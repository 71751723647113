.header{
    padding-top: 1.5rem;
    width: 90.6%;
    margin: 0 auto;
    display: flex;
    align-items: center;
    padding-bottom: 1.5rem;
    border-bottom: 1px solid #eceff1;
}

.avatar{
    width: 4rem;
    height: 4rem;
    margin-right: 0.932rem;
}

.name{
    color: #263238;
    font-family: "Apple_B";
    font-size: 1.2rem;
    margin-bottom: 0rem;
}

.howMuchDay{
    color: #78909c;
    font-family: "Apple_SB";
    font-size: 0.8rem;
}
.main {
    min-width: 1500px;
    margin: auto;
}

.appBar {
    background-color: #3d50b0;
    display: flex;
    justify-content: space-between;
    height: 78px;
    align-items: center;
    padding-left: 47px;
    padding-right: 40px;
    
}

.logoDiv{
    width: 143.7px;
}

.logoSvg{
    width: 120.7px;
}

.profileDiv {
    display: flex;
    align-items: center;
}

.profileConfig {
    color: #e6e6e6;
    font-family: "Apple_R";
    font-size: 15px;
    padding-right: 7.5px;
    border-right: 1px solid #ffffff;
    margin-right: 16.5px;
    display: inline-block;
    cursor: pointer;
}

.avatarCircle {
    width: 36px;
    height: 36px;
    background-color: #f5f5f5;
    border-radius: 50%;
    margin-right: 11px;
    display: flex;
    justify-content: center;
    cursor: pointer;
}

.logout {
    color: #e6e6e6;
    font-family: "Apple_R";
    font-size: 15px;
    display: inline-block;
    cursor: pointer;
}

.avatar {
    width: 14px;
}

.body {
    display: flex;
    width: 100%;
}

.sideMenu {
    height: calc(100vh - 78px);
    width: 244px;
    overflow: auto;
    padding-bottom: 60px;
    /* z-index: 100;
    box-shadow: rgb(63 70 125 / 20%) 1px 0px 1.5px 0px, rgb(101 94 115 / 10%) 4px 2px 4px 0.5px; */
}


.sideMenu::-webkit-scrollbar{
    background-color: #f5f5f5;
    width: 4px !important;
}

.sideMenu::-webkit-scrollbar-track{
    background-color: transparent;
}

.sideMenu::-webkit-scrollbar-thumb{
    background-color: #bbbcb6;
    border-radius: 10px;
    scrollbar-width: thin !important;
}

.sideMenuProfile {
    margin-left: 26px;
    width: 192px;
    border-bottom: 1px solid #dfdfdf;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 27px;
    padding-bottom: 23.5px;
}

.where {
    display: flex;
    align-items: center;
    margin-bottom: 12px;
}

.inside {
    margin-right: 6.1px;
}

.outside {
    width: 17px;
    margin-right: 6.1px;
}

.outsideText {
    font-family: "Apple_R";
    letter-spacing: -0.75px;
    color: #828282;
    font-size: 18px;
}

.sideMenuAvatar {
    width: 66px;
    height: 66px;
    border-radius: 50%;
    background-color: #ebebeb;
    margin-bottom: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.ionAvatar{
    width: 66px;
    height: 66px;
    margin-bottom: 10px;
}

.logo2{
    width: 25px;
}

.sideMenuName {
    font-size: 18px;
    font-family: "Apple_R";
    letter-spacing: -0.85px;
    color: #303030;
}

.sideMenuList {
    padding-top: 26.5px;
    padding-left: 26.3px;
}

.sideMenuListSection {
    height: 22px;
    display: flex;
    margin-bottom: 18px;
    cursor: pointer;
    align-items: center;
}

.sideMenuListImg{
    width: 22.516px;
}

.noticeNumber {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: 8px;
    width: 34px;
    height: 19px;
    border-radius: 13px;
    background-color: #ff605a;
    color: white;
    font-family: "Apple_R";
    font-size: 12px;
    letter-spacing: -0.6px;
    margin-bottom: 2px;
}

.menuimgcontainer {
    width: 50px;
    display: flex;
    align-items: center;
}

.sdeMenuListImg {
    margin-right: 24.7px;
}

.sideMenuListText {
    font-family: "Apple_R";
    letter-spacing: -0.8px;
    color: #303030;
}

.sideMenuListTextActive {
    font-family: "Apple_B";
    letter-spacing: -0.8px;
    color: #3d50b0;
}

.sideMenuListText.mobile{
    display: none;
}

.sideMenuListTextActive.mobile{
    display: none;
}

.mobileProfile {
    display: none;
}

.diagnosisPicture{
    margin-left: 4px;
    width: 16px;
}


@media screen and (max-width : 1024px){
    /* .sideMenu {
        height: 130vh;
    } */

    .diagnosisPicture{
        margin-left: 0px;
        width: 16px;
    }

    .sideMenuListTextActive{
        display: none;
        font-family: "Apple_B";
        font-size: 18px;
    }

    .sideMenuListTextActive.mobile{
        display: block;
    }



    .main {
        min-width: 1256px;
    }

    .body {
        display: block;
    }

    .sideMenu {
        background-color: white;
        display: flex;
        justify-content: center;
        width: auto;
        height: auto;
        padding-bottom: 0;
        box-shadow: rgb(63 70 125 / 20%) 0px 1px 3px 0px, rgb(101 94 115 / 10%) 2px 4px 8px 1px;        z-index: 99999;
        margin-bottom: 12px;
    }

    /* .mobileProfile {
        display: block;
        background-color: white;
        box-shadow: rgb(63 70 125 / 20%) 0px 1px 3px 0px, rgb(101 94 115 / 10%) 2px 4px 8px 1px;
        font-family: "Apple_B";
        padding-left: 47px;
        padding-top: 15px;
        padding-bottom: 15px;
        z-index: 5;
        font-size: 24px;
    } */

    .sideMenuProfile {
        display: none;
    }

    .sideMenuListSection{
        flex-direction: column;
        align-items: center;
        justify-content: space-between;
        width: 80px;
        height: 60px;
        margin-bottom: 0;
        margin-right: 5px;
    }

    .sideMenuList {
        padding-top: 0;
        display: flex;
        height: 100%;
        align-items: center;
        padding-top: 35px;
        padding-bottom: 35px;

    }

    .sideMenuListText {
        display: none;
        font-family: "Apple_B";
        font-size: 18px;
    }

    .sideMenuListText.mobile{
        display: block;
    }

    .menuimgcontainer{
        margin: 0;
    }

    .sideMenuListText{
        margin: 0;
    }

    .menuimgcontainer{
        width: auto;
    }


    /* .sideMenu {
        height: 200px;
        display: flex;
        width: 100%;
    } */

}
.menuBar{
    font-size: 0.969rem;
    font-family: "Apple_SB";
    width: 100%;
    margin-top: 1.4rem;
    display: flex;
    justify-content: space-around;
    padding-bottom: 0.9rem;
    border-bottom: 0.08rem solid #cfd8dc;
    color: #607d8b;
    position: relative;
}

.menuBarSection{
    flex-grow: 1;
    text-align: center;
}

.menuBarStatus{
    flex-grow: 1;
    text-align: center;
}

.menuBarSection.active{
    color: #37474f;
}

.menuBarStatus.active{
    color: #37474f;
}

.menuUnderBar{
    position: absolute;
    width: 40%;
    border-top: 0.12rem solid #263238;
    bottom: 0;
    transition: all 200ms ease-out;
}

.eachTeacher{
    padding-top: 1rem;
    padding-bottom: 1rem;
    border-bottom: 1px solid #e0e0e0;
    position: relative;
}

.mainStatus{
    padding-bottom: 3rem;
}

.teacherDiv{
    width: 90.6%;
    margin: 0 auto;
    display: grid;
    grid-template-columns: 1.2fr 3fr;
}

.teacherImage{
    width: 100%;
    height: 100%;
    object-fit: contain;
}

.teacherDescription{
    padding-left: 1.2rem;
}

.teacherName{
    font-family: "Apple_SB";
    font-size: 0.9rem;
}

.teacherSubject{
    font-family: "Apple_L";
    color: #938c80;
    font-size: 0.8rem;
}

.location{
    font-family: "Apple_L";
    color: #938c80;
    font-size: 0.8rem;
}

.times{
    margin-top: 1rem;
    padding-left: 4.7%;
    padding-right: 4.7%;
    display: flex;
    flex-wrap: nowrap;
    overflow-x: scroll;
}

.times::-webkit-scrollbar{
    background-color: transparent;
    width: 0px !important;

}

.times::-webkit-scrollbar-thumb{
    background-color: transparent;
    border-radius: 0px;
}

.timeDiv{
    padding: 0.3rem 0.5rem 0.3rem 0.5rem;
    color: #3d50b0;
    margin-right: 0.6rem;
    border: 1px solid #3d50b0;
    border-radius: 0.25rem;
    font-family: "Apple_SB";
    font-size: 0.75rem;
    white-space: nowrap;
}

.timeDiv.mine{
    background-color: #3d50b0;
    color: white;
    border: 1px solid #3d50b0;
}

.timeDiv.occupied{
    background-color: #8e8e8e;
    color: white;
    border: 1px solid #8e8e8e;
}

.mailDiv{
    position: absolute;
    top: 15px;
    right: 25px;
    padding: 10px;

}

.mailDiv.active{
    animation-name: airplane;
    animation-duration: 2000ms;
    animation-iteration-count: infinite;
}

@keyframes airplane {
    0% {
        top : 15px;
        right : 25px;
    }
    50% {
        top : 30px;
        right : 40px;
    }
    100% {
        top: 15px;
        right: 25px;
    }
}

.mail{
    width: 20px;
}

.mail.active{
    animation-name: mail;
    animation-duration: 2000ms;
    animation-iteration-count: infinite;
}

@keyframes mail {
    0% {
        width : 15px;
    }
    50% {
        width : 25px;
    }
    100% {
        width: 15px;
    }
}
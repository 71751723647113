.btnsDiv{
    width: 1600px;
    display: flex;
    justify-content: flex-end;
    padding-top: 60px;
}

.container{
    padding-top: 20px;
    width: 1600px;
    display: flex;
}

.calendarDiv{
    display: flex;
    justify-content: center;
    align-items: flex-start;
    margin-bottom: 20px;
    width: 350px;
    flex: 1;
}

.tableDiv{
    flex: 3;
    padding-left: 30px;
}

.table{
    border-collapse: collapse;
    width: 100%;
}

.rowStyle{
    height: 35px;
}

.mealType{
    font-size: 20px;
    font-family: "Apple_B";
}

.colStyle{
    text-align: center;
}

.lastRow{
    background-color: rgba(0, 0, 0, 0.05);
}

.inputStyle{
    width: 100%;
    height: 100%;
    border: none !important;
    background-color: transparent;
    font-size: 20px;
    /* 클릭했을때도 border 안나오게 */
    outline: none;
    text-align: center;
    font-family: "Apple_L";
}

.submitBtnDiv{
    padding-top: 40px;
    width: 1600px;
    display: flex;
    justify-content: flex-end;
}

.calendarPaper{
    padding-top: 30px;
    display: flex;
    width: 1500px;
}

.calendarDiv{
    flex: 1.2;
}

.calendarDiv2{
    padding-left: 50px;
    flex: 3;
}
.headerDiv{
    width: 90.4%;
    margin: 0 auto;
}

.header{
    display: flex;
    align-items: center;
    width: 100%;
    padding-bottom: 1.1rem;
    padding-top: 1.1rem;
    border-bottom: 0.031rem solid #cfcfcf;
}

.header.ios{
    padding-top: 0px !important;
}


.backBtnDiv{
    display: flex;
    align-items: center;
    height: 1.5rem;
    padding-top: 0.125rem;
}

.arrowLeft{
    width: 1rem;
    margin-right: 1.094rem;
}

.titleText{
    font-size: 1.063rem;
    letter-spacing: -0.053rem;
    text-align: left;
    color: #2a2a2a;
    font-family: "Apple_B";
}

.bodyDiv{
    background-color: #f6f6f6;
}

.body{
    width: 90.6%;
    max-width: 450px;
    margin: 0 auto;
    padding-top: 1.031rem;
    padding-bottom: 1.875rem;
}

.section{
    padding: 1.375rem 0.875rem 1.313rem;
    border-radius: 0.625rem;
    border: solid 0.031rem #dbdbdb;
    background-color: #fff;
}

.section.first{
    margin-bottom: 0.656rem;
}

.sectionTitle{
    font-family: "Apple_B";
    font-size: 1.063rem;
    line-height: 1.29;
    letter-spacing: -0.027rem;
    text-align: left;
    color: #2a2a2a;
    margin-bottom: 1.094rem;
}

.inputDiv.second{
    margin-top: 1.225rem;
}

.radioDiv{
    margin-top: 1.225rem;
}

.radioLabel{
    font-family: "Apple_M";
    font-size: 0.906rem;
}

.inputTitle {
    font-family: "Apple_M";
    font-size: 0.844rem;
    letter-spacing: -0.043rem;
    text-align: left;
    color: #251e1c;
    margin-bottom: 0.556rem;
}


.inputWrapper{
    width: 100%;
    z-index: 1;
    border : solid 0.063rem #bdbdbd;
    aspect-ratio: 100/13.826;

    display: flex;
    align-items: center;

    border-radius: 0.188rem;
}


.inputWrapper.active{
    border-color: #3d50b0;
    border-color: blue;
    border-color: #1b49af;
}

.inputWrapper.error{
    border-color: red;
}


.input{
    border: none;
    outline: none;
    background-color: transparent;
    font-family: "Apple_R";
    font-size: 1rem;
    letter-spacing: -0.028rem;
    color: #070707;
    width: 100%;
    margin-left: 1.188rem;
}

.input::placeholder{
    letter-spacing: -0.047rem;
    color: #6b6b6b;
    font-family: "Apple_R";
}

.inputGrid{
    height: 100%;
    width: 100%;
    display: grid;
    grid-template-columns: 203.5fr 98.5fr;
    grid-gap : 0.563rem;
}

.inputGrid .inputWrapper{
    aspect-ratio: auto;
}

.certBtn{
    background-color: #bdbdbd;
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 1.344rem;
    font-family: "Apple_B";
    font-size: 0.813rem;
    cursor: pointer;
    transition: all 150ms ease-in;
}

.certBtn.active{
    background-color: #121a50;
}

.certBtn.active:active{
    background-color: #616785;
}

.loginTextDiv{
    margin-top: 0.906rem;
    font-family: "Apple_R";
    font-size: 0.938rem;
    color: #251e1c;
    letter-spacing: 0.028rem;
    margin-bottom: 1.875rem;
}

.goLogin{
    display: inline-block;
    margin-left: 0.5rem;
    text-decoration: underline;
}

.submitBtnDiv{
    padding-bottom: 1.875rem;
}

.submitBtn{
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
    background-color: #3d50b0;
    border-radius: 0.8rem;
    font-family: "Apple_SB";
}

.submitBtn:active{
    background-color: #6976b8;
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  /* padding-left: 1.219rem;
  padding-right: 1.219rem; */
  width: 90.6%;
  margin: 0 auto;
  padding-bottom: 1.1rem;
  padding-top: 1.1rem;
  border-bottom: 0.075rem solid #e0e0e0;
}

.header.ios {
  padding-top: 0px;
}

.backBtnDiv {
  display: flex;
  align-items: center;
  height: 1.5rem;
  padding-top: 0.125rem;

  padding-top: 0.375rem;
  padding-bottom: 0.25rem;

  width: 23%;
}

.arrowLeft {
  width: 1rem;
  margin-right: 1.094rem;
}

.titleText {
  font-size: 1.063rem;
  letter-spacing: -0.053rem;
  text-align: left;
  color: #2a2a2a;
  font-family: "Apple_B" !important;
  display: flex;
  align-items: center;
}

.pentosqure {
  width: 1.4rem;
}

.submit {
  width: 23%;
  text-align: end;
  font-family: "Apple_B";
  font-size: 0.95rem;
  color: black;
  display: flex;
  justify-content: space-between;
}

.my {
  color: #e0e0e0;
}

.my.active {
  color: #3d50b0;
}

.plus {
  width: 1.3rem;
}

.titleTextString {
  margin-right: 0.5rem;
}

.caret {
  width: 0.45rem;
}

.mainBody {
  padding-top: 0.3rem;
  padding-bottom: 0.3rem;
  background-color: #eceff1;
}

.body::-webkit-scrollbar{
  background-color: transparent;
  width: 0px !important;

}

.body::-webkit-scrollbar-thumb{
  background-color: transparent;
  border-radius: 0px;
}


.mainBody::-webkit-scrollbar{
  background-color: transparent;
  width: 0px !important;

}

.mainBody::-webkit-scrollbar-thumb{
  background-color: transparent;
  border-radius: 0px;
}

.avatar {
  width: 2.3rem;
  height: 2.3rem;
  margin-right: 0.5rem;
}

.eachQuestion {
  background-color: white;
  padding-top: 1rem;
  padding-bottom: 1rem;
  margin-bottom: 1.4rem;
  position: relative;
}

.trash{
  position: absolute;
  top: 1rem;
  right: 0.8rem;
  width: 1rem;
}


.eachQuestionName {
  /* padding-left: 0.8rem;
  padding-right: 0.8rem; */
  width: 90.6%;
  margin: 0 auto;
  display: flex;
  align-items: center;
}

.author {
  color: #424242;
  font-family: "Apple_SB";
  font-size: 0.8rem;
}

.dateString {
  color: #9e9e9e;
  font-family: "Apple_SB";
  font-size: 0.7rem;
}

.eachQuestionDescription {
  margin: 0 auto;
  margin-top: 0.6rem;
  /* padding-left: 0.8rem;
  padding-right: 0.8rem; */
  width: 90.6%;

  white-space: pre-wrap;
}

.eachQuestionTitle {
  font-family: "Apple_B";
  font-size: 1rem;
}

.eachQuestionText {
  margin-top: 0.3rem;
  font-family: "Apple_R";
  font-size: 0.9rem;
  line-height: 1.5;
}

.imagesWrapper {
  margin-top: 0.5rem;
  background-color: #252525;
}

.imageBox {
  position: relative;
  background-color: #252525;
  width: 100%;
}

.expandDiv{
  position: absolute;
  top: 0.5rem;
  right: 0.5rem;
  padding: 0.1rem;
}

.expand{
  z-index: 5;
  width: 1.2rem;
}

.questionImg {
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  object-fit: contain;
}

.eachQuestionFooter {
  width: 90.6%;
  margin: 0 auto;
  margin-top: 1rem;
  /* padding-left: 0.8rem;
  padding-right: 0.8rem; */
}

.eachQuestionInnerFooter {
  padding-top: 1rem;


  width: 100%;
  border-top: 0.1rem solid #eceff1;
  display: flex;
  justify-content: space-between;
}

.likeDiv {
  display: flex;
  align-items: center;
}

.thumb {
  width: 1rem;
  margin-right: 0.65rem;
}

.understandText {
  font-family: "Apple_R";
  font-size: 0.8rem;
  color: #9e9e9e;
}

.understandText.active {
  color: #3d50b0;
}

.message {
  width: 1rem;
}

.replyDiv {
  display: flex;
  align-items: center;
}

.replyNumber {
  color: #9e9e9e;
  color: #3d50b0;
  font-family: "Apple_R";
  font-size: 1rem;
  margin-left: 0.3rem;
}

@media only screen and (min-width : 500px){
  .header{
    padding-top: 1.1rem !important;
  }
}

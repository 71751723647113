
.footer{
    position: fixed;
    z-index: 99;
    width: 100%;
    bottom: 0;
    background-color: white;
    border-top: 0.031rem solid #c9c9c9;

}

.innerFooter{
    width: 100%;
    margin: 0 auto;
    /* aspect-ratio: 100 /18.133; */
    height: 4.25rem;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr;

}

.footerMenu{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100%;
}

.footerIconDiv{
    height: 1.945rem;
    height: 1.8rem;
}

.hotMenu:active{
    background-color: #ececec;
}

.hotMenuDiv{
    margin-top: 2.237rem;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    row-gap: 1.491rem;
}

.hotMenuIcon{
    height: 2.0851rem;
    display: flex;
    justify-content: center;
    align-items: flex-start;
}

.hotMenuIcon.abc{
    align-items: center;
}

.hotMenuText{
    display: flex;
    justify-content: center;
    font-family: "Apple_R";
    color: #262626;
    letter-spacing: -0.39px;
    font-size: 0.812rem;
}

.homeIcon{
    width: 1.257rem;
    height: 1.323rem;
}

.bookIcon{
    width: 1.447rem;
    height: 1.121rem;
}

.messageIcon{
    width: 1.179rem;
    height: 1.209rem;
}

.calendarIcon{
    width: 1.254rem;
    height: 1.167rem;
}

.menuIcon{
    width: 1.23rem;
    height: 0.768rem;
}

.footerIconTextDiv{
    display: flex;
    justify-content: center;
    font-family: "Apple_R";
    font-size: 0.656rem;
    letter-spacing: -0.26px;
    color: #707070;
}

.footerIconTextDiv.active{
    font-family: "Apple_B";
    font-size: 0.625rem;
    color: #3b55dc;
}

.footerIconDiv.message{
    position: relative;
}

.unreadNumber{
    position: absolute;
    top: -0.5rem;
    right: -0.5rem;
    background-color: #ff7043;
    color: white;
    border-radius: 0.3rem;
    font-family: "Apple_SB";
    padding-right: 0.25rem;
    padding-left: 0.25rem;
    padding-top: 0.1rem;
    padding-bottom: 0.1rem;
    font-size: 0.75rem;
}



.title{
    font-family: "Apple_B";
    font-size: 24px;
}

.main{
    padding-top: 30px;

    margin: 0 auto;
}



.name{
    font-size: 24px;
    font-family: "Apple_R";
    margin-bottom: 24px;
}

.dividerDiv{
    margin-top: 24px;
}

.eachDividerDiv{
    margin-top: 40px;
}

.btnDiv{
    
}

.whichMonth{
    margin-bottom: 24px;
}

.whichShow{
    margin-top: 24px;
    width: 500px;
}
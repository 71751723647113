.pictureBody {
    width: 90.6%;
    margin: 0 auto;
    box-sizing: border-box;
}

.pictureTitle {
    color: #263238;
    margin-top: 1.5rem;
    font-family: "Apple_B";
    font-size: 1.3rem;
    color: #263238;
    margin-bottom: 3rem;
    box-sizing: border-box;

}

.embla {
    overflow: hidden;
}

.embla_container {
    display: flex;
    column-gap: 20px;
}

.embla_slide {
    flex: 0 0 50%;
}

.avatar{
    width: 100%;
    opacity: 0.3;
    transition: all 200ms ease-in;
    transform: scale(0.8);
}

.avatar.active{
    opacity: 1;
    transform: scale(1);
}

.numberDiv{
    margin-top: 1.4rem;
    display: flex;
    justify-content: center;
    font-family: "Apple_B";
    font-size: 1.2rem;
}
.main {
    background-color: #f5f5f5;
    width: calc(100% - 244px);
    height: calc(100vh - 78px);
    padding-top: 44px;
    padding-left: 53px;
    padding-right: 53px;
    font-family: "Apple_R";
    overflow: auto;
}

.mainBoard {
    max-width: 1180px;
    padding-bottom: 60px;
}

.main::-webkit-scrollbar{
    background-color: #f5f5f5;
    width: 6px !important;
}

.main::-webkit-scrollbar-thumb{
    background-color: #bbbcb6;
    border-radius: 10px;

}

.kindDiv {
    margin-bottom: 21px;
}

.title {
    display: flex;
    align-items: center;
    font-size: 22px;
    font-family: "Apple_B";
    color: #303030;
    margin-bottom:6px;
}

.title img {
    margin-right: 10px;
}

.searchMenu {
    margin-top: 23px;
    display: flex;
    margin-bottom: 33px;   
}

.searchMenuDiv {
    font-family: "Apple_R";
    width: 177px;
    height: 51px;
    border-radius: 28px;
    border: 1px solid #cbcbcb;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 14px;
    cursor: pointer;
}

.searchMenuDiv.active{
    background-color: #3d50b0;
    border: none;
    color: white;
}


.titleDescriptionDiv {
    display: flex;
    justify-content: space-between;
    margin-bottom: 21px;
}

.titleDescription {
    margin-bottom: 0px;
    font-size: 14px;
}

.dayText {
    font-family: "Apple_R";
}

.toggleText {
    font-family: "Apple_R";
}

.isName {
    display: flex;
    justify-content: end;
}

.buttonStyles {
    display: flex;
    justify-content: flex-end;
}

.spanStyles {
    font-family: "Apple_R";
    font-size: 14px;
}

.spanStyles2 {
    font-family: "Apple_B";
    font-size: 14px;
}

.divStyle {
    margin-bottom: 12px;
    font-family: "Apple_R";
    font-size: 18px;
}

.imageStyle {
    width: 32px;
    margin-left: 6px;
    cursor: pointer;
}

.filenameDiv {
    display: flex;
    align-items: center;
}

.filenameStyle {
    display: flex;
    align-items: center;
    margin-left: 12px;
    margin-bottom: 6px;
}

.filenameDivStyle {
    font-family: "Apple_R";
}

.times {
    width: 12px;
    margin-right: 16px;
    vertical-align: text-bottom;
    margin-left: 6px;
    cursor: pointer;
}

.reviewTitle {
    font-family: "Apple_R";
    font-size: 20px;
    margin-left: 12px;
    margin-bottom: 24px;
}

.reviewDiv {
    margin-top: 36px;
}

.satisfyDiv {
    margin-bottom: 21px;
}

.heartDiv {
    margin-left: 24px;
}

.mysearchDate {
    display: flex;
    justify-content: space-between;
}

.mysearchDate div{
    font-family: "Apple_R";
    font-size: 14px;
    margin-right: 6px;
    margin-bottom: 2px;
}

.mysearchDate div:first-child{
    color: #3d50b0;
    margin-left: 4px;
}

.mysearchDescription {
    font-family: "Apple_R";
    font-size: 14px;
    margin-top: 4px;
    margin-left: 6px;
}

.bottomDiv {
    display: flex;
    justify-content: space-between;
}

.trashImg {
    cursor: pointer;
    margin-top: 6px;
    margin-right: 8px;
    width: 24px;
}

.trashImg:hover{
    background-color: rgba(255,255,255);
}

.imagesDiv {
    display: flex;
    justify-content: center;
    align-items: center;
}

.images {
    cursor: pointer;
    width: 25px;
}

.caution {
    font-size: 16px !important;
    font-family: "Apple_B" !important;
}

.caution2 {
    font-size: 18px !important;
    font-family: "Apple_B" !important;
    margin-bottom: 6px;
}

.targetdate {
    margin-top: 16px;
    font-family: "Apple_R";
    font-weight: 600;
}

/* @media screen and (max-width : 1024px){
    .main {
        height: 130vh;
        overflow: auto;
    }
} */

.studentsBox {
    display: flex;
    flex-wrap: wrap;
    max-width: 1150px;
}

.insideStudent {
    padding-top: 6px;
    padding-bottom: 6px;
    text-align: center;
    color: blue;
    width: 160px;
    border: 1px solid black;
    font-family: "Apple_R";
}

.outsideStudent {
    padding-top: 10px;
    padding-bottom: 10px;
    border: 1px solid black;
    text-align: center;
    color: red;
    width: 160px;
    font-family: "Apple_R";

}

.studentTitle {
    margin-bottom: 24px;
    font-size: 24px;
    font-family: "Apple_B";
    margin-top: 24px;
}

.teacherTitle {
    margin-bottom: 24px;
    font-size: 24px;
    font-family: "Apple_B";
    margin-top: 48px;
}

.modalFirstDiv{
    display: flex;
    justify-content: center;
}

.cancelBtn {
    cursor: pointer;
    display: flex;
    justify-content: end;   
}

.cancelBtn img {
    width: 32px;
    margin-right: 16px;
    margin-bottom: 16px;
}

.cancelBtn img:hover{
    background-color: rgb(200, 200, 200);
    border-radius: 50%;
}

@media screen and (max-width : 1024px){
    .main {
        height: 130vh;
        width: 1256px;
    }

    @media (orientation : landscape){
        .main {
            height: auto;
        }
    }
}






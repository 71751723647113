.header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    /* padding-left: 1.219rem;
    padding-right: 1.219rem; */
    width: 90.6%;
    margin: 0 auto;
    padding-bottom: 1.1rem;
    padding-top: 1.1rem;
  }
  
  .header.ios {
    padding-top: 0px;
  }
  
  .backBtnDiv {
    display: flex;
    align-items: center;
    height: 1.5rem;
    padding-top: 0.125rem;
  
    padding-top: 0.375rem;
    padding-bottom: 0.25rem;
  
    width: 23%;
  }
  
  .arrowLeft {
    width: 1rem;
    margin-right: 1.094rem;
  }
  
  .titleText {
    font-size: 1.063rem;
    letter-spacing: -0.053rem;
    text-align: left;
    color: #2a2a2a;
    font-family: "Apple_B" !important;
    display: flex;
    align-items: center;
  }
  
  .pentosqure {
    width: 1.4rem;
  }
  
  .submit {
    width: 23%;
    text-align: end;
    font-family: "Apple_B";
    font-size: 0.95rem;
    color: black;
    display: flex;
    justify-content: flex-end;
  }
  
  .my {
    color: #e0e0e0;
  }
  
  .my.active {
    color: #3d50b0;
  }
  
  .plus {
    width: 1.3rem;
  }
  


.body{
    width: 90.4%;
    margin: 0 auto;
    margin-top: 0.5rem;
}

.body::-webkit-scrollbar{
    background-color: transparent;
    width: 0px !important;

}

.body::-webkit-scrollbar-thumb{
    background-color: transparent;
    border-radius: 0px;
}

.height.android{
    height: 30rem;
}

.modalBody{
    width: 90.4%;
    margin: 0 auto;
}

.modalTitle{
    font-size: 1.1rem;
    font-family: "Apple_SB";
    margin-bottom: 1rem;
}

.text{
    font-family: "Apple_R";
    margin-bottom: 0.2rem;
}

.modalTitle2{
    margin-top: 2rem;
    font-size: 1.1rem;
    font-family: "Apple_SB";
    margin-bottom: 1rem;
}

.submitButton{
    display: flex;
    position: fixed;
    bottom: env(safe-area-inset-bottom);
    z-index: 99;
    justify-content: center;
    align-items: center;
    background-color: #3d50b0;
    color: white;
    font-family: "Apple_SB";
    width: 100%;
    min-height: 4rem;
}

.submitButton.safeArea{
    width: 90%;
    min-height: 4rem;
    left: 50%;
    transform: translateX(-50%);
    border-radius: 0.8rem;
}

@media only screen and (min-width : 500px){
    .header{
      padding-top: 1.1rem !important;
    }
  }
  

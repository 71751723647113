.main{
    padding-top: 24px;
    padding-bottom: 24px;
    padding-left: 18px;
    padding-right: 18px;
    width: 100%;
}

.title{
    font-family: "Apple_SB";
    margin-bottom: 8px;
}

.description{
    font-family: "Apple_R";
    margin-bottom: 24px;
}
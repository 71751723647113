
.timeSelectWrapper{
    margin-top: 2rem;
    position: relative;
    z-index: 2;
    display: grid;
    grid-template-columns: 0.2fr 1fr 1fr 0.2fr;
}

.timeSelectWrapper2{
    margin-top: 2rem;
    position: relative;
    z-index: 2;
    display: grid;
    grid-template-columns: 2.5fr 1fr 2.5fr;
}

.timeSelectBox{
    width: 100%;
    text-align: center;
    overflow: scroll;
    height: 6rem;
    scroll-snap-type: y mandatory;
    scroll-behavior: smooth;
    -webkit-overflow-scrolling: touch;
    -webkit-overflow-scrolling: auto !important;
    z-index: 2;
}

.timeSelectBox::-webkit-scrollbar{
    display: none;
}

.timeSelectElement{
    font-family: "Apple_SB";
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
    padding-left: 1rem;
    padding-right: 1rem;
    scroll-snap-align: center;
    z-index: 1;
    position: relative;
    opacity: 0.1;
}

.layer{
    z-index: 0;
    position: absolute;
    width: 100%;
    height: 2.4rem;
    top: 50%;
    left: 0;
    transform: translateY(-50%);
    background-color: #f5f5f5;
    border-radius: 0.4rem;
}

.text{
    z-index: 2;
    display: flex;
    align-items: center;
    line-height: 3rem;
    justify-content: center;
    font-family: "Apple_SB";
    color: #9e9e9e;
    /* font-size: 0.85rem; */

    /* 모바일 자동 확대 방지 (브라우저마다 지원 여부 다름) */
  -webkit-text-size-adjust: none; 
  -ms-text-size-adjust: none;     
  -moz-text-size-adjust: none;    
  text-size-adjust: none;      
}
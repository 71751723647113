.header{
    
}

.headerDiv{
    width: 90.6%;
    margin: 0 auto;
}

.headerDiv{
    padding-bottom: 0.5rem;
}

.headerDiv.android{
    padding-top: 0.5rem;
}

.headerContents{
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.headerContents > div {
    flex: 1;
}

.xmarkDiv{
    display: flex;
    align-items: center;
}

.xmark{
    width: 1.2rem;
}


.title{
    text-align: center;
    font-size: 1.2rem;
    font-family: "Apple_B";
}



.modalBody{
    text-align: center;

    display: flex;
    align-items: center;
    justify-content: center;
}

.modalImg{

}

.modalBodyDiv{

}

.modalBody{

}


.modalImg{

}

@media only screen and (min-width : 500px){
    .headerDiv{
        padding-top: 0.5rem;
    }
}
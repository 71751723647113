.main{
    background-color: #f5f5f5
}

.titleDiv{
    width: 90.6%;
    padding-top: 1rem;
    margin: 0 auto;
    font-family: "Apple_SB";
}

.tableWrapper{
    padding-left: 4.7%;
    padding-right: 4.7%;
    overflow-x: scroll;
}

.tableDiv{
    margin-left: 6px;
    margin-top: 1.5rem;
    display: grid;
    grid-template-columns: repeat(2, 0.5fr) repeat(4, 1fr);
    grid-template-rows: 2.5rem 2.5rem 5.5rem 2.5rem 3.5rem 2.5rem 6.5rem 5.5rem;
    box-sizing: border-box;
    border-top: 1px solid #787878;
    width: 820px;
}

.tableDiv > .cell {

}

.cell{
    font-size: 0.75rem;
    font-family: "Apple_R";
    padding-top: 0.5rem;
    padding-left: 0.5rem;
    padding-right: 0.5rem;
    white-space: pre-wrap;
    overflow-y: scroll;
    padding-bottom: 0.5rem;
}

.cell0, .cell1, .cell2, .cell3, .cell4, .cell5, .cell6, .cell11, .cell16, .cell17, .cell22, .cell27, .cell32, .cell37{
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0;
    font-family: "Apple_SB";
    text-align: center;
}

.cell7, .cell8, .cell9, .cell10{
    display: flex;
    align-items: center;
    padding-top: 0;
    padding-bottom: 0;
}

.cell5{
    text-align: center;
}

.cell{
    box-sizing: border-box;
    display: flex;
    background-color: white;
}
.cell0{
    grid-column: 1/3;
}

.cell5{
    grid-row: 2/4;
}

.cell16{
    grid-row: 4/7;
}

.cell32{
    grid-column: 1/3;
}

.cell37{
    grid-column: 1/3;
}

.cell38{
    grid-column: 3/7;
}

.cell0, .cell1, .cell2, .cell3, .cell4{
    background-color: #ebebeb;
}  

.cell0, .cell1, .cell2, .cell3{
    border-right: 1px solid #cbcbcb;
}  

.cell4{
    border-right: 1px solid transparent;
}

.cell6, .cell7, .cell8, .cell9{
    border-right: 1px solid #cbcbcb;
    border-bottom: 1px solid #cbcbcb;
}  

.cell10{
    border-bottom: 1px solid #cbcbcb;
}

.cell5{
    border-right: 1px solid #cbcbcb;
    border-bottom: 1px solid #707070;
}

.cell11, .cell12, .cell13, .cell14{
    border-right: 1px solid #cbcbcb;
    border-bottom: 1px solid #707070;
} 

.cell15{
    border-bottom: 1px solid #707070;
}

.cell16{
    border-bottom: 1px solid #707070;
    border-right: 1px solid #cbcbcb;
}

.cell17, .cell18, .cell19, .cell20, .cell21, .cell22, .cell23, .cell24, .cell25, .cell26{
    border-right: 1px solid #cbcbcb;
    border-bottom: 1px solid #cbcbcb;
}

.cell27, .cell28, .cell29, .cell30, .cell31{
    border-right: 1px solid #cbcbcb;
    border-bottom: 1px solid #707070;
}

.cell32, .cell33, .cell34, .cell35{
    border-right: 1px solid #cbcbcb;
    border-bottom: 1px solid #707070;
}

.cell36{
    border-bottom: 1px solid #707070;
}

.cell37{
    border-right: 1px solid #cbcbcb;
}

.titleDiv{
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.chevronsDiv{
    display: flex;
    width: 4rem;
    justify-content: space-between;
}

.chevron{
    width: 1rem;
}
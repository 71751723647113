.main{
    padding-top: 32px;
    padding-bottom: 32px;
    width: 85%;
}

.main::-webkit-scrollbar{
    background-color: #f5f5f5;
    width: 0px !important;
}

.main::-webkit-scrollbar-thumb{
    background-color: #bbbcb6;
    border-radius: 10px;
}

.whichMonth{
    margin-bottom: 24px;
}

.title{
    font-family: "Apple_B";
    font-size: 20px;
    margin-bottom: 32px;
}

.eachDayDivTitle{
    font-family: "Apple_R";
    font-size: 18px;
    width: 45px;
}

.eachdayDiv{
    margin-top: 12px;
    display: flex;
    align-items: center;
}

.eachDayDivTextform{
    flex-grow: 1;
    border-radius: 0;
}

.textForm{
    border: 0;
    font-size: 16px;
    font-family: "Apple_R";
    height: 50px;
    width: 100%;
    padding-left: 16px;
    padding-right: 16px;
    background-color: #fafafa;
    border: 1px solid #ddd;
    border-radius: 0;
}

/* .textForm:focus{
    outline: none !important;
} */

.submitBtnDiv{
    margin-top: 32px;
    width: 100%;
    color: white;
}

.helperText{
    margin-top: 12px;
    font-size: 14px;
    font-family: "Apple_L";
}
.body {
    padding-left: 20px;
    width: 100%;
    overflow: auto;
    height : calc(100vh - 78px);
    max-height: 85vh;
}

.header {
    height: 118px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #f8f8f8;
    width: 100%;
    font-family: "Apple_B";
    font-size: "28px";
    letter-spacing: "-0.7px";
    line-height: 37px;
    color: #494951;
    font-size: 28px;
    margin-bottom: 46px;
}

.currentMenu {
    display: flex;
    justify-content: center;
}

.currentMenu img {
    margin-left: 23.2px;
    margin-right: 23.2px;
}

.circle {
    font-family: "Apple_R";
    width: 142px;
    height: 142px;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 2px solid #e6e6e6;
    border-radius: 50%;
}

.circle.active{
    border: 4px solid #566ee8;
    color: #566ee8;
    font-family: "Apple_B";
}

.description {
    margin-top: 27px;
    display: flex;
    justify-content: center;
    font-family: "Apple_R";
}

.descriptionText {
    width: 550px;
    line-height: 26px;
    margin-bottom: 54.5px;
}

.descriptionText2 {
    margin-bottom : 41px
}

.profileBoard {
    display: flex;
    justify-content: center;
}

.profileBoardDescription {
    width: 474px;
}

.beforeUniversityTitle {
    font-size: 26px;
    font-family: "Apple_B";
    color: #303030;
    letter-spacing: -1.3px;
    margin-bottom: 31px;
}

.beforeUniversityTitle2 {
    margin-top: 41px;
}

.beforeUniversityTitle3 {
    margin-top: 45.8px;
    margin-bottom: 15px;
}

.fullWidthText2 {
    margin-top: 25.5px;
}

.subtitle {
    font-family: "Apple_R";
    font-size: 18px;
    letter-spacing: -0.45px;
    color: #606060;
    margin-bottom: 9px;
}

.subtitle2 {
    font-family: "Apple_R";
    font-size: 16px;
    letter-spacing: -0.45px;
    color: #606060;
    margin-bottom: 9px;
    width: 40px;
    height: 42px;
    margin-right: 12px;
}

.subtitle3 {
    margin-bottom: 13px;
}

.subtitle5 {
    margin-top: 33px;
}

.textField {
    margin-bottom: 33px;
}

.justFont {
    font-family: "Apple_R";
}

.twoTextField {
    display: flex;
}

.twoTextField2 {
    display: flex;
    align-items: center;
}

.firstTextField {
    margin-right: 13px;
}

.secondTextField {
    margin-left: 13px;
}

.secondDiv {
    margin-top: 23px;
}

.radioGroup {
    width: 100%;
    margin-bottom: 22px;
}

.radioText {
    margin-right: 20px;
    font-size: 18px;
    color: #606060;
    font-family: "Apple_R";
}

.radioDiv {
    margin-bottom: 51.5px;
}

.formcontroldiv {
    width: 50px;
}

.radioset {
    display: flex;
    align-items: center;
}

.radiosetDiv {
    display: flex;
    margin-bottom: 12px;
}

.radioset1 {
    margin-right: 20px;
}
.radioset2 {
    margin-left: 20px;
}

.nextBtnDiv {
    border-top: 1px solid #d5d5d5;
    padding-top: 48.5px;
    margin-bottom: 80px;
}

.nextBtn {
    cursor: pointer;
    font-size: 20px;
    font-family: "Apple_R";
    letter-spacing: "-1px";
    width: 100%;
    height: 62px;
    border-radius: 31px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #3d50b0;
    color: white;
}

.password {
    display: flex;
}

.password fieldset {
    border-radius: 3px 0 0 3px;
}

.password.active fieldset {
    border-color: blue;
}

.certifyButton {
    cursor: pointer;
    font-family: "Apple_B";
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 220px;
    background-color: #3d50b0;
    border-radius: 0 3px 3px 0;
    height: 56px;
}



.inputRounded {
    border-radius: 0px !important;
}

.inputRounded .MuiOutlinedInput-root{
    border-radius: 0px;
}

.successhelpertext {
    color: blue;
    font-family: "Apple_B";
}

.failhelpertext {
    font-family: "Apple_B";
}

.passwordDiv {
    margin-bottom: 80px;
}

.radioRowDiv {
    display: flex;
}

.helperText {
    margin-left: 440px;
    font-size: 14px;
}

.helperText.active{
    margin-left: 430px;
    color: blue;
    font-size: 15px;
}

.wideProfileBoard{
    display: flex;
    justify-content: center;
}

.wideProfileBoardDescription {
    width: 844px;
}

.wideTitle {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 72px;
    border-top: 3px solid #707070;
    border-bottom: 1px solid #cbcbcb;
    font-size: 26px;
    color: #3d50b0;
    letter-spacing: -1.3px;
    font-family: "Apple_B";
    margin-bottom: 47px;
}

.eachPriceTitle {
    font-family: "Apple_R";
    font-size: 20px;
    letter-spacing: -0.5px;
    color: #606060;
    margin-bottom: 13.5px;
}

.eachPriceBox {
    width: 844px;
    height: 182px;
    border : 1px solid #e2e2e2;
    padding-left: 58px;
    padding-right: 79px;
    display: flex;
}

.eachPriceBox.second{
    height: 233px;
    margin-bottom: 40px;
}

.eachPriceBoxTitle {
    margin-top: 33.5px;
    margin-bottom: 36.5px;
    padding-right: 105px;
    border-right: 1px solid #d4d4d4;
}

.eachPriceBoxTitle div{
    font-size: 18px;
    font-family: "Apple_R";
    color: white;
    width: 158.5px;
    height: 44.5px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 22px;
    background-color: #566ee8;
}

.eachPriceBoxDescription {
    width: 100%;
    padding-left: 72.5px;
    margin-top: 23px;
}

.eachPriceBoxDescription .eachPriceBoxDescription_1:last-child{
    margin-bottom: 0px;
}

.eachPriceBoxDescription_1 {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 11px;
}

.eachPriceBoxDescription .eachPriceBoxDescription_1:last-child{
    margin-bottom: 0px;
}

.eachPriceBoxDescription_1 div:first-child {
    width: 102px;
    display: flex;
    justify-content: end;
}

.eachPriceBoxDescription_1 div:nth-child(2) {
    width: 152px;
    height: 39px;
    background-color: #ecefff;
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: "Apple_R";
    letter-spacing: -0.45px;
    color: #606060;
    font-size: 18px;
}

.eachPriceBoxDescription_1 div:nth-child(2) span {
    color: #ff4848;
}

.wideProfileBoardDescription .eachPriceDiv:last-child{
    margin-top: 38.5px;
}

.eachPriceDiv.second{
    margin-top: 38.5px;
}

.eachCoachingPrice {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 11px;
}

.eachCoachingPrice div:first-child {
    width: 88px;
    display: flex;
    justify-content: end;
}

.eachCoachingPrice div:nth-child(2){
    width: 50px;
    display: flex;
    justify-content: end;
}

.eachCoachingPrice div:nth-child(3){
    width: 152px;
    height: 39px;
    background-color: #ecefff;
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: "Apple_R";
    letter-spacing: -0.45px;
    color: #606060;
    font-size: 18px;
}

.eachCoachingPrice div:nth-child(3) span {
    color: #ff4848;
}

.priceDescriptionTextDiv {
    margin-bottom: 85px;
}

.priceDescriptionText{
    font-family: "Apple_R";
    color: #606060;
    letter-spacing: -0.4px;
}

.radioWrapDiv {
    display: flex;
    justify-content: center;
    margin-bottom: 57.5px;
}

.certifyDiv {
    display: flex;
    justify-content: center;
    margin-bottom: 127.5px;
}

.nextBtn.third{
    width: 424px;
}

.checkBox {
    width: 100%;
    padding-top: 40.5px ;
    padding-left: 46px;
    padding-right: 46px;
    padding-bottom: 34px;
    border: 1px solid #e2e2e2;
    box-shadow: 0 3px 6px 0 rgba(184, 184, 184, 0.16);
    border-radius: 4px;
    margin-bottom: 36px;
    font-family: "Apple_R";
    font-size: 18px;
    color: #606060;
    letter-spacing: -0.45px;
}

.checkBox .text {
    margin-bottom: 24.5px;
}

.text div{
    display: flex;
}

.text div div:first-child{
    margin-right: 6px;
    line-height: 32px;
}

.text div div:nth-child(2){
    line-height: 32px;
}

.progressbar {
    display: flex;
    justify-content: center;
}

.heightBox{
    height: 30px;
}


@media screen and (max-width : 1024px){
    .body {
        height: 130vh;
        overflow: auto;
    }

    @media (orientation : landscape){
        .body {
            height: auto;
        }
    }
}





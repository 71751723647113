.headerDiv{
    width: 90.6%;
    margin: 0 auto;
}

.header{
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    padding-bottom: 1.1rem;
    padding-top: 1.1rem;
    /* border-bottom: 0.031rem solid #cfcfcf; */
}

.header.ios{
    padding-top: 0px;
}

.backBtnDiv{
    display: flex;
    align-items: center;
    height: 1.5rem;
    padding-top: 0.125rem;

    padding-top: 0.375rem;
    padding-bottom: 0.25rem;

    width: 23%;
}

.arrowLeft{
    width: 1rem;
    margin-right: 1.094rem;
}

.titleText{
    font-size: 1.063rem;
    letter-spacing: -0.053rem;
    text-align: left;
    color: #2a2a2a;
    font-family: "Apple_B";
}


.submit{
    font-family: "Apple_B";
    font-size: 0.95rem;
    color: black;
}

.submit.isOnEdit{
    color: rgba(0, 0, 0, 0.4);
}

.body{
    display: flex;
    justify-content: center;
    align-items: center;
}

.footer{
    z-index: 9999999999;
    position: fixed;
    bottom: 0;
    background-color: black;
    opacity: 0.8;
    padding-top: 1rem;
    padding-bottom: 1rem;
    width: 100%;
    display: grid;
    grid-template-columns: 1.2fr 1fr 1.2fr;
}

.footer.isOnEdit{
    grid-template-columns: 1fr 4fr 1fr;
}

.footer > div{
    display: flex;
    justify-content: center;
    align-items: center;
}

.pen{
    width: 1.5rem;
}

.mark{
    width: 1.2rem;
}

.mark2{
    width: 1.5rem;
}

.body{
    position: relative;
}

.canvasContainer{

}

.canvas1{
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    position: absolute;
}

.canvas2{
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    position: absolute;
    z-index: 999;
}

.elements{
    width: 100%;
    display: flex;
    justify-content: space-between;
}

.elementContainer{
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
}

.element{
    width: 2rem;
    height: 2rem;
    border-radius: 50%;
}

.element.active{
    border: 3px solid #FFC400;
}

@media only screen and (min-width: 500px) {
    .header{
        padding-top: 1.1rem !important; 
    }
  }
.main {
    background-color: #f5f5f5;
    width: calc(100% - 244px);
    height: calc(100vh - 78px);
    overflow: scroll;
    padding-top: 44px;
    padding-left: 53px;
    padding-bottom: 120px;
    font-family: "Apple_R";
}

.mainModal {
    background-color: #f5f5f5;
    padding-bottom: 24px;
    overflow: auto;
    height: 800px;
}

.mainModal::-webkit-scrollbar{
    background-color: #f5f5f5;
    width: 0px !important;

}

.mainModal::-webkit-scrollbar-thumb{
    background-color: #bbbcb6;
    border-radius: 10px;

}

.main::-webkit-scrollbar{
    background-color: #f5f5f5;
    width: 6px !important;

}

.main::-webkit-scrollbar-thumb{
    background-color: #bbbcb6;
    border-radius: 10px;

}

.title {
    display: flex;
    align-items: center;
    font-size: 22px;
    font-family: "Apple_B";
    color: #303030;
}

.title img {
    margin-right: 10px;
}

.selectStudentText {
    margin-top: 21px;
    font-family: "Apple_R";
    font-size: 21px;
    letter-spacing: -1.05px;
    padding-bottom: 15px;
    border-bottom: 1px solid #cbcbcb;
}

.autocompleteDiv {
    margin-top: 26px;
}

.selectMenuDiv {
    margin-top: 37px;
    display: flex;
    cursor: pointer;  
}

.selectMenu:hover{
    background-color: rgba(255,255,255,.8);
}

.selectMenu {
    width: 152px;
    height: 46px;
    border: 1px solid #c9c9c9;
    border-radius: 28px;
    margin-right: 14px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: "Apple_R";
    color: #566ee8;
    letter-spacing: -0.8px;
}

.manageDiv {
    margin-top: 36px;
}

.manageDivTitle {
    font-size: 21px;
    font-family: "Apple_R";
    letter-spacing: -1.05px;
    color: #303030;
}

.manageTable {
    margin-top: 15px;
}

.manageModalTable {
    margin-top: 15px;
    margin-left: 24px;
    margin-right: 24px;
}

.manageDivTitle {
    display: flex;
    justify-content: space-between;
    margin-right: 50px;
    width: 1150px;
}

.manageModalDivTitle {
    display: flex;
    justify-content: space-between;
    margin-right: 66px;
    margin-left: 60px;
    font-family: "Apple_R";
}

.modalFirstDiv{
    display: flex;
    justify-content: center;
}

.cancelBtn {
    cursor: pointer;
    display: flex;
    justify-content: end;   
}

.cancelBtn img {
    width: 32px;
    margin-right: 16px;
    margin-bottom: 16px;
}

.cancelBtn img:hover{
    background-color: rgb(200, 200, 200);
    border-radius: 50%;
}

.firstRow {
    display: flex;
}

.firstRow div {
    box-sizing: border-box;
    width: 230px;
    height: 52px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-top: 2px solid #707070;
    border-right: 1px solid #cbcbcb;
    background-color: rgb(235,235,235);
}

.firstRow div:last-child{
    border-right: 0;
}

.secondRow {
    display: flex;
}

.secondRow div div {
    background-color: white;
}

.secondRow_1{
    box-sizing: border-box;
    width: 95px;
    height: 190px;
    background-color: white;
    border-right: 1px solid #cbcbcb;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    border-bottom: 2px solid #707070;
}

.secondRow_2 div:first-child{
    height: 50px;
    width: 135px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-bottom: 1px solid #cbcbcb;
    border-right: 1px solid #cbcbcb;
}

.secondRow_2 div:nth-child(2){
    height: 140px;
    width: 135px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-bottom: 1px solid #cbcbcb;
    border-right: 1px solid #cbcbcb;
    border-bottom: 2px solid #707070;
}

.secondRow_3 div:first-child{
    height: 50px;
    width: 230px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-bottom: 1px solid #cbcbcb;
    border-right: 1px solid #cbcbcb;
}

.secondRow_3 div:nth-child(2){
    height: 140px;
    width: 230px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-bottom: 1px solid #cbcbcb;
    border-right: 1px solid #cbcbcb;
    border-bottom: 2px solid #707070;
}

.secondRow_4 div:first-child{
    height: 50px;
    width: 230px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-bottom: 1px solid #cbcbcb;
    border-right: 1px solid #cbcbcb;
}

.secondRow_4 div:nth-child(2){
    height: 140px;
    width: 230px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-bottom: 1px solid #cbcbcb;
    border-right: 1px solid #cbcbcb;
    border-bottom: 2px solid #707070;
}

.secondRow_5 div:first-child{
    height: 50px;
    width: 230px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-bottom: 1px solid #cbcbcb;
    border-right: 1px solid #cbcbcb;
}

.secondRow_5 div:nth-child(2){
    height: 140px;
    width: 230px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-bottom: 1px solid #cbcbcb;
    border-right: 1px solid #cbcbcb;
    border-bottom: 2px solid #707070;
}

.secondRow_6 div:first-child{
    border-right: 0;
    height: 50px;
    width: 230px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-bottom: 1px solid #cbcbcb;
}

.secondRow_6 div:nth-child(2){
    border-right: 0;
    height: 140px;
    width: 230px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-bottom: 1px solid #cbcbcb;
    border-bottom: 2px solid #707070;
}

.thirdRow {
    display: flex;
}

.thirdRow div div {
    background-color: white;
}

.thirdRow_1{
    box-sizing: border-box;
    width: 95px;
    height: 190px;
    background-color: white;
    border-right: 1px solid #cbcbcb;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    border-bottom: 2px solid #707070;
}

.thirdRow_2 div:first-child{
    height: 50px;
    width: 135px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-bottom: 1px solid #cbcbcb;
    border-right: 1px solid #cbcbcb;
}

.thirdRow_2 div:nth-child(2){
    height: 89px;
    width: 135px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-bottom: 1px solid #cbcbcb;
    border-right: 1px solid #cbcbcb;
}

.thirdRow_2 div:nth-child(3){
    height: 51px;
    width: 135px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-bottom: 1px solid #cbcbcb;
    border-right: 1px solid #cbcbcb;
    border-bottom: 2px solid #707070;
}

.thirdRow_3 div:first-child{
    height: 50px;
    width: 230px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-bottom: 1px solid #cbcbcb;
    border-right: 1px solid #cbcbcb;
}

.thirdRow_3 div:nth-child(2){
    height: 89px;
    width: 230px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-bottom: 1px solid #cbcbcb;
    border-right: 1px solid #cbcbcb;
}

.thirdRow_3 div:nth-child(3){
    height: 51px;
    width: 230px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-bottom: 1px solid #cbcbcb;
    border-right: 1px solid #cbcbcb;
    border-bottom: 2px solid #707070;
}

.thirdRow_4 div:first-child{
    height: 50px;
    width: 230px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-bottom: 1px solid #cbcbcb;
    border-right: 1px solid #cbcbcb;
}

.thirdRow_4 div:nth-child(2){
    height: 89px;
    width: 230px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-bottom: 1px solid #cbcbcb;
    border-right: 1px solid #cbcbcb;
}

.thirdRow_4 div:nth-child(3){
    height: 51px;
    width: 230px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-bottom: 1px solid #cbcbcb;
    border-right: 1px solid #cbcbcb;
    border-bottom: 2px solid #707070;
}

.thirdRow_5 div:first-child{
    height: 50px;
    width: 230px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-bottom: 1px solid #cbcbcb;
    border-right: 1px solid #cbcbcb;
}

.thirdRow_5 div:nth-child(2){
    height: 89px;
    width: 230px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-bottom: 1px solid #cbcbcb;
    border-right: 1px solid #cbcbcb;
}

.thirdRow_5 div:nth-child(3){
    height: 51px;
    width: 230px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-bottom: 1px solid #cbcbcb;
    border-right: 1px solid #cbcbcb;
    border-bottom: 2px solid #707070;
}

.thirdRow_6 div:first-child{
    height: 50px;
    width: 230px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-bottom: 1px solid #cbcbcb;
}

.thirdRow_6 div:nth-child(2){
    height: 89px;
    width: 230px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-bottom: 1px solid #cbcbcb;

}

.thirdRow_6 div:nth-child(3){
    height: 51px;
    width: 230px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-bottom: 1px solid #cbcbcb;
    border-bottom: 2px solid #707070;
}

.fourthRow {
    display: flex;
    
}

.fourthRow div div {
    background-color: white;
}

.fourthRow_1 div{
    width: 230px;
    border-right: 1px solid #cbcbcb;
    height: 210px;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    border-bottom: 2px solid #707070;

}

.fourthRow_2 div{
    width: 230px;
    border-right: 1px solid #cbcbcb;
    height: 210px;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    border-bottom: 2px solid #707070;

}

.fourthRow_3 div{
    width: 230px;
    border-right: 1px solid #cbcbcb;
    height: 210px;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    border-bottom: 2px solid #707070;

}

.fourthRow_4 div{
    width: 230px;
    border-right: 1px solid #cbcbcb;
    height: 210px;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    border-bottom: 2px solid #707070;

}

.fourthRow_5 div{
    width: 230px;
    height: 210px;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    border-bottom: 2px solid #707070;

}



.fifthRow {
    display: flex;
}

.fifthRow div div {
    background-color: white;
}

.fifthRow_1 div{
    width: 230px;
    border-right: 1px solid #cbcbcb;
    height: 120px;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
}

.fifthRow_2 div{
    width: 920px;
    height: 120px;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
}


.input {
      padding-left: 10px;
      padding-right: 10px;
      width: 100%;
      height: 100%;
      border: none;
      font-family: "Apple_R";
  }

  .input:focus {
    outline: none !important;
    border:1.5px solid blue;
  }

  .textarea {
    font-family: "Apple_R";
    padding-left: 10px;
    padding-right: 10px;
    width: 100%;
    height: 100%;
    border: none;
    padding-top: 10px;
    padding-bottom: 10px;
    resize: none;
  }

  .textarea:focus {
    outline: none !important;
    border:1.5px solid #3d50b0;
  }

  .printDiv {
      margin-top: 18px;
  }

  .answerBtnDiv {
      border: 1px solid black;
      width: 100px;
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;
      font-size: 12px;
      margin-top: 4px;
      margin-left: 20px;
  }

  .answerBtnDiv:hover{
      background-color: black;
      color: white;
  }

  .floatingButton{
    position: fixed;
    right: 3%;
    bottom: 3%;
  }




  @media print {
      .manageTable {
        border-left: 1px solid black;
        border-bottom: 1px solid black;
        border-right: 1px solid black;
      }
      
      
.firstRow div {
    border-right: 1px solid #707070;
    border-bottom: 2px solid #707070;
}

.secondRow_1{
    border-right: 1px solid #707070;
    border-bottom: 2px solid #707070;
}

.secondRow_2 div:first-child{
    border-bottom: 1px solid #707070;
    border-right: 1px solid #707070;
}

.secondRow_2 div:nth-child(2){
    border-bottom: 2px solid #707070;
    border-right: 1px solid #707070;
}

.secondRow_3 div:first-child{
    border-bottom: 1px solid #707070;
    border-right: 1px solid #707070;
}

.secondRow_3 div:nth-child(2){
    border-bottom: 2px solid #707070;
    border-right: 1px solid #707070;
}

.secondRow_4 div:first-child{
    border-bottom: 1px solid #707070;
    border-right: 1px solid #707070;
}

.secondRow_4 div:nth-child(2){
    border-bottom: 2px solid #707070;
    border-right: 1px solid #707070;
}

.secondRow_5 div:first-child{
    border-bottom: 1px solid #707070;
    border-right: 1px solid #707070;
}

.secondRow_5 div:nth-child(2){
    border-bottom: 2px solid #707070;
    border-right: 1px solid #707070;
}

.secondRow_6 div:first-child{
    border-bottom: 1px solid #707070;
}

.secondRow_6 div:nth-child(2){
    border-bottom: 2px solid #707070
}

.thirdRow_1{
    border-right: 1px solid #707070;
}

.thirdRow_2 div:first-child{
    border-bottom: 1px solid #707070;
    border-right: 1px solid #707070;
}

.thirdRow_2 div:nth-child(2){
    border-bottom: 1px solid #707070;
    border-right: 1px solid #707070;
}

.thirdRow_2 div:nth-child(3){
    border-bottom: 2px solid #707070;
    border-right: 1px solid #707070;
}

.thirdRow_3 div:first-child{
    border-bottom: 1px solid #707070;
    border-right: 1px solid #707070;
}

.thirdRow_3 div:nth-child(2){
    border-bottom: 1px solid #707070;
    border-right: 1px solid #707070;
}

.thirdRow_3 div:nth-child(3){
    border-bottom: 2px solid #707070;
    border-right: 1px solid #707070;
}

.thirdRow_4 div:first-child{
    border-bottom: 1px solid #707070;
    border-right: 1px solid #707070;
}

.thirdRow_4 div:nth-child(2){
    border-bottom: 1px solid #707070;
    border-right: 1px solid #707070;
}

.thirdRow_4 div:nth-child(3){
    border-bottom: 2px solid #707070;
    border-right: 1px solid #707070;
}

.thirdRow_5 div:first-child{
    border-bottom: 1px solid #707070;
    border-right: 1px solid #707070;
}

.thirdRow_5 div:nth-child(2){
    border-bottom: 1px solid #707070;
    border-right: 1px solid #707070;
}

.thirdRow_5 div:nth-child(3){
    border-bottom: 2px solid #707070;
    border-right: 1px solid #707070;
}

.thirdRow_6 div:first-child{
    border-bottom: 1px solid #707070;
}

.thirdRow_6 div:nth-child(2){
    border-bottom: 1px solid #707070;

}

.thirdRow_6 div:nth-child(3){
    border-bottom: 2px solid #707070;
}



.fourthRow_1 div{
    border-right: 1px solid #707070;
    border-bottom: 2px solid #707070;

}

.fourthRow_2 div{
    border-right: 1px solid #707070;
    border-bottom: 2px solid #707070;

}

.fourthRow_3 div{
    border-right: 1px solid #707070;
    border-bottom: 2px solid #707070;

}

.fourthRow_4 div{
    border-right: 1px solid #707070;
    border-bottom: 2px solid #707070;

}

.fourthRow_5 div {
    border-bottom: 2px solid #707070;
    border-right: 1px solid black;

}


  }


  /* CSS */
  .button14 {
    background-image: linear-gradient(#f7f8fa ,#e7e9ec);
    border-color: #adb1b8 #a2a6ac #8d9096;
    border-style: solid;
    border-width: 1px;
    border-radius: 3px;
    box-shadow: rgba(255,255,255,.6) 0 1px 0 inset;
    box-sizing: border-box;
    color: #0f1111;
    cursor: pointer;
    display: inline-block;
    font-family: "Amazon Ember",Arial,sans-serif;
    font-size: 14px;
    height: 29px;
    font-size: 13px;
    outline: 0;
    overflow: hidden;
    padding: 0 11px;
    text-align: center;
    text-decoration: none;
    text-overflow: ellipsis;
    user-select: none;
    -webkit-user-select: none;
    touch-action: manipulation;
    white-space: nowrap;
  }
  
  .button14:active {
    border-bottom-color: #a2a6ac;
  }
  
  .button14:active:hover {
    border-bottom-color: #a2a6ac;
  }
  
  .button14:hover {
    border-color: #a2a6ac #979aa1 #82858a;
  }
  
  .button14:focus {
    border-color: #e77600;
    box-shadow: rgba(228, 121, 17, .5) 0 0 3px 2px;
    outline: 0;
  }

  .sendTimeAlim{
    font-family: "Apple_SB";
    font-size: 15px;
    line-height: 1.35;
  }

  .printDiv {
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 1150px;
  }

  .print {
      width: 32px;
      cursor: pointer;
  }

.submit {
    margin-left: 16px;
    width: 181px;
    height: 46px;
    background-color: #3d50b0;
    color: white;
    border-radius: 28px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-left: 25px;
    padding-right: 20px;
    cursor: pointer;
}

.submit2{
    display: flex;
    align-items: center;
}

.disabledSubmit {
    margin-left: 16px;
    width: 181px;
    height: 46px;
    background-color: rgb(224, 224, 224);
    color: rgb(163, 163, 163);
    border-radius: 28px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-left: 25px;
    padding-right: 20px;
    cursor: not-allowed;
}

.linearProgress {
    /* margin-top: 24px; */
    width: 1150px;
}

.modalLinearProgress{
    width: 1150px;
    margin-left: 60px;
}

.manageTableModalDiv {
    display: flex;
}

.modalChevron {
    width: 38px;
    cursor: pointer;
}


.modalLastDiv{
    margin-top: 24px;
    display: flex;
    justify-content: flex-end;
    margin-right: 56px;
}

/* @media screen and (max-width : 1024px){
    .main {
        height: 130vh;
        overflow: auto;
    }
} */

@media screen and (max-width : 1024px){
    .main {
        height: 130vh;
        width: 1256px;
    }

    @media (orientation : landscape){
        .main {
            height: auto;
        }
    }
}



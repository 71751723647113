.header{
    padding-top: 1.5rem;
    width: 90.6%;
    margin: 0 auto;
    display: flex;
    align-items: center;
    padding-bottom: 1.5rem;
    border-bottom: 1px solid #eceff1;
}

.white{
    background-color: white;
}

.avatar{
    width: 4rem;
    height: 4rem;
    margin-right: 0.932rem;
}

.name{
    color: #263238;
    font-family: "Apple_B";
    font-size: 1.2rem;
    margin-bottom: 0rem;
}

.howMuchDay{
    color: #78909c;
    font-family: "Apple_SB";
    font-size: 0.8rem;
}

.bodyWrapper{
    background-color: white;
}

.body{
    width: 100%;
    margin: 0 auto;
}

.bodyTitle{
    width: 85.6%;
    margin: 0 auto;
    padding-top: 1.5rem;
    margin-bottom: 0.5rem;
    color: #78909c;
    font-family: "Apple_SB";
}

.eachMenuWrapper{
    position: relative;
    overflow: hidden;
}

.eachMenu{
    width: 85.6%;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-top: 0.85rem;
    padding-bottom: 0.85rem;
}

.eachMenu.parentNoSee{
    display: none;
}

.eachMenu.noSee{
    display: none;
}

.eachMenuTitleDiv{
    display: flex;
    align-items: center;
}

.titleDiv{
    font-family: "Apple_L";
    display: flex;
    align-items: center;
}

.svgDiv{
    margin-right: 1.2rem;
    display: flex;
    align-items: center;
}

.svg{
    width: 1.5rem;
}

.chevron{
    width: 0.6rem;
}

.authorWrapper{
    background-color: #f5f5f5;
}

.authorDiv{
    border-top: 1.5px solid #ECEFF1;
    padding-top: 1.5rem;
    padding-bottom: 1.5rem;
    width: 85.6%;
    margin: 0 auto;
}

.logo{
    width: 8rem;
    margin-bottom: 0.3rem;
}

.author{
    font-family: "Apple_R";
    color: #9e9e9e;
}

.ripple {
    content: "";
    width: 20%;
    height: 50%;
    position: absolute;
    background: rgba(0, 0, 0, 0.2);
    display: block;
    content: "";
    border-radius: 50%;
    opacity: 1;
    animation: ripple-effect 0.15s ease 1 forwards;
}

@keyframes ripple-effect {
    0% {
        transform: scale(1);
        opacity: 1;
    }

    /* 50% {
        transform: scale(10);
        opacity: 0.375;
    } */

    100% {
        transform: scale(10);
        opacity: 0;
    }
}
.menuSelector{
    padding-top: 1rem;
    padding-bottom: 1.5rem;
    width: 90.6%;
    margin: 0 auto;
}


.studyTimeSelectMenuWrapper{
    width: 100%;
    background-color: #e8e9eb;
    height: 3.062rem;
    aspect-ratio: 100 / 14.624;
    border-radius: 24.5px;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
}


.studyTimeSelectMenuText{
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: "Apple_M";
    font-size: 0.938rem;
    letter-spacing: -0.38px;
    color: #607d8b;
    z-index: 2;
}

.studyTimeSelectMenuText.active{
    color: #37474f;
}

.whiteWrapper{
    position: absolute;
    z-index: 1;
    box-shadow: 0 1.5px 3px 0 rgba(0, 0, 0, 0.16);
    background-color: #fff;
    border-radius: 19px;
    width: 32.386%;
    height: 2.375rem; 
    aspect-ratio: 100 / 35.024;
    top: 50%;
    left: 0;
    transition: all 200ms ease-out;
}

.border{
    width: 100%;
    border-top: 1px solid #eceff1;
}

.body{
    width: 90.6%;
    margin: 0 auto;
    padding-top: 0.5rem;
    padding-bottom: 3rem;
}

.eachStudent{
    display: flex;
    padding-top: 1.1rem;
    padding-bottom: 1.1rem;
    align-items: flex-end;
}

.rankingNumberDiv{
    width: 3rem;
    position: relative;
}

.crown{
    width: 1.5rem;
    position: absolute;
    top: 0;
    left: 50%;
    transform: translate(-50%, -84%);
    display: none;
}

.crown.active{
    display: block;
}

.rankingNumberCircle{
    width: 100%;
    border : 1.2px solid #3d50b0;
    border-radius: 50%;
    font-family: "Apple_B";
    font-size: 1.2rem;
    color: #3d50b0;
}

.rankingNumberCircle.mine{
    background-color: #3d50b0;
    color: white;
}

.rankingDescriptionDiv{
    flex: 1;
    padding-left: 1rem;
}

.rankingDescriptionNameDiv{
    display: flex;
    justify-content: space-between;
    padding-bottom: 0.7rem;
}

.studentName{
    font-family: "Apple_B";
}

.studyTime{
    font-family: "Apple_R";
    color: #3d50b0;
}

.studyTimeGraphBar{
    height: 0.3rem;
    border-radius: 0.3rem;
    overflow: hidden;
    width: 100%;
    background-color: #e8e9eb;
}

.studyTimeGraphBarContent{
    background-color: #3d50b0;
    height: 0.3rem;
}

.blankText{
    padding-top: 1rem;
    font-family: "Apple_SB";
}
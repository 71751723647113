.main {
    background-color: #f5f5f5;
    background-color: #fff;
    width: calc(100% - 244px);
    border-left: 1px solid #d5dadb;
    /* padding-left: 46px;
    padding-top: 45.7px;
    padding-right: 46px;
    padding-bottom: 71px; */
    height: calc(100vh - 78px);
    overflow: hidden;
    position: relative;
}

.main::-webkit-scrollbar{
    background-color: #f5f5f5;
    width: 6px !important;
}

.main::-webkit-scrollbar-thumb{
    background-color: #bbbcb6;
    border-radius: 10px;
}

.chatMain{
    width: 100%;
    height: calc(100vh - 182px);
    height: 100%;
    padding-bottom: 32px;
    overflow: hidden;
}

/* .chatMain::-webkit-scrollbar{
    background-color: #f5f5f5;
    width: 6px !important;
}

.chatMain::-webkit-scrollbar-thumb{
    background-color: #bbbcb6;
    border-radius: 10px;

    background-color: #969696;
    border-radius: 12px;
    width: 7px;
    background-clip: content-box;
} */

.title {
    display: flex;
    align-items: center;
    font-size: 22px;
    font-family: "Apple_B";
    color: #303030;
    letter-spacing: -1.1px;
}

.title img {
    margin-right: 10.3px;
}


.searchMenu {
    margin-top: 23px;
    display: flex;
    margin-bottom: 33px;   
}

.searchMenuDiv {
    font-family: "Apple_R";
    width: 177px;
    height: 51px;
    border-radius: 28px;
    border: 1px solid #cbcbcb;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 14px;
    cursor: pointer;
}

.searchMenuDiv.active{
    background-color: #3d50b0;
    border: none;
    color: white;
}

.textfieldDiv {
    margin-top: 20px;
}

.buttonDiv {
    margin-top: 16px;
    display: flex;
    justify-content: end;
}

.buttonText {
    font-family: "Apple_R";
}

.spanStyles2 {
    font-family: "Apple_R";
}

.messageTitle {
    font-family: "Apple_B";
    font-size: 16px;
    margin-bottom: 24px;
}

.nameText {
    font-family: "Apple_R";
    font-size: 15px;
}

/* @media screen and (max-width : 1024px){
    .main {
        height: 130vh;
        overflow: auto;
    }
} */


.chatFooter{
    position: absolute;
    bottom: 0;
    z-index: 15;
    min-height: 104px;
    width: 100%;
    border-top: 1px solid #d5dadb;
    background-color: white;
}

.inputDiv{
    border: 1px solid #ddd;
    margin: 10px 8px 8px 10px;
    box-shadow: 0 3px 6px rgba(0,0,0,.08);
    border-radius: 8px;
    width: calc(100% - 20px);
    float: left;
    min-height: 85px;
    position: relative;
}

.inputDiv.focus{
    border: 1.5px solid #3d50b0;
    z-index: 3;
}

.messageDiv{
    min-height: 45px;
    max-height: 310px;
}

.messageDiv::-webkit-scrollbar{
    background-color: #f5f5f5;
    width: 0px !important;
}

.messageDiv::-webkit-scrollbar-thumb{
    background-color: #bbbcb6;
    border-radius: 10px;
}

.textarea::-webkit-scrollbar{
    background-color: #f5f5f5;
    width: 0px !important;
}

.textarea::-webkit-scrollbar-thumb{
    background-color: #bbbcb6;
    border-radius: 10px;
}

.textarea {
    font-size: 16px;
    font-family: "Apple_R";
    padding-left: 10px;
    padding-right: 10px;
    width: 100%;
    border: none;
    padding-top: 10px;
    padding-bottom: 10px;
    resize: none;
    background-color: transparent;
    color: #3d3c40;
}

.textarea::placeholder{
    color: #cacaca;
    font-size: 13px;
    font-family: "Apple_R";
}

.textarea:focus {
    outline: none !important;
  }

  .sendBtn{
      background-color: #f2f4f8;
      background-color: transparent;
      color: #c2c2c2;
      cursor: not-allowed;
      width: 57px;
      width: 26px;
      height: 32px;
      border-radius: 30px;
      font-size: 15px;
      display: flex;
      justify-content: center;
      align-items: center;
      font-family: "Apple_R";
  }

  .sendBtn.active{
      /* background-color: #3d50b0;
      color: white; */
      cursor: pointer;
  }

  .sendBtn.active:hover{
      /* background-color: rgb(78, 97, 194); */
  }

  .messageBtns{
      padding: 6px 12px;
      display: flex;
      justify-content: space-between;
      align-items: center;
  }

  .emoticons{
      display: flex;
  }

  .smileFace{
      width: 17px;
      margin-right: 12px;
      cursor: pointer;
  }

  .clipImg{
      width: 17px;
      cursor: pointer;
      margin-right: 12px;

  }

  .linkImg{
    width: 17px;
    cursor: pointer;
  }

  .emojiBox{
    position: absolute;
    top: -325px;
    width: 370px;
    border: 1px solid rgba(0, 0, 0, 0.15);
    border-radius: 4px;
    box-shadow: 0 6px 12px rgba(0,0,0,.175);
    height: 312px;
  }


  













@media screen and (max-width : 1024px){
    .main {
        height: 130vh;
        width: 1256px;
    }




    @media (orientation : portrait) {
        .message{
            bottom : 44%;
        }
    }

    @media (orientation : landscape){
        .main {
            height: auto;
        }
    }
}


.modalTitle{
    padding: 16px 24px;
    font-family: "Apple_EB";
    color: #333;
    font-size: 20px;
    line-height: 22px;
    height: 54px;
}

.modalBody{
    padding: 16px 24px;
}

.messageImg{
    width: 459px;
    height: 229px;
    object-fit: contain;
}

.modalFooter{
    padding: 15px 20px 20px;
    display: flex;
    justify-content: flex-end;
}

.cancelBtn{
    cursor: pointer;
    color: #bcbcbc;
    border-color: rgba(0, 0, 0, 0.12);
    background-color: rgba(0, 0, 0, 0.03);
    padding: 8px 12px;
    font-family: "Apple_R";
    margin-right: 4px;
}

.uploadBtn{
    font-family: "Apple_R";
    color: white;
    cursor: pointer;
    background-color: #3d50b0;
    padding: 8px 12px;
    border-radius: 4px;
}

.imgLoadingDiv{
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.eachMessage{
    margin: 12px 0 0;
    padding: 0 0 0 56px;
}

.profileAnchor{
    position: relative;
}

.profileContainer{
    width: 32px;
    height: 32px;
    margin-left: -40px;
    border-radius: 50%;
    background-color: white;
    border: 1px solid #e1e1e1;
    position: relative;
    float: left;
}

.profileContainer.ofStudent{
    background-color: rgba(218,218,218);
}

.shapeLogo{
    width: 75%;
    height: 75%;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    overflow: hidden;
    object-fit: cover;
}

.avatar{
    width: 60%;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    overflow: hidden;
    object-fit: cover;
}

.userName{
    vertical-align: top;
    font-size: 15px;
    font-family: "Apple_B";
    line-height: 32px;
    display: inline-block;
    padding-top: 0px;
}

.msg_container{

}

.msg_item{
    border-radius: 8px;
    background-color: #EBF5F1;
    padding: 6px 12px;
    margin-top: 2px;
    max-width: 440px;
    background-clip: border-box;
    color: #333;
    vertical-align: bottom;
    word-break: keep-all;
    word-wrap: break-word;
    display: inline-block;
}

.msg_item.ofStudent{
    background-color: #f3f3f3;
}

.msg_text_box{
    white-space: pre-wrap;
    word-break: normal;
    line-height: 23px;
    color: #505050;
    font-size: 15px;
}

.msg_img{
    max-width: 416px;
    max-height: 500px;
}

.msg_extra{
    padding-left: 6px;
    font-size: 11px;
    display: inline-flex;
    flex-flow: wrap-reverse;
    margin-top: 4px;
}

.msg_extra_action{
    display: block;
    color: #797979;
}

.divider{
    margin-top: 20px;
    padding: 8px 12px;
}

.chatHeader{
    border-bottom: 1px solid #e5e5e5;
    position: fixed;
    width: 100%;
    height: 54px;
    z-index: 5;
    background-color: white;

    display: flex;
    align-items: center;
    padding-left: 56px;
}

.chatBody{
    margin-top: 54px;
    margin-bottom: 104px;
    height: calc(100% - 140px);
    overflow: auto;
    position: relative;
}



.userName.chatHeaderName{
    color: #505050;
    font-family: "Apple_B";
    font-size: 17px;
}

.loadingDiv{
    margin-top: 12px;
    display: flex;
    justify-content: center;
}

.chatBody::-webkit-scrollbar{
    background-color: #f5f5f5;
    width: 6px !important;
}

.chatBody::-webkit-scrollbar-thumb{
    background-color: #bbbcb6;
    border-radius: 10px;

    background-color: #969696;
    border-radius: 12px;
    width: 7px;
    background-clip: content-box;
}

.divider.dividerNotShown{
 display: none;
}

.unReadNumber{
    color: #3d50b0;
}

.link{
    color: #0000ee;
}

.link:visited{
    color: #551a8b;
}
.main {
    min-width : 1500px;
    margin: auto;
}

.appbar {
    display: flex;
    justify-content: space-between;
    padding-left: 99.8px;
    padding-right: 93px;
    padding-top: 27px;
    padding-bottom: 19.5px;
    border-bottom: 1px solid #dedede;
}

.logo1 {
    width: 143.7px;
}

.login {
        height: 100%;
        display: flex;
        align-items: center;
}

.avatar {
    margin-right: 7.8px;
    width: 14.1px;
}

.loginText {
    font-family: "Apple_R";
    font-size: 15px;
    letter-spacing: -0.75px;
    color: #303030;
    display: inline-block;
    vertical-align: top;
}

.body {
    width: 439px;
    margin: 0 auto;
}

.signUpText {
    margin-top: 70.5px;
    font-family: "Apple_R";
    letter-spacing: -1.5px;
    font-size: 30px;
    color: #303030;
    margin-bottom: 20px;
}

.input {
    color: red;
    width: 80%;
    margin-top: 20px;
}

.label {
    margin-bottom: 9px;
    font-size: 18px;
    color: #606060;
    letter-spacing: -0.45px;
    font-family: "Apple_R";
}

.phone {
    display: flex;
    width: 100%;
}

.phoneCert {
    width: 148px;
    margin-left: 13.8px;
    font-family: "Apple_R";
    font-size: 18px;
    background-color: #121a50;
    color: white;
    border-radius: 28px;
    cursor: pointer;
}

.submit {
    margin-top: 30px;
    width: 440px;
    height: 62px;
    border-radius: 31px;
    color: white;
    font-family: "Apple_R";
    font-size: 20px;
    letter-spacing: -1px;
    background-color: #3d50b0;
    border: none;
    margin-bottom: 35.5px;
    cursor: pointer;
}

.radioLabel {
    font-size: 18px;
    color: #606060;
    letter-spacing: -0.45px;
    font-family: "Apple_R";
}

.lastText {
    text-align: center;
    padding-top: 29.7px;
    border-top: 1px solid #c1c1c1;
    font-size: 19px;
    color: #2a2a2a;
    letter-spacing: 0.19px;
    font-family: "Apple_R";
    margin-bottom: 20px;
}

@media screen and (max-width : 1024px){
    .main {
        min-width: 0;
    }

    .appbar {
        display: none;
    }

    .body {
        width: auto;
        padding-left: 8%;
        padding-right: 8%;
    }

    .signUpText {
        margin-top: 130px;
        font-family: "Apple_B";
        font-size: 4.2rem;
    }

    .label {
        margin-top: 32px;
        font-family: "Apple_B";
        font-size: 2.2rem;
        margin-bottom: 1.5rem;
    }

    .submit {
        margin-top: 90px;
        width: 100%;
        height: 7.5rem;
        font-size: 2.2rem;
        border-radius: 3.5rem;
        font-family: "Apple_B";
    }

    .phoneCert {
        width: 240px;
        font-size: 2.2rem;
        flex-grow: 1;
        border-radius: 3.5rem;
        height : 7rem
    }

    .radioLabel {
        font-size: 2.2rem;
    }

    .lastText {
        font-family: "Apple_B";
        border: 0;
        margin-top: 40px;
        margin-bottom: 160px;
        font-size: 2.2rem;
    }
}
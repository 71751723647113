
.headerDiv{
    width: 90.6%;
    margin: 0 auto;
}

.header{
    display: flex;
    justify-content: space-between;
    align-items: center;
    /* padding-left: 1.219rem;
    padding-right: 1.219rem; */
    width: 100%;
    padding-bottom: 1.1rem;
    padding-top: 1.1rem;
    /* border-bottom: 0.031rem solid #cfcfcf; */
}

.header.ios{
    padding-top: 0px;
}

.backBtnDiv{
    display: flex;
    align-items: center;
    height: 1.5rem;
    padding-top: 0.125rem;

    padding-top: 0.375rem;
    padding-bottom: 0.25rem;

    width: 23%;
}

.arrowLeft{
    width: 1rem;
    margin-right: 1.094rem;
}

.titleText{
    font-size: 1.063rem;
    letter-spacing: -0.053rem;
    text-align: left;
    color: #2a2a2a;
    font-family: "Apple_B";
}

.pentosqure{
    width: 1.4rem;
}

.submit{
    width: 23%;
    text-align: end;
    font-family: "Apple_B";
    font-size: 0.95rem;
    color: black;
}

.bodyDiv{
    width: 90.6%;
    margin: 0 auto;
}

.body{
    padding-top: 1rem;
    /* padding-left: 1.219rem;
    padding-right: 1.219rem; */
}

.box{
    margin-bottom: 1.4rem;
}

.avatarDiv{
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.innerAvatarDiv{
    display: flex;
    align-items: center;
}

.bodyTitle{
    color: #8a8c8f;
    font-family: "Apple_B";
    margin-bottom: 1.6rem;
}

.name{
    font-family: "Apple_B";
    font-size: 0.95rem;
}

.descriptionBox{
    margin-left: 1rem;
}

.descriptionKind{
    font-family: "Apple_B";
    font-size: 1rem
}

.descriptionEtc{
    font-family: "Apple_B";
    color: #98989c;
    font-size: 0.8rem;
}

.chevronRight{
    width: 0.6rem;
}

.imageNumber{
    font-family: "Apple_EB";
    color: #3d50b0;
    font-weight: 900;
}

.kindTitle{
    padding-top: 0.5rem;
    font-family: "Apple_B";
    font-size: 1.6rem;
}

.overString{
    font-size: 0.95rem;
    margin-top: 0.5rem;
    font-family: "Apple_M";
    color: #6E6F72;
}

.avatarDiv2{
    margin-top: 2rem;
    display: flex;
    align-items: center;
}

.userNameDiv{
    margin-left: 1rem;
    font-family: "Apple_R";
}

.descriptionWrapper{
    margin-top: 1rem;
    font-family: "Apple_R";
}

.avatarDiv3{
    display: none;
}

.avataDiv3.active{
    display: flex;
}

@media only screen and (min-width : 500px){
    .header{
        padding-top: 1.1rem !important;
    }
}

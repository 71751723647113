.body{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.checkDiv{
    display: flex;
    justify-content: center;
    height: 3.5rem;
}

.circleCheck{
    width: 3.6rem;
}

.title{
    margin-top: 1rem;
    margin-bottom: 1rem;
    font-family: "Apple_B";
    font-size: 1.2rem;
    white-space: pre-wrap;
}

.textDiv > div{
    text-align: center;
}
.selectMenu{
    margin-top: 2.5rem;
    display: flex;
}

.btnLine{
    display: flex;
    justify-content: space-between;
}

.btns{

}

.main{
    width: 1500px;
}

.modalHeader{
    width: 100%;
    background-color: #3d50b0;
    color: white;
    height: 3.75rem;
    display: flex;
    align-items: center;
    padding-left: 1.5rem;
}

.modalBody{
    padding-left: 1.5rem;
    padding-right: 1.5rem;
}

.title {
    color: #000;
    font-family: "Apple_SB";
    font-size: 1rem;
    font-weight: 600;
    line-height: normal;
    margin-top: 1.2rem;
    margin-bottom: 0.5rem;
}

.horizontalRow{
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.subMessage {
    color: #7B7B7B;
    font-family: "Apple_R";
    font-size: 1rem;
    font-style: normal;
    line-height: normal;
}

.justHeight{
    height: 9.38rem;
}

.justHeight2{
    height: 3.38rem;
}

.modalBottom{
    height: 4.6rem;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    border-top: 1.5px #dfdfdf solid;
    padding-right: 2.31rem;
    padding-left: 2.31rem;
}

.radioText{
    font-family: "Apple_R";
}

.imageUploadDiv{
    display: flex;
    align-items: center;
    font-family: "Apple_L";
    cursor: pointer;
}

.imageUploadDiv div{
    display: flex;
    align-items: center;
}

.imageUploadDiv span{
    color: #999;
}

.clickPlus{
    display: flex;
    align-items: center;
}

.plusIcon{
    margin-right: 0.3rem;
    display: flex;
    align-items: center;
}

.plusIconText {
    color: #878787;
    font-size: 1rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    display: flex;
    align-items: center;
}

.optionItem{
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 8px;
}

.optionSelect{
    display: flex;
    align-items: center;
}

.deleteDiv{
    padding: 0.2rem;
    background-color: gray;
    border-radius: 6px;
    margin-left: 0.4rem;
    background-color: #F3F2F2;
    cursor: pointer;
}

.deleteDiv:hover{
    background-color: #E5E5E5;
}

.clickPlus{
    display: flex;
    align-items: center;
    cursor: pointer;
}

.modalBody{
    max-height: 70vh;
    overflow-y: auto;
}

.modalBody::-webkit-scrollbar{
    background-color: #f5f5f5;
    width: 4px !important;
}

.modalBody::-webkit-scrollbar-track{
    background-color: transparent;
}

.modalBody::-webkit-scrollbar-thumb{
    background-color: #bbbcb6;
    border-radius: 10px;
    scrollbar-width: thin !important;
}
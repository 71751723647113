.main {
    background-color: #f5f5f5;
    width: calc(100% - 244px);
    height: calc(100vh - 78px);
    padding-left: 46px;
    padding-top: 48px;
    padding-bottom: 48px;
    padding-right: 46px;
}

.mainTitle {
    font-size: 20px;
    font-family: "Apple_R";
    margin-bottom: 26px;
}

@media screen and (max-width : 1024px){
    /* .main {
        height: 130vh;
        overflow: auto;
    } */
}

@media screen and (max-width : 1024px){
    .main {
        height: 130vh;
        width: 1256px;
    }

    @media (orientation : landscape){
        .main {
            height: auto;
            padding-bottom: 40px;
        }
    }
}
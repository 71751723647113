.dateLine {
    margin-bottom: 8px;
    font-family: "Apple_B";
}

.dateChange {
    display: flex;
    justify-content: end;
    margin-bottom: 16px;
}

.firstDiv{
    display: flex;
    justify-content: space-between;
}

.imageBox {
    margin-top: 48px;
}

.eachImage {
    margin-bottom: 24px;
}

.image {
    margin-right: 24px;
}

.listBox{
    display: flex;
    flex-direction: row-reverse;
}

.imageBox{
    display: flex;
    flex-wrap: wrap;
    flex-grow: 1;
    height: 1000px;
    overflow: auto;
    margin-right: 8px;
    padding-bottom: 450px;
}

.imageBox.second {
    height: auto;
    margin-top: 24px;
    padding-bottom: 0px;
}

.date {
    font-family: "Apple_R";
    padding-bottom: 6px;
    border-bottom: 2px solid black;
    font-weight: 600;
}

.date.second {
    border-bottom: 0;
}

.imageBox::-webkit-scrollbar{
    background-color: #f5f5f5;
    width: 6px !important;
}

.imageBox::-webkit-scrollbar-thumb{
    background-color: #bbbcb6;
    border-radius: 10px;
}

.titleImageBox {
    height: 1000px;
    overflow: auto;
    margin-right: 8px;
    padding-bottom: 450px;
    flex-grow: 1;
}

.titleImageBox::-webkit-scrollbar{
    background-color: #f5f5f5;
    width: 6px !important;
}

.titleImageBox::-webkit-scrollbar-thumb{
    background-color: #bbbcb6;
    border-radius: 10px;
}

.list {
    margin-top: 25px;
}

.eachList {
    font-family: "Apple_R";
    padding-left: 24px;
    width: 460px;
    overflow: auto;
    padding-top: 6px;
    padding-bottom: 6px;
    margin-bottom: 6px;
    background-color: #c6c6c6;
    cursor: pointer;
    display: flex;
    justify-content: space-between;
    padding-right: 12px;
}

.eachListDiv{
    width: 300px;
}

.eachList.active{
    background-color: #3d50b0;
    color : white;
}

.title {
    font-family: "Apple_B";
    overflow: hidden;
}

.count {
    display: inline-block;
    margin-left: 24px;
    background-color: white;
    width: 55px;
    height: 24px;
    border-radius: 13px;
    color: black;
    text-align: center;
    line-height: 26px;
    font-size: 16px;
}

.count.active {
    background-color: blue;
    color: white;
}

.image.active{
    border: 4px solid blue;
}

.listTime {
    font-family: "Apple_B";
    font-size: 18px;
    margin-bottom: 8px;
    margin-top: 16px;
}

.eachTitle {
    font-family: "Apple_EB";
    font-size: 24px;
    margin-top: 24px;
}
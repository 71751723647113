.bodyDiv{
    width: 90.4%;
    margin: 0 auto;
}

.body{
    padding-top: 0.5rem;
    width: 100%;
}

.body.ios{
    padding-top: 0;
}

.title{
    margin-top: 1rem;
    font-family: "Apple_B";
    font-size: 1.3rem;
    color: #263238;
    padding-bottom: 0.5rem;
}

.main{
    overflow: scroll;
}

.eachNotification{
    padding-top: 1.4rem;
    padding-bottom: 1.4rem;
    width: 90.4%;
    margin: 0 auto;
}

.eachNotificationDetail{
    padding-bottom: 1.4rem;
    width: 90.4%;
    margin: 0 auto;
}

.eachNotificationKindDivWrapper{
    display: flex;
    margin-bottom: 1rem;
}

.eachNotificationKindDiv{
    padding: 0.3rem 0.4rem 0.3rem 0.4rem;
    border-radius: 0.3rem;
    display: flex;
    align-items: center;
    border: 1px solid #e0e0e0;
}

.eachNotificationKindAll{
    background-color: #bdbdbd;
    color: white;
    font-family: "Apple_B";
    font-size: 0.7rem;
    padding: 0.06rem 0.25rem 0.06rem 0.25rem;
    border-radius: 0.15rem;
    margin-right: 0.5rem;
}

.eachNotificationKindText{
    font-size: 0.8rem;
    font-family: "Apple_R";
    color: #757575;
}

.profileContainer {
    width: 2.5rem;
    height: 2.5rem;
    border-radius: 50%;
    background-color: white;
    border: 1px solid #e1e1e1;
    position: relative;
    margin-right: 0.5rem;
}

.profileContainer.ofStudent {
    background-color: rgba(218, 218, 218);
    background-color: #b0dbf1;
}

.avatarDiv{
    display: flex;
    align-items: center;
    margin-bottom: 1.2rem;
}

.avatar {
    width: 60%;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    overflow: hidden;
    object-fit: cover;
}

.avatarName{
    font-family: "Apple_B";
    font-size: 0.90rem;
}

.uploadDate{
    font-size: 0.72rem;
    font-family: "Apple_R";
}

.notificationTitle{
    font-family: "Apple_R";
    margin-bottom: 1rem;
}

.notificationDetailTitle{
    font-family: "Apple_SB";
    margin-bottom: 0.5rem;
}

.notificationDetailDescription{
    font-family: "Apple_R";
    margin-bottom: 1rem;
    white-space: pre-wrap;
    word-break: break-all;
}

.border{
    background-color: #f5f5f5;
    height: 8px;
    width: 100%;
}

.notificationImgDiv{
    /* margin-bottom: 1rem; */
}

.notificationImg{
    border-radius: 0.25rem;
    width: 6rem;
    height: 6rem;
    object-fit: contain;
    background-color: black;
}



.notificationViews{
    display: flex;
}

.notificationViewBox{
    border-radius: 0.3rem;
    padding: 0.2rem 0.6rem 0.2rem 0.6rem;
    background-color: #f1f1f1;
    font-family: "Apple_SB";
}

.avatarName{
    display: flex;
    align-items: center;
}

.new{
    margin-left: 0.25rem;
    display: block;
    text-align: center;
    line-height: 1rem;
    width: 1rem;
    height: 1rem;
    border-radius: 50%;
    background-color: red;
    color: white;
    font-size: 0.6rem;
}

.numberDiv{
    color: #757575;
    margin-top: 0.6rem;
    font-family: "Apple_R";
    font-size: 0.8rem;
}

.shapeLogo{
    width: 1.2rem;
}
.container{
    margin-top: 60px;
}

.noMargin{
    margin-top: 0;
}

.table{
    border-collapse: collapse;
}

.table th,
.table td {
    border: 1px solid rgba(0, 0, 0, 0.3);
    padding: 8px;
}

.table th:first-child,
.table td:first-child {
  border-left: none;
}

/* 마지막 열의 오른쪽 테두리 제거 */
.table th:last-child,
.table td:last-child {
  border-right: none;
}

.oneRow:hover{
    /* 
    마우스 갖다대면 배경색 변경
    */
    transition: background-color 0.3s;
    background-color: rgba(0, 0, 0, 0.1);
}

.container{
    max-height: 800px;
    overflow-y: auto;
}

.table thead{
    /* 또는 thead th { position: sticky; } 로도 가능 */
    position: sticky;
    top: 0;
    background: #fff;  /* 스크롤 시 헤더가 뒤 레이어에 묻히지 않도록 */
    z-index: 10;       /* 본문 셀보다 헤더가 위에 표시되도록 */
  }

.header{
    display: flex;
    align-items: center;
    padding-left: 1.219rem;
    padding-right: 1.219rem;
    padding-bottom: 1.1rem;
    padding-top: 1.1rem;
    border-bottom: 0.031rem solid #cfcfcf;
}

.header.ios{
    padding-top: 0px !important;
}

.header{
    position: fixed;
    width: 100%;
}


.backBtnDiv{
    display: flex;
    align-items: center;
    height: 1.5rem;
    padding-top: 0.125rem;
}

.arrowLeft{
    width: 1rem;
    margin-right: 1.094rem;
}

.titleText{
    font-size: 1.063rem;
    letter-spacing: -0.053rem;
    text-align: left;
    color: #2a2a2a;
    font-family: "Apple_B";
}


.mobileTitleList {
    display: flex;
    overflow: auto;
    border-bottom: 0.5px solid #e7e7e7;
    margin-bottom: 1.875rem;
    padding-left: 1.125rem;
    padding-top: 1.601rem;
    padding-top: 4.263rem;
}

.mobileTitleList::-webkit-scrollbar{
    background-color: #f5f5f5;
    width: 0px !important;
    height: 0px !important;
}

.mobileTitleList::-webkit-scrollbar-thumb{
    background-color: #bbbcb6;
    border-radius: 0px;
}

.mobileEachListTitle{
    flex-shrink: 0;
    font-size: 0.906rem;
    letter-spacing: -0.36px;
    padding-left: 0.25rem;
    padding-right: 0.25rem;
    padding-bottom: 0.884rem;
    margin-right: 1.594rem;
    font-family: "Apple_R";
    line-break: strict !important;
    color: #a4a4a4;
}

.mobileEachListTitle.active{
    color: #3d55dc;
    border-bottom: 0.125rem solid #3d55dc;
}

.choiceName{
    padding-left: 1.125rem;
    display: flex;
}

.isName{
    width: 4.063rem;
    height: 2rem;
    background-color: #acacac;
    opacity: 0.8;
    border-radius: 1rem;
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: "Apple_R";
    font-size: 0.812rem;
    letter-spacing: -0.65px;
}

.isName.active{
    background-color: #343953;
    opacity: 0.85;
}

.isName.first{
    margin-right: 0.469rem;
}

.opinionDescriptionDiv{
    margin-top: 1.094rem;
    padding-left: 1.125rem;
    font-family: "Apple_L";
    color: #575757;
    font-size: 0.781rem;
    line-height: 1.6;
    letter-spacing: -0.31px;  
}

.opinionWriteDiv{
    margin-top: 1.76rem;
    background-color: #f6f6f6;
    padding-top: 1.125rem;
    padding-bottom: 1.125rem;
}

.opinionWriteWrapper{
    width: 90.4%;
    margin: 0 auto;
    background-color: white;
    padding-top: 1.375rem;
    padding-bottom: 1.375rem;
}

.opinionWriteWrapperWrapper{
    width: 91.740%;
    margin: 0 auto;
}

.opinionQuestionForm{

}

.opinionWriteTitle{
    font-family: "Apple_R";
    font-size: 1.063rem;
    line-height: 1.29;
    letter-spacing: -0.43px;
    color: #2a2a2a;
    margin-bottom: 1.1rem;
}

.opinionQuestionTitle{
    font-family: "Apple_M";
    font-size: 0.844rem;
    color: #251e1c;
    letter-spacing: -0.68px;
    margin-bottom: 0.566rem;
}

.opinionQuestion2 .opinionQuestionTitle{
    margin-top: 1.225rem;
}

.textarea2{
    width: 100%;
    border: 0;
    text-decoration: none;
    outline: none;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    box-shadow: none;
    -webkit-appearance: none;
    border-radius: 5px !important;
    resize: none; /*remove the resize handle on the bottom right*/
    border: 0.5px solid #b7b7b7;
    padding: 0.938rem 1.156rem 0.937rem 1.156rem;
    font-family: "Apple_R";
    color: #251e1c;
    letter-spacing: -0.65px;
    font-size: 0.813rem;
}

.opinionQuestion2{
    margin-top: 0.5rem;
}

.imageSvgWrapper{
    width: 2rem;
    position: relative;
}

.badge{
    position: absolute;
    top: -0.5rem;
    right: -0.5rem;
    width: 1.5rem;
    height: 1.5rem;
    border-radius: 50%;
    background-color: red;
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: "Apple_B";
    font-size: 0.7rem;
}

.imageSvg{
    width: 100%;
}






.checkBoard {
    margin-top: 33px;
    background-color: white;
    width: 1164px;
    padding-bottom: 60px;
    margin-bottom: 60px;
    padding-top: 53px;
    padding-left: 66px;
    padding-right: 50px;
    border-radius: 12px;
    box-shadow: rgb(20 20 20 / 2%) 2px 8px 12px 0px, rgb(20 20 20 / 2%) 0px 1px 3px 0px;

}

.title {
    display: flex;
    align-items: center;
}

.title img{
    margin-right: 8px;
}

.title span {
    font-family: "Apple_R";
    font-size: 18px;
    color: #303030;
}

.dayLine {
    margin-top: 16px;
    display: flex;
}

.dayFirst1 {
    width: 40px;
    border-top: 3px solid #f2f2f2;
    height: 44px;
    border-bottom: 3px solid #f2f2f2;
    border-left: 3px solid #f2f2f2;
}

.dayFirst2 {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 40px;
    height: 250px;
    border-bottom: 3px solid #f2f2f2;
    border-left: 3px solid #f2f2f2;
}

.dayFirst3 {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 40px;
    height: 165px;
    border-bottom: 3px solid #f2f2f2;
    border-left: 3px solid #f2f2f2;
}

.dayDiv {

    width: 144px;
    height: 44px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #e4e8ff;
    border-left: 3px solid #f2f2f2;
    border-top: 3px solid #f2f2f2;
    border-bottom: 3px solid #f2f2f2;
}

.dayLine .dayDiv:last-child{
    border-right: 3px solid #f2f2f2;
}

.dateLine{
    display: flex;
}

.dateDiv{
    width: 144px;
    height: 250px;
    border-left: 3px solid #f2f2f2;
    border-bottom: 3px solid #f2f2f2;
    padding-top: 11px;
    padding-left: 17px;
    padding-bottom: 11px;
    padding-right: 17px;
    overflow: auto;
}

.dateDiv::-webkit-scrollbar{
    background-color: #f5f5f5;
    width: 2px !important;
}

.dateDiv::-webkit-scrollbar-thumb{
    background-color: #bbbcb6;
    border-radius: 10px;
}

.dateDivDiv{
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.dateLine .dateDiv:last-child{
    border-right: 3px solid #f2f2f2;
}

.menuListLunch div{
    font-family: "Apple_R";
    margin-bottom: 8px;
    line-height: 22px;
}

.menuListLunch div:first-child{
    font-family: "Apple_B";
}

.menuListDinner div{
    font-family: "Apple_R";
    margin-bottom: 8px;
}

.cancelBtn {
    cursor: pointer;
    font-family: "Apple_B";
    color: #3d50b0;
    display: flex;
    justify-content: center;
    border: 1px solid #3d50b0;
    border-radius: 6px;
    align-items: center;
    height: 24px;
    font-size: 14px;
}

.menuListDinner div:first-child{
    font-family: "Apple_B";
    line-height: 22px;
}

.linearProgress {
    margin-top: 20px;
}

.eachEnroll {
    margin-top: 5px;
    margin-bottom: 10px;
    font-family: "Apple_R";
}

.loading {
    width: 100%;
    height: 300px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.teacherDiv {
    margin-top: 25px;
}


.totalCheckBtn{
    margin-top: 24px;
    cursor: pointer;
    margin-right: 12px;
    border-radius: 4px;
    background-color: #3d50b0;
    width: 120px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: white;
    font-family: "Apple_L";
}

.disableTotalCheckBtn {
    background-color: rgb(224, 224, 224);
    color: rgb(163, 163, 163);
    margin-top: 24px;
    cursor: not-allowed;
    margin-right: 12px;
    border-radius: 4px;
    width: 120px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-family: "Apple_L";

}

.dataGrid {
    margin-top: 50px;
}

.main{
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    flex-direction: column;
}

.newlogo{
    width: 120px;
}

.logoDiv{
    width: 600px;
    margin: 0 auto;
    margin-top: 60px;
    display: flex;
    justify-content: flex-end;
}

.text{
    width: 600px;
    margin: 0 auto;
    font-size: 24px;
    font-family: "Apple_B";
    text-align: center;
    white-space: nowrap;

}

@media screen and (max-width : 1024px){

    .logoDiv{
        width: 80%;
        margin: 0 auto;
        margin-top: 40px;
        display: flex;
        justify-content: flex-end;
    }

    .text{
        width: 80%;
        font-size: 24px;
    }

    .newlogo{
        width: 90px;
    }
}
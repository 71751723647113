.bodyDiv{
    width: 90.4%;
    margin: 0 auto;
}

.body{
    padding-top: 0.5rem;
    width: 100%;
}

.body.ios{
    padding-top: 0;
}



.writeEdit{
    display: flex;
    justify-content: flex-end;
    font-family: "Apple_M";
}

.pentosqure{
    width: 1.4rem;
    height: 1.4rem;
}

.title{
    margin-top: 1rem;
    font-family: "Apple_B";
    font-size: 1.3rem;
    color: #263238;
}

.menuBar{
    font-size: 1.1rem;
    font-family: "Apple_SB";
    width: 100%;
    margin-top: 1.4rem;
    display: flex;
    justify-content: space-around;
    padding-bottom: 0.9rem;
    border-bottom: 0.08rem solid #cfd8dc;
    color: #607d8b;
    position: relative;
}

.menuBarSection{
    flex-grow: 1;
    text-align: center;
}

.menuBarStatus{
    flex-grow: 1;
    text-align: center;
}

.menuBarSection.active{
    color: #37474f;
}

.menuBarStatus.active{
    color: #37474f;
}

.menuUnderBar{
    position: absolute;
    width: 40%;
    border-top: 0.12rem solid #263238;
    bottom: 0;
    transition: all 200ms ease-out;
}

.body2Div{
    width: 90.6%;
    margin: 0 auto;
}

.body2{
    padding-top: 2rem;
}

.calendarDiv{
    border-radius: 0.4rem;
    padding-left: 1.2rem;
    padding-right: 1.2rem;
    padding-top: 1.2rem;
    padding-bottom: 1.2rem;
    border: 0.08rem solid #eeeeee;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 0.4px 1.6px 0px;
    overflow: hidden;
    transition: 300ms ease-out max-height;
}

.calendarTitle{
    font-family: "Apple_B";
    font-size: 1.1rem;
    color: #263238;
}

.calendarInfoDiv::-webkit-scrollbar{
    background-color: transparent;
    width: 0px !important;

}

.calendarInfoDiv::-webkit-scrollbar-thumb{
    background-color: transparent;
    border-radius: 0px;
}

.dayTextDiv{
    margin-top: 1.6rem;
    display: grid;
    grid-template-columns: repeat(7, 1fr);
}

.dayText{
    display: flex;
    justify-content: center;
    align-items: center;
    color: #78909c;
    font-family: "Apple_B";
    font-size: 0.9rem;
    margin-bottom: 1rem;
}

.calendarInfoDiv{

}

.dayWrapper{
    display: grid;
    grid-template-columns: repeat(7, 1fr);
}

.monthTitle{
    font-family: "Apple_B";
    color: #78909c;
    font-size: 0.8rem;
    margin-bottom: 0.6rem;
    margin-top: 0.6rem;
}

.dayDivWrapper{

}

.dayDiv{
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: "Apple_SB";
    font-size: 1rem;
    width: 100%;
    height: 100%;
    border-radius: 0.5rem;
    color: #263238;
}

.dayDiv.saturday{
    color: #0d52bd;
    color: #3880ff;
}

.dayDiv.sunday{
    color: #eb445a;
}

.dayDiv.today{
    background-color: #f5f5f5;
}

.dayDiv.past{
    opacity: 0.2;
}

.dayDiv.active{
    background-color: #1b49af;
    color: white;
}

.topDiv{
    transition:  150ms ease-out max-height;
    overflow: hidden;
}

/* .topDiv.hide{
    max-height: 0px;
    overflow: hidden;
} */

.backBtn{
    display: none;
}

.backBtn.active{
    display: flex;
}

.backBtnDiv{
    width: 90.6%;
    margin: 0 auto;
}

.backBtn{
    width: 100%;
    background-color: white;
    z-index: 99;
    justify-content: space-between;
    align-items: center;
    padding-bottom: 0rem;
    padding-top: 1.1rem;
    /* border-bottom: 0.031rem solid #cfcfcf; */
}

.backBtn.ios{
    padding-top: 0px;
}

.arrowLeft{
    width: 1rem;
    margin-right: 1.094rem;
}



.smallTitle{
    font-size: 0.95rem;
    color: #9e9e9e;
    font-family: "Apple_SB";
}

.smallDescription{
    font-family: "Apple_SB";
    font-size: 0.95rem;
    color: #263238;
    white-space: nowrap;
    
}

.calendarWrapperDisplay{
    display: none;
}

.calendarWrapperDisplay.active{
    display: block;
}

.calendarDiv.noPadding{
    padding: 0;
}

.subCalendarTextDiv{
    height: 100%;
    /* width: 87.5%;
    margin: 0 auto; */
    display: none;
    justify-content: space-between;
    align-items: center;
    height: 3rem;
    padding-left: 1.2rem;
    padding-right: 1.2rem;
    width: 100%;
}

.subCalendarTextDiv.active{
    display: flex;
}

.kindWrapper{
    display: none;
    margin-top: 0.8rem;
}

.kindWrapper.active{
    display: block;
}

.kindDiv{
    border: 0.08rem solid #eeeeee;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 0.4px 1.6px 0px;
    border-radius: 0.4rem;
    transition: all 300ms ease-out;
    overflow: hidden;
}

.kindSmallDiv{
    display: flex;
    /* width: 87.5%;
    margin: 0 auto; */
    padding-left: 1.2rem;
    padding-right: 1.2rem;
    justify-content: space-between;
    min-height: 3rem;
    align-items: center;
    height: 100%;
    width: 100%;
}



.kindBigDiv{

    padding-left: 1.2rem;
    padding-right: 1.2rem;
    padding-top: 1.2rem;
    padding-bottom: 1.2rem;

    transition: height 300ms ease;
}

.kindBigDiv.morePadding{
    padding-bottom: 2.4rem;
}


.kindBigTitle{
    font-family: "Apple_B";
    font-size: 1.1rem;
    margin-bottom: 1.1rem;
}

.kindSelectWrapper{
    position: relative;
    background-color: #f5f5f5;
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    height: 2.2rem;
    border-radius: 0.5rem;
}



.kindSelectElement{
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: "Apple_B";
    color: #9e9e9e;
    font-size: 0.8rem;
    z-index: 2;
}

.kindSelectElement.active{
    color: #263238;
}

.kindSelector{
    position: absolute;
    border: #eeeeee;
    background-color: white;
    width: 22%;
    height: 1.5rem;

    top: 15%;
    border-radius: 0.25rem;
    z-index: 1;
    transition: all 200ms ease-in;
}

.dialDiv{
    overflow: hidden;
    transition: max-height 300ms ease;
}

.timeDisplay{
    padding: 0.1rem 0.3rem 0.1rem 0.3rem;
    border-radius: 0.2rem;
    margin-left: 0.5rem;
    display: inline-block;
    font-size: 0.75rem;
    background-color: rgba(61, 80, 176, 0.15);
    color: #3d50b0;
    font-family: "Apple_EB";
}

.textDescriptionWrapper{
    border: 0.08rem solid #eeeeee;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 0.4px 1.6px 0px;
    border-radius: 0.4rem;
    margin-top: 0.8rem;
}

.textDescriptionWrapper{
    display: none;
}

.textDescriptionWrapper.active{
    display: block;
}

.textArea{
    border: none;
    outline: none;
    font-size: 0.95rem;
    padding-left: 1.2rem;
    padding-right: 1.2rem;
    padding-top: 0.9rem;
    padding-bottom: 0.9rem;
    line-height: 1.2;
    font-family: "Apple_SB";
    color :#263238
}

.textArea:focus{
    border: none;
    outline: none;
}

.textArea::placeholder{
    color: #9e9e9e;
    font-family: "Apple_SB";
    font-size: 0.95rem;
    line-height: 1.2;
}

.submitButton{
    display: none;
    position: fixed;
    bottom: env(safe-area-inset-bottom);
    z-index: 99;
    justify-content: center;
    align-items: center;
    background-color: #3d50b0;
    color: white;
    font-family: "Apple_SB";
    width: 100%;
    min-height: 4rem;
}

.submitButton.safeArea{
    width: 90%;
    min-height: 4rem;
    left: 50%;
    transform: translateX(-50%);
    border-radius: 0.8rem;

}

.submitButton.active{
    display: flex;
}

.statusBody{
    padding-top: 2.469rem;
}

.statusTop{
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 90.4%;
    margin: 0 auto;
}

.statusTitle{
    font-family: "Apple_SB";
    font-size: 1.1rem;
    letter-spacing: -0.85px;
    color: #251e1c;
}

.statusNumber{
    font-size: 0.8rem;
    font-family: "Apple_L";
}

.description{
    margin-top: 0.812rem;
    background-color: #f6f6f6;
    padding-top: 1.632rem;
    padding-bottom: 1.632rem;
}

.descriptionBody{
    width: 90.6%;
    margin: 0 auto;
}

.eachDescription{
    margin-bottom: 1.484rem;
}

.eachDescription.last{
    margin-bottom: 0;
}

.eachDescriptionDate{
    font-family: "Apple_M";
    color: #9e9e9e;
    font-size: 0.938rem;
    letter-spacing: -0.38px;
    margin-bottom: 0.634rem;
}

.eachDescriptionBox{
    width: 100%;
    padding: 1.397rem 1.094rem 1.141rem 1.094rem;
    border-radius: 10px;
    border: solid 0.5px #dbdbdb;
    background-color: #fff;
    position: relative;
}

.eachDescriptionText{
    font-size: 0.875rem;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.57;
    letter-spacing: -0.35px;
    text-align: left;
    color: #2a2a2a;
    font-family: "Apple_M";
    margin-bottom: 0.281rem;

}

.eachDescriptionTargetDate{
    font-family: "Apple_L";
    font-size: 0.875rem;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: -0.7px;
    text-align: left;
    color: #251e1c;
    margin-bottom: 0.853rem;
}

.eachDescriptionKindDiv{
    display: flex;
    align-items: center;
}

.eachDescriptionKindText{
    font-family: "Apple_M";
    font-size: 0.95rem;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: -0.85px;
    text-align: left;
    color: #251e1c;

    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 1;
    overflow: hidden;
    text-overflow: ellipsis;
}

.eachDescriptionKindSuccess{
    margin-left: 0.719rem;
    display: flex;
    align-items: center;
    background-color: #3d55dc;
    border-radius: 12.5px;
    opacity: 0.9;
    border: 0.5px solid #b7b7b7;
    color: white;
    padding: 0.344rem 0.656rem 0.313rem 0.531rem;
}

.eachDescriptionKindSuccess > span{
    font-family: "Apple_SB";
    font-size: 0.75rem;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: -0.6px;
    text-align: left;
    color: #fff;
}

.check{
    width: 10px;
}

.xmark{
    width: 8px;
}

.arrowUp{
    width: 8px;
}

.trash{
    width: 14px;
}

.eachDescriptionKindSuccess.waiting{
    background-color: #92949c;
}

.eachDescriptionKindSuccess.decline{
    background-color: #eb445a;
}

.eachDescriptionKindSuccess.success{
    background-color: #3d55dc;
}

.trashCanDiv{
    position: absolute;
    right: 1.094rem;
    top: 1.397rem;
}

.trashCanDiv.none{
    display: none;
}

@media only screen and (min-width : 500px){
    .backBtn{
        padding-top: 1.1rem !important;
    }
}



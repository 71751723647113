* {
    box-sizing: border-box;
}

.main {
    background-color: #f5f5f5;
    width: calc(100% - 244px);
    height: calc(100vh - 78px);
    overflow : scroll;
    padding-left: 46px;
    padding-top: 45.7px;
    padding-bottom: 71px;
}

.main::-webkit-scrollbar{
    width: 6px !important;
}

.clock {
    margin-right: 7.1px;
}

.title {
    width: 1164px;
    font-family: "Apple_B";
    font-size: 22px;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.title div:first-child{
    display: flex;
    align-items: center;
}

.paper {
    border-radius: 12px;
    margin-top: 19px;
    width: 1164px;
    background-color: white;
    padding-left: 67px;
    padding-right: 67px;
    padding-bottom: 108px;
    transition: box-shadow 0.2s ease 0s;
    box-shadow: rgb(20 20 20 / 2%) 2px 8px 12px 0px, rgb(20 20 20 / 2%) 0px 1px 3px 0px;
}

.paperTitle {
    display: flex;
    justify-content: space-between;
    padding-top: 38px;
}

.paperTitleFirst {
    display: flex;
}



.calendarFlat {
    margin-right: 8px;
}

.studentName {
    display: flex;
    align-items: center;
    font-family: "Apple_R";
    font-size: 18px;
    color: #303030;
    letter-spacing: -0.9px;
}

.monthStudyTime {
    cursor: pointer;
    width: 180px;
    height: 46px;
    border: solid 1px #728aff;
    border-radius: 4px;
    line-height: 46px;
    text-align: center;
    color: #3d50b0;
    font-family: "Apple_R";
    letter-spacing: -0.8px;
    box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
}


.chevronLeft {
    box-sizing: border-box;
    width: 7.4px;
    margin-right: 26.6px;
    cursor: pointer;
}

.chevronRight {
    box-sizing: border-box;
    width: 7.4px;
    margin-left: 26.6px;
    cursor: pointer;
}


.main::-webkit-scrollbar{
    background-color: #f5f5f5;
}

.main::-webkit-scrollbar-thumb{
    background-color: #bbbcb6;
    border-radius: 10px;

}


.whenMonth {
    margin-top: 10px;
    margin-bottom: 18px;
    font-size: 23px;
    color: #303030;
    font-family: "Apple_B";
    display: flex;
    justify-content: center;
}

.day {
    justify-content: stretch;
    display: flex;
    border-top: 4px solid #f2f2f2;
    border-bottom: 4px solid #f2f2f2;
}

.dayList {
    width: 300px;
    font-family: "Apple_R";
    height: 44px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-right: 3px solid #f2f2f2;
    background-color: #ebebeb;
}

.day .dayList:first-child{
    color: #d14e4e;
}

.dayList.last{
    border-right: 0;
}

.calendar {
    border: 3px solid #f2f2f2;
    border-top: 2px solid #303030;
    box-shadow: 0 10px 30px 0 rgba(201, 201, 201, 0.16);
}

.calendarWeek {
    display: flex;
    justify-content: stretch;
}

.calendarDay {
    padding-left: 9px;
    padding-top: 11px;
    width: 300px;
    height : 110px;
    border-right: 3px solid #f2f2f2;
}

.calendarDay.active:hover{
    cursor: pointer;
    background-color: #f1fbff;
}

.calendarDay:hover{

}

.calendarWeek .calendarDay:nth-child(7n){
    border-right: 0;
}

.calendarWeek .calendarDay:nth-child(7n+1){
    color: #d14e4e;
    font-family: "Apple_B";
}

.calendarWeek {
    border-bottom: 3px solid #f2f2f2;
}

.calendarDiv .calendarWeek:last-child{
    border-bottom: 0;
}

.startTime {
    margin-top: 11px;
    margin-bottom: 2px;
    color: black;
    font-family: "Apple_R";
}

.startTime.late{
    color: red;
}

.lastTime{
    color: black;
    font-family: "Apple_R";
}

.totalHours {
    margin-top: 1px;
    margin-left: 4.5px;
    font-family: "Apple_B";
    font-size: 12px;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid #1b49af;
    color: #1b49af;
    border-radius: 15px;
    padding: 1px 3px 1px 3px;
}

.modalDate{
    width: 100%;
    background-color: #494951;
    color: white;
    font-family: "Apple_R";
    height: 64px;
    border-radius: 34px;
    text-align: center;
    line-height: 64px;
    font-size: 18px;
}

.accessControlList {
    margin-top: 40px;
    overflow: auto;
    height: 343px;
}

.accessControlList::-webkit-scrollbar{
    background-color: #f5f5f5;
    width: 6px !important;
}

.accessControlList::-webkit-scrollbar-thumb{
    background-color: #bbbcb6;
    border-radius: 10px;
}

.eachAccessControl{
    width: 360px;
}

.eachAccessControlChild{
    border-bottom: 1px solid rgba(0,0,0,0.2);
    padding-right: 20px;
    padding-top: 20px;
    padding-bottom: 20px;
    font-size: 17px;
    letter-spacing: -0.95px;
}

.timeCheck{
    font-weight: 600;
}

.eachAccessControlChild.inside{
    color: #ff605a;
}

.eachAccessControlChild.outside{
    color: #3d50b0;
}

.totalScore{
    margin-top: 12px;
    font-size: 14px;
    display: flex;
    justify-content: flex-end;
    font-weight: 600;
}

.modalPaper{
    max-height: 80vh;
    overflow: auto;
}

.modalPaper::-webkit-scrollbar{
    background-color: #f5f5f5;
    width: 2px !important;
}

.modalPaper::-webkit-scrollbar-thumb{
    background-color: #bbbcb6;
    border-radius: 10px;
}


@media screen and (max-width : 1024px){
    /* .main {
        height: 130vh;
        overflow: auto;
    } */

    
}

@media screen and (max-width : 1024px){
    .main {
        height: 130vh;
        width: 1256px;
    }

    @media (orientation : landscape){
        .main {
            height: auto;
        }
    }
}




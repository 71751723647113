
.menuDiv {
    margin-top: 29px;
    display: flex;
    justify-content: center;
}

.menuDiv div {
    color: #757575;
    font-size: 18px;
    font-family: "Apple_R";
    cursor: pointer;
    display: flex;
    justify-content: center;
    padding-bottom: 18px;
    margin-right: 25px;
    width: 155px;
    border-bottom: 2px solid #b1b1b1;
}

.menuDiv .active {
    color: #4a78ff;
    border-bottom: 3px solid #4a78ff;
}

.chargeTitle {
    border-radius: 4px 4px 0 0;
    font-family: "Apple_L";
    font-size: 18px;
    margin-bottom: 18px;
    background-color: #3d50b0;
    color: white;
    height: 60px;
    display: flex;
    align-items: center;
    padding-left: 12px;
}

.chargeBoard {
    padding-left: 12px;
    padding-right: 12px;
    padding-bottom: 8px;
}

.chargeName {
    margin-top: 12px;
    margin-bottom: 4px;
    font-family: "Apple_R";
}

.chargeBtnDiv {
    margin-top: 12px;
    border-top: 2px solid rgb(223, 223, 223);
    display: flex;
    justify-content: end;
    padding-top: 16px;
    padding-bottom: 16px;
}

.disabledChargedBtn {
    cursor: not-allowed;
    margin-right: 12px;
    border-radius: 4px;
    background-color: rgb(224, 224, 224);
    width: 120px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: rgb(163, 163, 163);
    font-family: "Apple_L";
}

.chargeBtn {
    cursor: pointer;
    margin-right: 12px;
    border-radius: 4px;
    background-color: #3d50b0;
    width: 120px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: white;
    font-family: "Apple_L";
}

.datePickerDiv {
    margin-top: 16px;
    display: flex;
}

.datePicker {
    margin-top: 12px;
}

.datePickerTitle {
    margin-bottom: 8px;
}

.datePickerTitle1 {
    margin-bottom: 4px;
}

.datePickerTitle2 {
    margin-bottom: 4px;
    margin-left: 8px;
}

.timePicker1 {
    margin-right: 8px !important;
}

.timePicker2 {
    margin-left: 8px !important;
}

.addText {
    font-size: 12px;
    margin-top: 4px;
}

.uploadFileDiv {
    margin-top: 24px;
}

.selectDiv {
    display: flex;
}

.teacherDiv {
    width: 1095px;
    margin-top: 52px;
    background-color: white;
    display: flex;
    border-radius: 12px;
    box-shadow: rgb(20 20 20 / 2%) 2px 8px 12px 0px, rgb(20 20 20 / 2%) 0px 1px 3px 0px;
}

.teacherImg {
    width: 487px;
    width: 315px;
    height: 326px;
    object-fit: cover;
    background-color: rgb(202,175, 206);
}

.teacherImage{
    width: 487px;
    width: 315px;
    height: 326px;
    object-fit: cover;
}

.teacherDescription {
    width: 600px;
    padding-left: 37px;
    padding-top: 46px;
    padding-right: 47px;
    font-size: 18px;
    font-family: "Apple_R";
}

.teacherSubject {
    padding-bottom: 20px;
    border-bottom: 1px solid #dfdfdf;
}

.teacherName {
    color: #566ee8;
    margin-top: 20px;
    padding-bottom: 20px;
    border-bottom: 1px solid #dfdfdf;
}

.location {
    margin-top: 20px;
    padding-bottom: 20px;
    border-bottom: 1px solid #dfdfdf;
}

.selectDiv {
    margin-top: 35px;
    display: flex;
    justify-content: start;
    margin-bottom: 20px;
}

.timeSelect {
    margin-left: 28px;
    margin-right: 28px;
    width: 390px;
    height: 358px;
    background-color: white;
    padding-top: 25px;
    padding-left: 27px;
    padding-right: 27px;
    border-radius: 10px;
    box-shadow: rgb(20 20 20 / 2%) 2px 8px 12px 0px;

}

.timeSelect2 {
    width: 345px;
    height: 358px;
    background-color: white;
    padding-top: 25px;
    padding-left: 27px;
    padding-right: 27px;
    border-radius: 10px;
    box-shadow: rgb(20 20 20 / 2%) 2px 8px 12px 0px;

}

.calendar{
    width: 100%;
    display: grid;
    grid-template-columns: repeat(7, 1fr);
    padding-top: 16px;
    gap: 10px;
}

.calendarDivDay{


}

.calendarDiv{
    font-family: "Apple_L";
    display: flex;
    justify-content: center;
    align-items: center;
    width: 34px;
    height: 34px;
    color: rgba(0,0,0,0.38);
    font-size: 14px;
}

.calendarDiv.calendarDivSelected{
    background-color: #3d50b0;
    color: white;
    border-radius: 50%;
}

.calendarDiv.sunday{
    color: red;
}



.timeSelectTitle {
    font-family: "Apple_R";
    font-size: 20px;
    color: #303030;
    padding-bottom: 16px;
    border-bottom: 1px solid #dbdbdb;
    display: flex;
    justify-content: space-between;
}

.times {
    margin-top: 23px;
    display: flex;
    flex-wrap: wrap;
}

.timeDiv {
    font-family: "Apple_R";
    color: #858585;
    margin-bottom: 8px;
    margin-right: 6px;
    width: 79px;
    height: 39px;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid #cbcbcb;
    border-radius: 28px;
    cursor: pointer;
}

.times .timeDiv:nth-child(4n){
    margin-right: 0;
}

.questionDescription {
    width: 300px;
    height: 358px;
    background-color: white;
    padding-top: 25px;
    padding-left: 27px;
    padding-right: 27px;
    border-radius: 10px;
    box-shadow: rgb(20 20 20 / 2%) 2px 8px 12px 0px;

}

.questionDescriptionTitle{
    font-family: "Apple_R";
    font-size: 20px;
    color: #303030;
    padding-bottom: 16px;
    border-bottom: 1px solid #dbdbdb;
}

.questionDiscriptionDiv {
    margin-top: 5px;
    font-family: "Apple_R";
}

.fileUploadDivWrapper{
    height: 45.438px;
}

.fileNameDivWrapper{
    max-height: 40px;
    overflow: hidden;
}

.questionDescriptionSubmitDiv{
    display: flex;
    justify-content: flex-end;
    margin-top: 0px !important;
}

.questionDiscriptionDiv div div:last-child{
    margin-top: 12px;
    font-size: 14px;
}

.justText{
    font-family: "Apple_B";
}

.mine {
    background-color: #3d50b0 !important;
    color: white !important;
}

.occupied {
    cursor: not-allowed;
    background-color: #8e8e8e;
    color: white;
}

.alert {
    margin-top: 12px;
}

.alertSpan{
    font-family: "Apple_R";
}

.enrollLoading {
    margin-top: 110px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.cog {
    width: 25px;
    cursor: pointer;
}

.blank {
    height: 80px;
}

.deleteQuestionStyles {
    color: red;
}

.loading {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 300px;
}

.imageWrapperDiv{
    width: 600px;
    margin: 0 auto;
    margin-top: 40px;
}

.imageExample{
    width: 600px;
}

.exampleQuestionDescription{
    width: 600px;
    margin: 0 auto;
    margin-top: 40px;
    font-size: 22px;
    font-family: "Apple_R";
}

.logoDiv{
    width: 600px;
    margin: 0 auto;
    margin-top: 60px;
    display: flex;
    justify-content: flex-end;
}

.newlogo{
    width: 120px;
}

.exampleQuestionDescription{
    white-space: pre-wrap;
}

@media screen and (max-width : 1024px){
    .imageWrapperDiv{
        margin-top: 20px;
        width: 80%;
    }

    .imageExample{
        width: 100%;
    }

    .exampleQuestionDescription{
        margin-top: 20px;
        width: 80%;
        font-size: 16px;
    }

    .logoDiv{
        width: 80%;
        margin: 0 auto;
        margin-top: 40px;
        display: flex;
        justify-content: flex-end;
    }

    .newlogo{
        width: 80px;
    }
}









.headerDiv{
    width: 90.6%;
    margin: 0 auto;
}

.header{
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    padding-bottom: 1.1rem;
    padding-top: 1.1rem;
    /* border-bottom: 0.031rem solid #cfcfcf; */
}

.header.ios{
    padding-top: 0px;
}

.backBtnDiv{
    display: flex;
    align-items: center;
    height: 1.5rem;
    padding-top: 0.125rem;

    padding-top: 0.375rem;
    padding-bottom: 0.25rem;

    width: 23%;
}

.arrowLeft{
    width: 1rem;
    margin-right: 1.094rem;
}

.titleText{
    font-size: 1.063rem;
    letter-spacing: -0.053rem;
    text-align: left;
    color: #2a2a2a;
    font-family: "Apple_B";
}

.imageIconDiv{
    display: flex;
    align-items: center;
    position: relative;
}

.imageIcon{
    width: 1.5rem;
}

.badge{
    position: absolute;
    top: -0.4rem;
    right: -0.4rem;
    width: 1.2rem;
    height: 1.2rem;
    border-radius: 50%;
    background-color: red;
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: "Apple_B";
    font-size: 0.7rem;
    display: none;
}

.badge.active{
    display: flex;
}

.submit{
    font-family: "Apple_B";
    font-size: 0.95rem;
    color: rgba(0, 0, 0, 0.4);
}

.submit.active{
    color: black;
}

.btns{
    width: 23%;
    justify-content: space-between;
    display: flex;
    align-items: center;
}

.body{
    padding-top: 0.2rem;
    width: 90.6%;
    margin: 0 auto;
}

.kindSelect{
    padding-top: 0.8rem;
    position: relative;
    overflow: hidden;
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: center;
    padding-bottom: 0.8rem;
    border-bottom: 0.031rem solid rgba(0, 0, 0, 0.4);
}

.kindSelectText{
    color: rgba(0, 0, 0, 0.4);
    font-family: "Apple_M";
}

.kindSelectChevron{
    display: flex;
    align-items: center;
}

.kindSelectText.active{
    color: black;
}

.chevronRight{
    width: 0.6rem;
}

.rippleEffect{
    color: red !important;
}

.radioItem{
    font-family: "Apple_R";
}

.descriptionDiv{
    padding-top: 0.8rem;
}

.textarea{
    width: 100%;
    border: 0;
    outline: 0;
    font-size: 1rem;
    font-family: "Apple_M";
}

.textarea::placeholder{
    color: rgba(0, 0, 0, 0.4);
    font-family: "Apple_M";
    font-size: 1rem;
}

.imageNumber{
    font-family: "Apple_EB";
    color: #3d50b0;
    font-weight: 900;
}

.cardWrapper{
    margin-bottom: 0.4rem;
    position: relative;
}

.eachCard{
    border: 1px solid #ebebeb;
    border-radius: 0.5rem;
    display: flex;
    width: 100%;
    position: relative;
    min-height: 3.864rem;
}

.eachImageText{
    padding-left: 0.8rem;
    flex: 10;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.eachImageName{
    font-size: 0.9rem;
    font-family: "Apple_R";
    font-weight: 600;
    margin-bottom: 0.1rem;
}

.eachImageSize{
    font-size: 0.7rem;
    color: #606060;
}

.eachImageDiv{
    width: 100%;
    height: 100%;
    flex: 1.8;
}

.eachImage{
    width: 100%;
    height: 100%;
}

.cancel{
    position: absolute;
    top: 0;
    right: 0;
    transform: translate(50%, -50%);
    width: 1rem;
    height: 1rem;
    background-color: black;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.xmark{
    width: 0.625rem;
    height: 0.625rem;
}

.addDiv{
    /* margin-top: 0.8rem; */
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #f6f7f8;
    width: 100%;
    border-radius: 0.5rem;
    /* aspect-ratio: 100 / 14; */
    /* min-height: 2.939rem; */
}

.addDivTextDiv{
    display: flex;
    align-items: center;
}

.plusDiv{
    width: 0.9rem;
    height: 0.9rem;
    background-color: #768397;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 0.6rem;
}

.plus{
    width: 0.6rem;
    height: 0.6rem;
}

.addText{
    color: #768397;
    font-family: 'Apple_B';
    font-size: 0.8rem;
    line-height: 0.9rem;
}

@media only screen and (min-width : 500px){
    .header{
        padding-top: 1.1rem !important;
    }
}
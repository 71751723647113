.header{
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-left: 4.7%;
    padding-right: 4.7%;
    padding-bottom: 1.1rem;
    padding-top: 1.1rem;
    /* border-bottom: 0.031rem solid #cfcfcf; */
    /* height: 59.19px; */
}

.header.ios{
    padding-top: 0px;
}

.backBtnDiv{
    display: flex;
    align-items: center;
    height: 1.5rem;
    padding-top: 0.125rem;

    padding-top: 0.375rem;
    padding-bottom: 0.25rem;

    width: 28%;
}

.arrowLeft{
    width: 1rem;
    margin-right: 1.094rem;
}

.titleText{
    font-size: 1.063rem;
    letter-spacing: -0.053rem;
    text-align: left;
    color: #2a2a2a;
    font-family: "Apple_B";
}

.pentosqure{
    width: 1.4rem;
}

.empty{
    width: 28%;
}

.submit{
    width: 28%;
    text-align: end;
    font-family: "Apple_B";
    font-size: 0.95rem;
    color: black;
}

.restaurantSubmit{
    width: 28%;
    text-align: end;
}

.wrapper{
    display: inline-flex;
    align-items: center;
    border-radius: 0.4375rem;
    border: 0.7px solid #E1E1E1;
    padding-top: 0.19rem;
    padding-bottom: 0.19rem;
    padding-left: 0.38rem;
    padding-right: 0.5rem;
}

.coin{
    width: 0.9375rem;
    height: 0.9375rem;
}

.wrapperText{
    font-size: 0.75rem;
    margin-left: 0.4rem;
    margin-right: 0.38rem;
    display: flex;
    align-items: center;
}

.wrapperText2{
    display: flex;
    align-items: center;
}

.wrapperText.ticketText{
    margin-left: 0.5rem;
    margin-right: 0.8rem;
}

.rightChevron{
    width: 0.5rem;
    fill: rgba(0,0,0,0.64);
}

.wrapperText{
    font-family: "Apple_L";
}

@media only screen and (min-width : 500px){
    .header{
        padding-top: 1.1rem !important;
    }
}
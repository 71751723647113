.eachContent{
    width: 100%;
    position: relative;
    height: 100%;
}

.contentDescription{
    width: 100%;
    overflow: hidden;
}

.hoverIcon{
    position: absolute;
    right: 0;
    z-index: 99;
    display: none;
}

.eachContent:hover .hoverIcon{
    display: block;
    cursor: pointer;
}

.hoverIconOpened{
    display: block;
    cursor: pointer;
}

.eachContent:hover .contentDescription{
    width: 96%;
}


.title{
    font-family: Apple_B;
    margin-bottom: 24px;
}

.title5{
    font-family: Apple_B;
}

.title6{
    font-family: 13px;
}

.oneBox{
    margin-top: 12px;
    margin-right: 24px;
    display: flex;
    align-items: center;
}

.questionTitle{
    font-family: "Apple_R";
    margin-right: 12px;
    text-align: center;
    font-size: 15px;
}

.titleWrapper{
    display: flex;
    justify-content: space-between;
}

.eachBox{
    margin-top: 12px;
}

.titleText{
    font-family: "Apple_B";
    font-size: 20px;
    margin-bottom: 8px;
}

.title{
    display: flex;
    align-items: center;
}

.titleText2{
    font-family: "Apple_SB";
    font-size: 16px;
    margin-bottom: 20px;
}

.titleBox{
    width: 1150px;

    padding-top: 18px;
    margin-bottom: 32px;
}

.main{
    width: 1150px;
    display: flex;
    justify-content: space-between;
}

.subMain{
    width: 550px;
}

.oneBox{
    min-height: 55px;
}

.red{
    color: red;
}
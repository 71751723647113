.clockWrapper{
    padding-top: 25px;
    width: 82%;
    margin: 0 auto;
    padding-bottom: 25px;
}

.clock{
    display: flex;
    justify-content: center;
}

.hourClock{
    display: flex;
}

.hourClock.real{
    margin-right: 24px;
}

.eachNumberWrapperBox{
    border: 1px solid #d6d6d6;
    border-radius: 8px;
    overflow: hidden;
    width: 70px;
    position: relative;
    color: #1c3150;
}

.eachNumberWrapperBox.before{
    margin-right: 5px;
}

.upperWrapper{
    height: 48px;
    background-color: #f5f5f5;
    border-bottom: 1px solid #424242;
}

.bottomWrapper{
    height: 48px;
}

.leftClip{
    position: absolute;
    left: 10%;
    top: 50%;
    transform: translateY(-50%);
    height: 15px;
    width: 3px;
    background-color: #dadada;
}

.rightClip{
    position: absolute;
    right: 10%;
    top: 50%;
    transform: translateY(-50%);
    height: 15px;
    width: 3px;
    background-color: #dadada;
}

.clockNumber{
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-family: "Apple_B";
    font-size: 58px;
}

.schedulerWrapper{
    width: 90.6%;
    margin: 0 auto;
    border-top: 1px solid #bdbdbd;
}

.eachSchedule{
    padding-left: 0.3rem;
    padding-right: 0.3rem;
    padding-top: 0.9rem;
    padding-bottom: 0.9rem;
    border-bottom: 1px solid #bdbdbd;
    display: flex;
    font-family: "Apple_B";
    justify-content: space-between;
    color: #1c3150;
}

.whichClass{
    width: 110px;
    display: flex;
    justify-content: space-between;
}

.startTime{
    display: flex;
    justify-content: center;
}

.middleTime{
    display: flex;
    justify-content: center;
}

.endTime{
    display: flex;
    justify-content: center;
}

.eachSchedule.active{
    background-color: #ebebeb;
    /* color: blue !important;
    font-family: "Apple_EB"; */
}

.eachSchedule.current{
    color: #304ffe !important;
    font-family: "Apple_EB";
}

.schedulerWrapper::-webkit-scrollbar{
    background-color: transparent;
    width: 0px !important;

}

.schedulerWrapper::-webkit-scrollbar-thumb{
    background-color: transparent;
    border-radius: 0px;
}